import { useContext, useMemo } from 'react';

import LFsContext from '@global/RootApp/LFsContext';
import type { LFsState } from '@global/store/root/lfs/types';

export const useLFsStateContext = (): LFsState => {
  const lfsState = useContext(LFsContext.State);

  return useMemo(() => lfsState, [lfsState]);
};

export default null;
