import { useEffect } from 'react';

import { usePageRegionAlerts } from '@components/PageRegion';

type JobDetailNotificationArgs = {
  detail?: Record<string, string> & {
    info?: string;
    warning?: string;
    error?: string;
  };
};

const useJobDetailNotification = ({ detail }: JobDetailNotificationArgs) => {
  const { showInfoAlert, showWarningAlert, showErrorAlert } =
    usePageRegionAlerts();

  useEffect(() => {
    if (!detail?.info) {
      return;
    }

    showInfoAlert({
      message: detail.info,
      origin: 'useJobDetailNotification',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail?.info]);

  useEffect(() => {
    if (!detail?.warning) {
      return;
    }

    showWarningAlert({
      message: detail.warning,
      origin: 'useJobDetailNotification',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail?.warning]);

  useEffect(() => {
    if (!detail?.error) {
      return;
    }

    showErrorAlert({
      message: detail.error,
      origin: 'useJobDetailNotification',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail?.error]);
};

export default useJobDetailNotification;
