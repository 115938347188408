export default {
  root: {
    top: '7px',
    zIndex: 2,
    pointerEvents: 'none' as const,
  },
  shrink: {
    left: '0px',
    top: '-6px',
  },
};
