import moment from 'moment';

import type { Notification } from '@snorkel/api/lib';
import Icon, { Icons } from '@snorkel/coral/components/Icon';
import HStack from '@snorkel/coral/layout/HStack';
import VStack from '@snorkel/coral/layout/VStack';
import IconButton, {
  IconButtonSizes,
} from '@snorkel/coral/prebuilts/IconButton';

export type NotificationItemProps = {
  notification: Notification;
  onDismiss: VoidFunction;
  onClick: VoidFunction;
};

const NotificationItem = ({
  notification,
  onClick,
  onDismiss,
}: NotificationItemProps) => {
  const {
    body: { title, message },
    created_at: createdAt,
  } = notification;

  return (
    <div className="flex cursor-pointer items-center border-b border-gray-200 py-4 px-1 transition duration-300 hover:bg-gray-100 ">
      <div className="flex-1" onClick={onClick}>
        <VStack>
          <div className="flex w-full flex-row justify-between">
            <div className="font-semibold">{title}</div>
            <HStack>
              <div className="text-xs text-coolGray-500">
                {moment.utc(createdAt).local().fromNow()}
              </div>
              <IconButton
                tooltip="Resolve notification"
                onClick={e => {
                  // For avoiding navigating to the notification url
                  e.stopPropagation();
                  onDismiss();
                }}
                size={IconButtonSizes.Xsmall}
                data-cy={`resolve-notification-button-${notification.notification_uid}`}
              >
                <Icon name={Icons.CHECKMARK__IN_CIRCLE} />
              </IconButton>
            </HStack>
          </div>
          <div>{message}</div>
        </VStack>
      </div>
    </div>
  );
};

export default NotificationItem;
