/* eslint-disable local-rules/require-data-cy */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import type { ReactNode } from 'react';
import React from 'react';

import Button from '@components/Form/DeprecatedButton';
import type { DialogActionButtonProps } from '@components/Modals/Dialog/DialogActionButton';
import DialogActionButton from '@components/Modals/Dialog/DialogActionButton';

type ActionButtonProps = Omit<DialogActionButtonProps, 'onClose'>;

type DialogComponentProps = {
  title: ReactNode;
  message?: ReactNode;
  open: boolean;
  handleClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  closeButtonDataCy?: string;
  actionButtonProps: ActionButtonProps;
  cancelButtonText?: string;
  children?: ReactNode;
};

const CustomDialog = ({
  title,
  message,
  open,
  handleClose,
  closeButtonDataCy,
  children,
  actionButtonProps: {
    buttonText,
    buttonDataCy,
    isLoadingOverride,
    buttonLoadingText,
    loading,
    disabled,
    syncHandleConfirm,
    handleConfirm,
  },
  cancelButtonText,
}: DialogComponentProps) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    // Protect the underlying page from accidental actions.
    //
    // onClick and onKeyPress are not explicitly defined in DialogProps,
    // but these props do actually work, because <Dialog> passes them down
    // to the underlying <div> that it renders.
    onClick={e => {
      e.stopPropagation();
    }}
    onKeyPress={e => {
      e.stopPropagation();
    }}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <form onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
      <DialogContent>
        {message && (
          <div className="pb-4" id="alert-dialog-description">
            {message}
          </div>
        )}
        {children || null}
      </DialogContent>
      <DialogActions>
        {handleClose && (
          <Button
            onClick={handleClose}
            variant="outlined"
            dataCy={closeButtonDataCy}
          >
            {cancelButtonText || 'Cancel'}
          </Button>
        )}
        <DialogActionButton
          onClose={handleClose}
          buttonText={buttonText}
          buttonDataCy={buttonDataCy}
          loading={loading}
          isLoadingOverride={isLoadingOverride}
          buttonLoadingText={buttonLoadingText}
          disabled={disabled}
          syncHandleConfirm={syncHandleConfirm}
          handleConfirm={handleConfirm}
        />
      </DialogActions>
    </form>
  </Dialog>
);

CustomDialog.defaultProps = {
  buttonDataCy: '',
};

export default CustomDialog;
