import type { RefObject } from 'react';
import { useEffect } from 'react';

type ReferencesType = RefObject<HTMLSpanElement>[] | RefObject<HTMLSpanElement>;

const useOutside = (
  references: ReferencesType,
  cb: () => void,
  type = 'mousedown',
  elementGetter: () => Element[] = () => [],
) => {
  const handleClick = (e: any) => {
    const refList = Array.isArray(references) ? references : [references];

    const clickedInsideDocument = !elementGetter
      ? true
      : elementGetter().some(el => {
          if (!el) {
            return false;
          }

          return el.contains(e.target);
        });

    const clickedInsideRef = refList.some(ref => {
      if (!ref || !ref.current) {
        return false;
      }

      return ref.current.contains(e.target);
    });

    if (!clickedInsideRef && !clickedInsideDocument) {
      cb();
    }
  };

  useEffect(() => {
    document.addEventListener(type, handleClick);

    return () => {
      document.removeEventListener(type, handleClick);
    };
  }, []);
};

export default useOutside;
