import type React from 'react';
import type { ChangeEvent, MouseEvent } from 'react';

export type DropdownOptionProps<T = string> = {
  value: T;
  children: string;
  isDisabled?: boolean;
};

export type DropdownGroupProps = {
  title: string;
  children: React.ReactNode;
};

export enum RestrictedKeysForDropdown {
  Escape = 'Escape',
  Down = 'Down',
  ArrowDown = 'ArrowDown',
  Up = 'Up',
  ArrowUp = 'ArrowUp',
  Home = 'Home',
  End = 'End',
  Enter = 'Enter',
  Right = 'Right',
  ArrowRight = 'ArrowRight',
  Left = 'Left',
  ArrowLeft = 'ArrowLeft',
}

export const RestrictedKeysForDropdownArr: string[] = Object.values(
  RestrictedKeysForDropdown,
);

export type DropdownOptionType<ValueType> = {
  value: ValueType;
  title: string;
  group?: string;
  isDisabled?: boolean;
};

export type OnSelect<ValueType> = (
  selectedItem: DropdownOptionType<ValueType>,
) => void;
export type ToggleDropdown = (
  e: MouseEvent<HTMLButtonElement | HTMLInputElement>,
) => void;
export type CustomTitleRender<ValueType> = (
  title: string,
  value: ValueType,
  options: { selected: boolean },
) => React.ReactNode;

export type CustomButtonRender<ValueType> = (
  value: DropdownOptionType<ValueType>[],
  toggleDropdown?: (
    e: MouseEvent<HTMLButtonElement | HTMLInputElement>,
  ) => void,
) => React.ReactNode;

export type OnChangeSearchValue = ({
  target: { value },
}: ChangeEvent<HTMLInputElement>) => void;
export type OnSelectItem = (value: any) => void;
export type TraverseGroup = (index: number) => void;
export type DropdownSearchableProps =
  | {
      placeholder: string;
    }
  | boolean;
