import { useContext } from 'react';

import AnnotationContext from '@app/StudioPage/providers/AnnotationProvider/context';

const useAnnotationStateContext = () => {
  const context = useContext(AnnotationContext.Read);

  if (!context) {
    throw new Error(
      'useAnnotationStateContext must be used within a AnnotationProvider',
    );
  }

  return context;
};

export default useAnnotationStateContext;
