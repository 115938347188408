import type { MouseEventHandler } from 'react';
import React, { useState } from 'react';
import type { DefaultAccordionProps } from '../types';
import Icon, { Icons } from '../../Icon';
import combineClasses from '../../../utils/combineClasses';

const DefaultAccordion = ({
  summary,
  children,
  divideSummary,
  onClick,
  iconStart,
  open,
  enablePadding = true,
  'data-cy': dataCy,
  ...props
}: DefaultAccordionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(open || false);

  const onAccordionClick: MouseEventHandler<HTMLDetailsElement> = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setIsOpen(!isOpen);

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <details open={isOpen} {...props}>
      <summary
        className={`flex cursor-pointer items-center py-2 px-4 ${
          iconStart ? 'justify-start' : 'justify-between'
        }`}
        data-cy={dataCy}
        onClick={onAccordionClick}
      >
        {iconStart ? (
          <>
            <Icon
              name={isOpen ? Icons.CHEVRON__DOWN : Icons.CHEVRON__RIGHT}
              className="mr-2"
            />
            {summary}
          </>
        ) : (
          <>
            <div>{summary}</div>
            <Icon
              name={isOpen ? Icons.CHEVRON__UP : Icons.CHEVRON__DOWN}
              className="mr-2"
            />
          </>
        )}
      </summary>
      {divideSummary && (
        <div data-cy="divider" className="w-full border-b px-4" />
      )}
      <div className={combineClasses(enablePadding ? 'px-4' : '', 'pb-4')}>
        {children}
      </div>
    </details>
  );
};

export default DefaultAccordion;
