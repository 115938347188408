export const getOpenApiErrorMessage = (e: any, fallback?: string): string => {
  return (
    e.response?.data?.user_friendly_message ||
    e.response?.data?.[0]?.msg ||
    e.response?.data?.detail ||
    fallback ||
    e.message ||
    ''
  );
};

const getErrorMessage = (e: any, fallback?: string): string => {
  return (
    e.body?.user_friendly_message ||
    e.body?.detail?.[0]?.msg ||
    e.body?.detail ||
    fallback ||
    e.message ||
    ''
  );
};

export default getErrorMessage;
