import type {
  TooltipArrowSizes,
  TooltipBaseColors,
} from '../../components/tooltips/types';

export enum OverlayElevation {
  OVERLAY = 'z-notifications',
  TOOLTIP = 'z-tooltips',
  POPOVER = 'z-dialog',
}

export enum OverlayPlacement {
  TOP_START = 'top-start',
  TOP = 'top',
  TOP_END = 'top-end',
  RIGHT_START = 'right-start',
  RIGHT = 'right',
  RIGHT_END = 'right-end',
  BOTTOM_START = 'bottom-start',
  BOTTOM = 'bottom',
  BOTTOM_END = 'bottom-end',
  LEFT_START = 'left-start',
  LEFT = 'left',
  LEFT_END = 'left-end',
}

export type ArrowOptions = {
  color: TooltipBaseColors;
  size: TooltipArrowSizes;
};

export default null;
