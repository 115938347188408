import type { LF } from '@core/types';
// import wrapReducer from '@utils/wrapReducer';

import type { SetPopulatingLFsActionData } from './actionCreators';
import * as actionTypes from './actionTypes';
import type { LFsAction, LFsState } from './types';

export const initialState: LFsState = {
  data: [],
  votes: {},
  loading: true,
  populatingLFsConfig: undefined,
  populatingLFs: false,
  populateLFJobId: '',
  totalCount: 0,
};

const reducer = (
  state: LFsState = initialState,
  { type, data, ...action }: LFsAction,
): LFsState => {
  switch (type) {
    case actionTypes.addLFs: {
      if (!action.lfs) return state;
      const { lfs, upsert } = action;

      const nextLFsMap: Record<string, boolean> = {};

      const isLFNotLoaded = (lf: LF) => {
        const lfAlreadyLoaded = (currentLF: LF) => lf.uid === currentLF.uid;

        nextLFsMap[lf.uid] = true;

        return !state.data.some(lfAlreadyLoaded);
      };

      const newLFs = lfs.filter(isLFNotLoaded);

      let existingData = state.data;

      if (upsert) {
        existingData = existingData.filter(({ uid }: LF) => nextLFsMap[uid]);
      }

      return { ...state, data: [...existingData, ...newLFs] };
    }
    case actionTypes.addLF: {
      return data ? { ...state, data: [data, ...state.data] } : state;
    }
    case actionTypes.setLFs: {
      const newData = action.lfs || [];

      return newData === state.data ? state : { ...state, data: newData };
    }
    case actionTypes.removeLFs: {
      const { uids } = data;

      return uids.length === 0
        ? state
        : { ...state, data: state.data.filter(lf => !uids.includes(lf.uid)) };
    }
    case actionTypes.updateLF: {
      const { lf, id } = action;
      const result = {
        ...state,
        data: state.data.map(({ uid, ...value }) =>
          value.id !== id
            ? { uid, ...value }
            : { ...value, ...(id === lf.id && { uid }), ...lf },
        ),
      };

      return result;
    }
    case actionTypes.updateLFs: {
      const { lfs } = action;

      return lfs
        ? {
            ...state,
            data: state.data.map(lf => {
              const lfMatchedById = lfs.find(({ uid }: LF) => uid === lf.uid);

              return lfMatchedById
                ? {
                    ...lf,
                    metrics: lfMatchedById.metrics,
                    isMetricsLoading: lfMatchedById.isMetricsLoading,
                  }
                : lf;
            }),
          }
        : state;
    }
    case actionTypes.clearLFs: {
      return state.data.length === 0 ? state : { ...state, data: [] };
    }
    case actionTypes.setLFsLoading: {
      return data === state.loading ? state : { ...state, loading: data };
    }
    case actionTypes.setAutosuggestLFsPopulating: {
      const nextData = data as SetPopulatingLFsActionData;

      return nextData === state.populatingLFsConfig
        ? state
        : {
            ...state,
            populatingLFsConfig: nextData,
            populatingLFs: !!nextData,
          };
    }
    case actionTypes.setAutosuggestLFsJobId: {
      return data === state.populateLFJobId
        ? state
        : { ...state, populateLFJobId: data };
    }
    case actionTypes.setLFVotes: {
      return data === state.votes ? state : { ...state, votes: data };
    }
    case actionTypes.resetLFMetrics: {
      return state.data.length === 0
        ? state
        : {
            ...state,
            data: state.data.map((lf: LF) => ({ ...lf, metrics: {} })),
          };
    }
    case actionTypes.updateLFState: {
      return data ? { ...state, ...data } : state;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
