import moment from 'moment';

type ConvertToTimeAgoArgs = {
  time: string | undefined;
  fallback?: string;
};

const convertToTimeAgo = ({ time, fallback }: ConvertToTimeAgoArgs) => {
  if (!time) {
    return fallback || '';
  }

  // Date() interprets time strings in UTC if it ends with Z, and the backend
  // server returns UTC time strings without Z, so add if missing
  const utcTime = time.endsWith('Z') ? time : `${time}Z`;
  const dateTime = new Date(utcTime);

  return moment.utc(dateTime).fromNow();
};

export default convertToTimeAgo;
