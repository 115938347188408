import { useAtomValue } from 'jotai/react';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';

import type { UserMap, Users } from '@app/AdminPage/AdminSettings/types';
import useGetUsers from '@global/PrivateRouteProviders/UsersProvider/hooks/useGetUsers';

import UsersContext from './context';

import { selectedWorkspaceAtom } from '../../store/workspaces/workspacesAtomStore';

type UsersProviderProps = {
  children: React.ReactNode;
};

type UserState = {
  workspaceUsers: Users;
  workspaceUserMap: UserMap;
  users: Users;
};

const initialUserState: UserState = {
  workspaceUsers: [],
  workspaceUserMap: {},
  users: [],
};

const UsersProvider = ({ children }: UsersProviderProps) => {
  const [state, setState] = useState<UserState>(initialUserState);
  const selectedWorkspace = useAtomValue(selectedWorkspaceAtom);

  const { getUsers } = useGetUsers();

  const setUsers = (users: Users) => {
    setState(prev => ({
      ...prev,
      users,
    }));
  };

  const setWorkspaceUsers = (workspaceUsers: Users) => {
    const workspaceUserMap = workspaceUsers.reduce(
      (map, user) => ({ ...map, [user.user_uid]: user }),
      {} as UserMap,
    );

    setState(prev => ({
      ...prev,
      workspaceUsers,
      workspaceUserMap,
    }));
  };

  const pullUsers = async (users: Users) => {
    if (!isNil(selectedWorkspace) && !isNil(selectedWorkspace.workspace_uid)) {
      const nextWorkspaceUsers = await getUsers(
        users,
        selectedWorkspace.workspace_uid!,
      );
      setWorkspaceUsers(nextWorkspaceUsers);
    }

    const nextUsers = await getUsers(users);
    setUsers(nextUsers);
  };

  useEffect(() => {
    pullUsers(state.users);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedWorkspace)]);

  return (
    <UsersContext.Provider value={{ ...state, setUsers, setWorkspaceUsers }}>
      {children}
    </UsersContext.Provider>
  );
};

export default UsersProvider;
