import crypto from 'crypto';
import EventEmitter from 'events';

import * as Sentry from '@sentry/nextjs';
import jwt from 'jsonwebtoken';
import { mutate } from 'swr';

/**
 * REARCH: Encapsulated access token management.
 *
 * Accessing globalAccessToken directly is prohibited.
 *
 * React *will not* re-render components based on a globalAccessToken change.
 */
let globalAccessToken: null | string = null;

export const setGlobalAccessToken = (token: null | string) => {
  // This is to check for sinister XSS payloads - i.e. func() { alert('hi') }
  const partialToken = typeof token === 'string' ? token.slice(0, 20) : null;

  // This is to make sure it's the same sinister payload each time, i.e. check for rapid enumeration of XSS vulnerabilities
  const hashedToken =
    typeof token === 'string'
      ? crypto.createHash('sha256').update(token).digest('hex')
      : null;

  if (token !== null && typeof token !== 'string') {
    Sentry.captureEvent({
      message: 'Failed to set Global Access Token: Invalid Token Type',
      level: 'error',
      tags: { operation: 'setGlobalAccessToken' },
      extra: { partialToken, hashedToken },
    });

    // eslint-disable-next-line no-console
    console.error(
      `accessTokenManager/setGlobalAccessToken/error - You're trying to use setGlobalAccessToken with an invalid token type.`,
    );

    return;
  }

  let isValidTokenInput = false;

  if (token && jwt.decode(token)) {
    isValidTokenInput = true;
  }

  if (token === null) {
    globalAccessToken = null;

    Sentry.captureEvent({
      message: 'Setting Global Access Token',
      level: 'info',
      tags: { operation: 'setGlobalAccessToken' },
      extra: { partialToken, hashedToken },
    });

    // eslint-disable-next-line no-console
    console.info(
      `accessTokenManager/setGlobalAccessToken - Your access token was changed to *null*, React will *not* re-render based on this change.`,
    );

    return;
  }

  if (!isValidTokenInput) {
    Sentry.captureEvent({
      message: 'Failed to set Global Access Token: Invalid Token',
      level: 'error',
      tags: { operation: 'setGlobalAccessToken' },
      extra: { partialToken, hashedToken },
    });

    // eslint-disable-next-line no-console
    console.error(
      `accessTokenManager/setGlobalAccessToken/error - You're trying to use setGlobalAccessToken with an invalid token "${partialToken}"}`,
    );

    return;
  }

  if (isValidTokenInput) {
    globalAccessToken = token;

    Sentry.captureEvent({
      message: 'Setting Global Access Token',
      level: 'info',
      tags: { operation: 'setGlobalAccessToken' },
      extra: { partialToken, hashedToken },
    });

    // eslint-disable-next-line no-console
    console.info(
      `accessTokenManager/setGlobalAccessToken - Your access token was changed, React will *not* re-render based on this change.`,
    );
  }
};

export const getGlobalAccessToken = (): Readonly<null | string> =>
  globalAccessToken;

/**
 * Middleware to ensure we have a valid token before a request is made.
 *
 * @param next - The next function in the middleware chain
 * @returns The next function in the middleware chain
 */
export const validTokenMiddleware = next => (key, fetcher, config) => {
  const token = getGlobalAccessToken();

  // If the token is null or invalid, do not proceed with the fetch
  if (!token || !(token && jwt.decode(token))) {
    return {
      data: null,
      error: new Error(
        `validTokenMiddleware/error - You've passed an invalid token to the fetcher for ${key}`,
      ),
      isValidating: false,
      mutate: () => Promise.resolve(),
    };
  }

  return next(key, fetcher, config);
};

export const tokenEventEmitter = new EventEmitter();
export const GLOBAL_ACCESS_TOKEN_CHANGED_EVENT =
  'GLOBAL_ACCESS_TOKEN_CHANGED_EVENT';

/**
 * Middleware to handle when a token has changed. It will revalidate all of the SWR hooks
 * that happen to change their access token in the middle of a request.
 *
 * To use this, call an EventEmitter with the event GLOBAL_ACCESS_TOKEN_CHANGED_EVENT when
 * updating the token as follows;
 *
 * ```
 * import { tokenEventEmitter, GLOBAL_ACCESS_TOKEN_CHANGED_EVENT } from '@global/AccessTokenManager';
 *
 * tokenEventEmitter.emit(GLOBAL_ACCESS_TOKEN_CHANGED_EVENT);
 * ```
 *
 * @param next - The next function in the middleware chain
 * @returns The next function in the middleware chain
 */
export const changedTokenMiddleware = next => (key, fetcher, config) => {
  tokenEventEmitter.on(GLOBAL_ACCESS_TOKEN_CHANGED_EVENT, () => {
    mutate(key);
  });

  return next(key, fetcher, config);
};
