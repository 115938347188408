type GenerateAPIUrlOptions = {
  protocol?: string;
  host?: string;
  basePath?: string;
};

const generateAPIUrl = (args?: GenerateAPIUrlOptions): string => {
  const {
    protocol = window.location.protocol,
    host = window.location.host,
    basePath = '',
  } = args ?? {};

  return typeof window === 'undefined'
    ? ''
    : `${protocol}//${host}${basePath}/api`;
};

export default generateAPIUrl;
