import React from 'react';
import styled from 'styled-components';

const SkipToContent = styled.a`
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;

  &:focus {
    opacity: 1;
  }
`;

const SkipToMainContent = () => (
  <SkipToContent className="z-100 absolute bg-white" href="#main-content">
    Skip to main content
  </SkipToContent>
);

export default SkipToMainContent;
