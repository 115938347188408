import type { Dispatch } from 'react';

import type { RunningJob } from '@core/types';

export interface Action {
  type: string;
  [x: string]: any;
}

export enum JobProgressNotificationsActions {
  QUEUE_JOB_PROGRESS_BAR = 'QUEUE_JOB_PROGRESS_BAR',
  REMOVE_JOB_PROGRESS_BAR = 'REMOVE_JOB_PROGRESS_BAR',
}

export type JobProgressNotificationsState = {
  jobUid: string;
  onCompleteMessage?: string;
  runningJob: RunningJob;
}[];

export type JobProgressNotificationsContextType = {
  jobsInProgress: JobProgressNotificationsState;
  dispatch: Dispatch<Action>;
};

export default null;
