import palette from '../palette';

export default {
  root: {
    backgroundColor: palette.background.default,
    '&:hover': {
      backgroundColor: '#e5e5e5',
      '& .hoverableButton': {
        opacity: 1,
      },
    },
    '& .hoverableButton': {
      boxShadow: 'none',
      cursor: 'pointer',
      opacity: 0,
      transition: 'all 0.3s',
    },
  },
};
