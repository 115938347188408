import { useEffect } from 'react';

const useEffectAbortable = <Deps extends readonly any[]>(
  func: (signal: AbortSignal) => void,
  deps: Deps,
) => {
  useEffect(() => {
    const abortController = new AbortController();
    func(abortController.signal);

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useEffectAbortable;
