import { PublicRoutes } from '@core/constants';
import { calcBasePath } from '@utils/api/calcBasePath';

const getIsPublicRoute = (route: string) => {
  const basePath = calcBasePath();

  return (Object.values(PublicRoutes) as string[]).includes(
    route.replace(basePath, ''),
  );
};

export default getIsPublicRoute;
