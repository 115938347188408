import type { Workspaces } from '@app/AdminPage/AdminSettings/types';
import HotKeysModal from '@components/HotkeysModal';
import SidebarMenu from '@global/providers/NavigationProvider/NavigationSidebarFooter/common/SidebarMenu';
import type { Workspace } from '@snorkel/api/lib';

import SidebarMenuItem from './common/SidebarMenuItem';

type WorkspacesMenuProps = {
  workspaces: Workspaces;
  onSelectWorkspace: (workspace: Workspace) => void;
  close?: VoidFunction;
};

const WorkspacesMenu = ({
  workspaces,
  onSelectWorkspace,
  close,
}: WorkspacesMenuProps) => {
  return (
    <SidebarMenu>
      {workspaces.map(workspace => (
        <SidebarMenuItem
          key={workspace.workspace_uid!}
          aria-label="select a workspace"
          data-cy={`select-${workspace.name}-workspace`}
          onClick={() => {
            onSelectWorkspace(workspace);
            // This will close the popover, note that the fn name must be named close
            if (close) close();
          }}
        >
          {workspace.name}
        </SidebarMenuItem>
      ))}
      <HotKeysModal />
    </SidebarMenu>
  );
};

export default WorkspacesMenu;
