import React from 'react';

export type HorizontalNavItemProps<T> = {
  id: T;
  children: string;
};

function HorizontalFilterItem<T = string>({
  children,
}: HorizontalNavItemProps<T>) {
  return <>{children}</>;
}

export default HorizontalFilterItem;
