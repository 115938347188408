import { fromPairs } from 'lodash';
import type { Dispatch } from 'react';
import { createContext } from 'react';

import type { Action, ContextTag, DataTagMap, TagType } from '@core/types';

import { TagsActions } from './actions';

export type TagsReducerState = {
  dataTags: DataTagMap[];
  dataTagMap: Record<string, DataTagMap>;
  docDataTags: DataTagMap[];
  availableTags: TagType[];
  availableTagMap: Record<string, TagType>;
  contextTags: ContextTag[];
  openTagsMenu: boolean;
  openSuggestLFMenu: boolean;
  currentTagId: number;
};

export const tagsInitialState: TagsReducerState = {
  dataTags: [],
  docDataTags: [],
  availableTags: [] as TagType[],
  contextTags: [],
  dataTagMap: {},
  availableTagMap: {},
  openTagsMenu: false,
  openSuggestLFMenu: false,
  currentTagId: -1,
};

export type TagsReducerType = (
  state: TagsReducerState,
  action: Action,
) => TagsReducerState;

export type TagsContextType = {
  state: TagsReducerState;
  dispatch: Dispatch<Action>;
};

const initialContext: TagsContextType = {
  state: tagsInitialState,
  dispatch: () => null,
};

export const TagsContext = createContext(initialContext);

const tagsReducer = (
  state: TagsReducerState = tagsInitialState,
  action: Action,
): TagsReducerState => {
  switch (action.type) {
    case TagsActions.SET_AVAILABLE_TAGS: {
      return {
        ...state,
        availableTags: action.data,
        availableTagMap: fromPairs(
          action.data.map(tag => [tag.tag_type_uid, tag]),
        ),
      };
    }
    case TagsActions.SET_DATA_TAGS: {
      return {
        ...state,
        dataTagMap: fromPairs(action.data.map(tag => [tag.x_uid, tag])),
        dataTags: action.data,
      };
    }
    case TagsActions.SET_DOC_DATA_TAGS: {
      return {
        ...state,
        docDataTags: action.data,
      };
    }
    case TagsActions.SET_CONTEXT_TAGS: {
      return {
        ...state,
        contextTags: action.data,
      };
    }
    case TagsActions.SET_DOC_DATA_AND_DATA_TAGS: {
      return {
        ...state,
        docDataTags: action.data.docDataTags,
        dataTags: action.data.dataTags,
        dataTagMap: fromPairs(
          action.data.dataTags.map(tag => [tag.x_uid, tag]),
        ),
      };
    }
    case TagsActions.SET_OPEN_TAGS_MENU: {
      return {
        ...state,
        openTagsMenu: action.data,
      };
    }
    case TagsActions.SET_OPEN_SUGGEST_LF_MENU: {
      return {
        ...state,
        openSuggestLFMenu: action.data.nextOpen,
        currentTagId: action.data.tagId,
      };
    }

    default: {
      return state;
    }
  }
};

export default tagsReducer;
