import { usePageRegionAlerts } from '@components/PageRegion';
import useSnorkelCopyToClipboard from '@snorkel/coral/hooks/useCopyToClipboard';

const useCopyToClipboard = () => {
  const { showSuccessAlert, showErrorAlert } = usePageRegionAlerts();

  return useSnorkelCopyToClipboard(showSuccessAlert, showErrorAlert);
};

export default useCopyToClipboard;
