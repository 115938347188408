import { ButtonThemes } from '../types';

const getBgColorFromTheme = (
  theme: ButtonThemes,
  filled: boolean = false,
): string => {
  if (!filled) return '';

  switch (theme) {
    case ButtonThemes.primary:
      return 'bg-primary';
    case ButtonThemes.secondary:
      return 'bg-secondary';
    case ButtonThemes.default:
      return 'bg-gray-100';
    default:
      return 'bg-white';
  }
};

const getTextColorFromTheme = (
  theme: ButtonThemes,
  filled: boolean = false,
): string => {
  switch (theme) {
    case ButtonThemes.primary:
      return filled ? 'text-white' : 'text-primary';
    case ButtonThemes.secondary:
      return filled ? 'text-white' : 'text-secondary';
    default:
      return 'text-gray-900';
  }
};

const getFilled = (
  filled: boolean,
  theme: ButtonThemes,
  disabled?: boolean,
): string => {
  if (disabled) {
    return 'bg-coolGray-100 text-gray-300';
  }

  return [
    getBgColorFromTheme(theme, filled),
    getTextColorFromTheme(theme, filled),
  ].join(' ');
};

export default getFilled;
