import flags from '@utils/getFlag/flags.json';
import getFlagFromBrowserStorage from '@utils/getFlag/utils/getFlagFromBrowserStorage';
import getIsFlagFromQueryStringTrue from '@utils/getFlag/utils/getIsFlagFromQueryStringTrue';

import getFlagFromEnvVar from './getFlagFromEnvVar';

/**
 * Returns flag status based on an in memory override
 * @param flagName
 */
const getFlagInMemoryOverride = (flagName: string) => {
  const flagStatusFromBrowserStorage = getFlagFromBrowserStorage(flagName);
  const flagStatusFromQueryString = getIsFlagFromQueryStringTrue(flagName);
  const flagStatusFromEnvVar = getFlagFromEnvVar(flagName);

  if (typeof flagStatusFromQueryString === 'boolean') {
    return flagStatusFromQueryString;
  }

  if (
    flags.CONTROL_BROWSER_STORAGE &&
    typeof flagStatusFromBrowserStorage === 'boolean'
  ) {
    return flagStatusFromBrowserStorage;
  }

  if (typeof flagStatusFromEnvVar === 'boolean') {
    return flagStatusFromEnvVar;
  }

  return null;
};

export default getFlagInMemoryOverride;
