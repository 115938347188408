import { createContext } from 'react';

import type { GraphStructure } from '@hooks/useGraph/types';
import { GraphCombiner } from '@hooks/useGraph/types';

import type { DataFiltersDispatch, DataFiltersState } from './utils/types';

const initialGraph: GraphStructure = [GraphCombiner.AND];
export const initialFilterConfig = {
  filters: [],
  graph: initialGraph,
};

export const dataFiltersInitialState: DataFiltersState = {
  config: initialFilterConfig,
  sortOptions: {
    column: '',
    order: 'asc',
    random: false,
  },
  filterBasedSortOptions: {},
  training_set_uid: undefined,
  page: 0,
  limit: 1,
  showAnnotations: false,
};
const State = createContext<DataFiltersState>(dataFiltersInitialState);

const defaultDispatch = () => null;
const Dispatch = createContext<DataFiltersDispatch>(defaultDispatch);

const DataFiltersContext = {
  State,
  Dispatch,
};

export default DataFiltersContext;
