import { useAtomValue } from 'jotai';
import { isNil } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import { usePageRegionAlerts } from '@components/PageRegion';
import { PublicRoutes } from '@core/constants';
import getNotificationMetaData from '@hooks/useMakeOnRequestFailed/utils/getNotificationMetaData';
import useRequest from '@hooks/useRequestWithLogging';
import type { GetCurrentUserResponse } from '@snorkel/api/lib';
import { push } from '@utils/api';
import { usersApi } from '@utils/api/serverRequests';
import getErrorMessage from '@utils/getErrorMessage';
import isExternalJwtLoginEnabled from '@utils/isJwtLoginEnabled';

import { selectedWorkspaceAtom } from '../../store/workspaces/workspacesAtomStore';

const THIRTY_SECONDS = 1000 * 30;

const useCurrentUser = (
  accessToken?: string,
  accessTokenExpiry?: number,
  existingUser?: GetCurrentUserResponse,
) => {
  const request = useRequest();
  const { showErrorAlert } = usePageRegionAlerts();
  const [user, setUser] = useState<GetCurrentUserResponse | undefined>(
    existingUser,
  );
  const currentWorkspace = useAtomValue(selectedWorkspaceAtom);

  const [loading, setLoading] = useState<boolean>(false);

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const cleanup = () => {
    if (timeout.current) {
      clearInterval(timeout.current);
    }
  };

  const getUserCheckExpired = async () => {
    try {
      const nextUser = await usersApi
        .getCurrentUserCurrentUserGet(currentWorkspace.workspace_uid, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(({ data }) => data);

      if (!isNil(user) && !isNil(nextUser)) {
        setUser({
          ...user,
          ...nextUser,
        });

        return;
      }

      if (!isNil(nextUser)) {
        setUser(nextUser);
      }
    } catch (e: any) {
      showErrorAlert({
        message: getErrorMessage(e),
        metaData: getNotificationMetaData(e, {}),
        origin: 'useCurrentUser',
      });
      push(PublicRoutes.ADMIN_LOGIN);
    }
  };

  const getUser = async (workspaceUid?: number) => {
    if (isNil(workspaceUid)) {
      return undefined;
    }

    setLoading(true);

    const nextUser = await request(
      usersApi.getCurrentUserCurrentUserGet,
      {},
      workspaceUid,
    );

    setLoading(false);

    return nextUser;
  };

  const checkIfExpired = () => {
    // If external JWT login is enabled, then skip expiry check. This is because we
    // sometimes do not have the JWT saved in the context, meaning this check will fail
    if (isExternalJwtLoginEnabled()) {
      return;
    }

    if (accessToken && moment().isAfter(accessTokenExpiry)) {
      push(PublicRoutes.ADMIN_LOGIN);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getUserCheckExpired();
      checkIfExpired();

      cleanup();

      timeout.current = setInterval(checkIfExpired, THIRTY_SECONDS);
    }

    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return {
    user,
    loading,
    setUser,
    getUser,
  };
};

export default useCurrentUser;
