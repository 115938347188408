import type { CommentMap, CommentType } from '@core/types';

const editComment =
  ({ x_uid, comment_uid, body }: CommentType) =>
  (comment: CommentMap) => {
    const { comments } = comment;
    if (x_uid === comment.x_uid)
      return {
        x_uid,
        comments: comments.map((c: CommentType) => {
          if (c.comment_uid !== comment_uid) {
            return c;
          }

          return {
            ...c,
            body,
            created_at: c.created_at,
          };
        }),
      };

    return comment;
  };

export default editComment;
