import { difference, isNil, merge } from 'lodash';
import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useReducer, useState } from 'react';

import useSettingsResolver from '@global/PrivateRouteProviders/UserSettingsProvider/hooks/useSettingsResolver';
import getCustomColorsForDatabase from '@global/PrivateRouteProviders/UserSettingsProvider/utils/getCustomColorsForDatabase';
import type { UserSettingsRequestParams } from '@hooks/useGetRequestParams';
import useGetRequestParams from '@hooks/useGetRequestParams';
import useRequest from '@hooks/useRequestWithLogging';
import type { UserSettingsJson } from '@snorkel/api/lib';
import { userSettingsApi } from '@utils/api/serverRequests';

import { updateAllStateAction } from './actions';
import UserSettingsContext, { userSettingsInitialState } from './context';
import userSettingsReducer from './reducer';
import type { UserSettings } from './types';

// Global user settings are application-agnostic and do not enforce
// an application_uid to be present in the request.
const GLOBAL_USER_SETTINGS = ['dag_manipulation_enabled', 'global_preferences'];

const UserSettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [userSettings, setUserSettingsState] = useReducer(
    userSettingsReducer,
    userSettingsInitialState,
  );
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const request = useRequest();
  const requestParams = useGetRequestParams();
  const { settingsResolver } = useSettingsResolver();

  const fetchUserSettings = (
    nextRequestParams: UserSettingsRequestParams,
  ): Promise<UserSettingsJson> => {
    return request(
      userSettingsApi.getUserSettingsUserSettingsGet,
      {},
      nextRequestParams.organization,
      nextRequestParams.application_uid,
      nextRequestParams.node_uid,
      nextRequestParams.user_uid,
    );
  };

  const updateUserSettings = useCallback(
    async (updatedSettings: UserSettings): Promise<UserSettings> => {
      const settingsKeys = Object.keys(updatedSettings);

      // Check if settings keys include any non global settings, block update if true
      const onlyGlobalSettings =
        difference(settingsKeys, GLOBAL_USER_SETTINGS).length === 0;

      if (
        !settingsKeys.length ||
        isNil(requestParams.user_uid) ||
        (isNil(requestParams.application_uid) && !onlyGlobalSettings)
      ) {
        // Prevent setting global workspace settings until we have a reason otherwise.
        return userSettings;
      }

      const {
        custom_colors: customColors,
        studio_preferences: studioPreferences,
        ...settingsToUpdate
      } = updatedSettings;

      // Do not pass back all the settings! Only the ones that have been updated. Keep it sparse.
      const nextSettings: Partial<UserSettingsJson> = {
        ...settingsToUpdate,
        ...(customColors
          ? { custom_colors: getCustomColorsForDatabase(customColors) }
          : {}),
        ...(studioPreferences &&
        requestParams.application_uid &&
        requestParams.node_uid
          ? { studio_preferences: studioPreferences }
          : {}),
      };

      // onlyGlobalSettings should be not be saved at application level, only user level. That's why removing application data from request params and only keeping user uid in request params
      const params = onlyGlobalSettings
        ? { user_uid: requestParams.user_uid }
        : requestParams;

      await request(
        userSettingsApi.updateUserSettingUserSettingsPost,
        {},
        { ...params, settings: nextSettings },
      );

      return merge({}, userSettings, updatedSettings);
    },
    [request, userSettings, requestParams],
  );

  useEffect(() => {
    (async () => {
      setIsFetching(true);

      const result = await fetchUserSettings(requestParams);

      const nextSettings = settingsResolver(result);

      setUserSettingsState(updateAllStateAction(nextSettings));

      if (result?.workspace_type !== nextSettings.workspace_type) {
        // Update user_settings if workspace_type conflicts with user role
        await updateUserSettings({
          workspace_type: nextSettings.workspace_type,
        });
      }

      setIsFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(requestParams)]);

  return (
    <UserSettingsContext.Write.Provider
      value={{ setUserSettingsState, updateUserSettings }}
    >
      <UserSettingsContext.Read.Provider value={{ userSettings, isFetching }}>
        {children}
      </UserSettingsContext.Read.Provider>
    </UserSettingsContext.Write.Provider>
  );
};

export default UserSettingsProvider;
