import {
  queueNotification as queueNotif,
  removeNotification as removeNotif,
  updateProgressNotification as updateNotif,
} from './actions';

import type {
  ErrorAlertProps,
  InfoAlertProps,
  NotificationDispatch,
  NotificationId as NotifId,
  OptionalUid,
  ProgressNotificationProps,
  SuccessAlertProps,
  WarningAlertProps,
  WithoutVariant,
} from '../types';
import { NotificationVariant as NotifVariant } from '../types';

export type NotificationDispatchers = {
  showInfoNotification: (
    notif: WithoutVariant<OptionalUid<InfoAlertProps>>,
  ) => void;
  showWarningNotification: (
    notif: WithoutVariant<OptionalUid<WarningAlertProps>>,
  ) => void;
  showSuccessNotification: (
    notif: WithoutVariant<OptionalUid<SuccessAlertProps>>,
  ) => void;
  showErrorNotification: (
    notif: WithoutVariant<OptionalUid<ErrorAlertProps>>,
  ) => void;
  showProgressBarNotification: (
    notif: WithoutVariant<ProgressNotificationProps>,
  ) => void;
  updateProgressBarNotification: (
    uid: NotifId,
    notif: WithoutVariant<OptionalUid<ProgressNotificationProps>>,
  ) => void;
  removeNotification: (uid: NotifId) => void;
};

const calcDispatchers = (
  dispatch: NotificationDispatch,
): NotificationDispatchers => {
  const show = notif => dispatch(queueNotif(notif));

  const showInfoNotification = (
    notif: WithoutVariant<OptionalUid<InfoAlertProps>>,
  ) => {
    show({ ...notif, variant: NotifVariant.info });
  };

  const showWarningNotification = (
    notif: WithoutVariant<OptionalUid<WarningAlertProps>>,
  ) => {
    show({ ...notif, variant: NotifVariant.warning });
  };

  const showSuccessNotification = (
    notif: WithoutVariant<OptionalUid<SuccessAlertProps>>,
  ) => {
    show({ ...notif, variant: NotifVariant.success });
  };

  const showErrorNotification = (
    notif: WithoutVariant<OptionalUid<ErrorAlertProps>>,
  ) => {
    show({ ...notif, variant: NotifVariant.error });
  };

  const showProgressBarNotification = (
    notif: WithoutVariant<ProgressNotificationProps>,
  ) => {
    show({ ...notif, variant: NotifVariant.progress });
  };

  const updateProgressBarNotification = (
    uid: NotifId,
    notif: Omit<WithoutVariant<ProgressNotificationProps>, 'uid'>,
  ) => {
    dispatch(updateNotif(uid, { ...notif, variant: NotifVariant.progress }));
  };

  const removeNotification = (uid: NotifId) => {
    dispatch(removeNotif(uid));
  };

  return {
    showInfoNotification,
    showWarningNotification,
    showSuccessNotification,
    showErrorNotification,
    showProgressBarNotification,
    updateProgressBarNotification,
    removeNotification,
  };
};

export default calcDispatchers;
