import { Tool } from '@app/StudioPage/components/LFComposerToolbar/types';
import type { LFSummary } from '@snorkel/api/lib/studio/api';

import * as actionTypes from './actionTypes';
import type { TaskAction, TaskState } from './types';

const defaultSummary: LFSummary = {
  total_points: 0,
  total_lfs: 0,
  total_coverage: 0,
  total_conflict: 0,
  class_balance: {},
  label_density: 0,
  label_distribution: {},
  class_coverage: {},
  class_coverage_counts: {},
  skipped_compute: false,
  estimated_num_spans_per_doc: 0,
};

export const initialState: TaskState = {
  name: '',
  task_uid: undefined,
  task_name: '',
  task_config: {
    label_map: {},
    inverse_label_map: {},
    settings: {},
  },
  dataset_uid: undefined,
  dataset_name: '',
  summary: defaultSummary,
  class_labels: [],
  split: null,
  splits: [],
  tool: Tool.SEARCH,
  shortcutsModalOpen: false,
  settings: {
    metrics: [],
    models: [],
    primary_metric: '',
    suggest_field: '',
    templates: [],
    viewer_highlight_configs: [],
    viewer_initially_deselected_fields: [],
    viewer_initially_selected_fields: [],
    default_metrics: [],
    supported_metrics: [],
    model_generated_bodies: undefined,
  },
  processingTaskDataSources: false,
  settings_loading: false,
  dataInitialized: false,
  lfsInfo: [],
  loadingTaskDataSources: false,
  taskDataSources: [],
  activeTaskDataSources: [],
  users: [],
};

const reducer = (
  state: TaskState = initialState,
  { type, data }: TaskAction,
): TaskState => {
  switch (type) {
    case actionTypes.setTask: {
      return { ...state, ...data };
    }
    case actionTypes.setSummary: {
      return {
        ...state,
        summary: { ...state.summary, ...data },
      };
    }
    case actionTypes.setTool: {
      return data === state.tool ? state : { ...state, tool: data };
    }
    case actionTypes.updateTask: {
      return { ...state, ...data };
    }
    case actionTypes.changeSplit: {
      return data === state.split ? state : { ...state, split: data };
    }
    case actionTypes.setShortcutsOpen: {
      return data === state.shortcutsModalOpen
        ? state
        : { ...state, shortcutsModalOpen: data };
    }
    case actionTypes.setProcessingDataSources: {
      return data === state.processingTaskDataSources
        ? state
        : { ...state, processingTaskDataSources: data };
    }
    case actionTypes.setSettingsLoading: {
      return data === state.settings_loading
        ? state
        : { ...state, settings_loading: data };
    }
    case actionTypes.setSettings: {
      return { ...state, settings: { ...state.settings, ...data } };
    }
    case actionTypes.setDataInitialized: {
      return data === state.dataInitialized
        ? state
        : { ...state, dataInitialized: data };
    }
    case actionTypes.resetSummary: {
      return defaultSummary === state.summary
        ? state
        : { ...state, summary: defaultSummary };
    }
    case actionTypes.setLFsInfo: {
      return data === state.lfsInfo ? state : { ...state, lfsInfo: data };
    }
    case actionTypes.startLoadingTaskDatasources: {
      return state.loadingTaskDataSources
        ? state
        : { ...state, loadingTaskDataSources: true };
    }
    case actionTypes.setActiveTaskDatasources: {
      return data === state.activeTaskDataSources
        ? state
        : { ...state, activeTaskDataSources: data };
    }
    case actionTypes.setTaskDatasources: {
      return {
        ...state,
        loadingTaskDataSources: false,
        taskDataSources: data.taskDataSources,
        activeTaskDataSources: data.activeTaskDataSources,
      };
    }
    case actionTypes.setUsers: {
      return data === state.users ? state : { ...state, users: data };
    }
    case actionTypes.setTaskConfig: {
      return {
        ...state,
        task_config: { ...state.task_config, ...data },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
