import type { Dispatch } from 'react';
import { createContext } from 'react';

import type { Action } from '@core/types';

import type { NodeDetailsState } from './reducer';
import { nodeDetailsInitialState } from './reducer';

export type NodeOverviewContextType = {
  state: NodeDetailsState;
  dispatch: Dispatch<Action>;
};

const initialContext: NodeOverviewContextType = {
  state: nodeDetailsInitialState,
  dispatch: () => null,
};

const NodeOverviewContext = createContext(initialContext);

export default NodeOverviewContext;
