import { UserRole } from '@snorkel/api/lib';

import useAuthContext from './useAuthContext';

const useUserRole = () => {
  const { role, user_roles } = useAuthContext();

  return {
    isLabeler: role === UserRole.Labeler,
    isAdmin: role === UserRole.Admin || user_roles.includes(UserRole.Admin),
    isStandard: role === UserRole.Standard,
    isReviewer: role === UserRole.Reviewer,
    isSuperAdmin: user_roles.includes(UserRole.Superadmin),
  };
};

export default useUserRole;
