import type {
  ErrorAlertProps,
  ErrorMetaData,
  OptionalUid,
  WithoutVariant,
} from '@components/Notifications';

export type MakeDisplayErrorMessageProps = {
  showErrorAlert: (props: WithoutVariant<OptionalUid<ErrorAlertProps>>) => void;
  shouldHideErrorSnackbars: boolean;
};

const makeDisplayErrorMessage = ({
  showErrorAlert,
  shouldHideErrorSnackbars,
}: MakeDisplayErrorMessageProps) => {
  const displayErrorMessage = (
    message: string,
    metaData: ErrorMetaData,
    origin: string,
  ) => {
    if (!shouldHideErrorSnackbars) {
      showErrorAlert({ message, metaData, origin });
    }
  };

  return displayErrorMessage;
};

export default makeDisplayErrorMessage;
