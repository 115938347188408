import getRouterQuery from '@utils/getRouterQuery';

const getIsFlagFromQueryStringTrue = (flag: string) => {
  const key = `FLAG_${flag}`;
  const query = getRouterQuery();

  return query[key] ? query[key] === 'true' : null;
};

export default getIsFlagFromQueryStringTrue;
