import { useRef, useState } from 'react';

const useControlledState = <T>(
  externalValue: T | undefined,
  defaultValue: T,
) => {
  const { current: isControlled } = useRef(externalValue !== undefined);

  const [internalValue, setInternalValue] = useState<T>(defaultValue);

  const value = isControlled ? (externalValue as T) : internalValue;

  const setValue = (nextValue: T) => {
    if (isControlled) return;

    setInternalValue(nextValue);
  };

  return [value, setValue] as const;
};

export default useControlledState;
