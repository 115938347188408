import { useState } from 'react';

import type { User } from '@app/AdminPage/AdminSettings/types';
import useRequest from '@hooks/useRequestWithLogging';
import alphabeticalSort from '@utils/alphabeticalSort';
import { usersApi } from '@utils/api/serverRequests';

const useGetUsers = () => {
  const request = useRequest();
  const [loading, setLoading] = useState<boolean>(false);

  const getUsers = async (
    existingUsers: User[] = [],
    workspace_uid?: number,
  ) => {
    setLoading(true);
    const userList = await request(
      usersApi.getListUsersUsersGet,
      {
        fallback: existingUsers,
      },
      true,
      workspace_uid,
    );
    const sortedUserList = userList.toSorted((a, b) =>
      alphabeticalSort(a.username, b.username),
    );
    setLoading(false);

    return sortedUserList;
  };

  return {
    loading,
    getUsers,
  };
};

export default useGetUsers;
