export default {
  primary: {
    contrastText: '#FFFFFF',
    dark: '#000000',
    main: '#0F62FE',
    light: '#7bdcce',
  },
  secondary: {
    contrastText: '#6F6F6F',
    dark: '#CCCCCC',
    main: '#F3F3F3',
    light: '#9e0cec',
  },
  background: {
    default: '#F3F3F3',
    paper: '#FFFFFF',
  },
  textSecondary: {
    main: '#8C8C8C',
  },
  custom: {
    confirm: '#00C259',
  },
  activeColor: '#e2e8f0',
};
