import moment from 'moment';
import React from 'react';

import useGetLicense from '@app/AdminPage/hooks/useGetLicense';
import Alert, { AlertTheme } from '@snorkel/coral/components/Alert';

const LicenseWarnings = () => {
  const { accountInfo, expirationBuffers } = useGetLicense();
  const expirationDelta = moment(accountInfo?.expiration_date).diff(
    moment(),
    'days',
  );

  return (
    <div className="mb-2">
      {expirationBuffers &&
        expirationDelta > 0 &&
        expirationDelta <= expirationBuffers?.license_until_expiration_days && (
          <Alert theme={AlertTheme.warning} data-cy="license-warning">
            Your Snorkel Flow license will expire in {expirationDelta} days!
            Contact the Snorkel AI team to update your license.
          </Alert>
        )}
      {expirationBuffers && expirationDelta <= 0 && (
        <Alert theme={AlertTheme.error} data-cy="license-expired">
          Your Snorkel Flow license has expired. There are{' '}
          {expirationBuffers.license_buffer_days + expirationDelta} days
          remaining before this instance becomes inaccessible.
        </Alert>
      )}
    </div>
  );
};

export default LicenseWarnings;
