import { TextSize, Variant } from '../types';

const getTextWeight = (variant: Variant, size: TextSize) => {
  switch (variant) {
    case Variant.headline:
      switch (size) {
        case TextSize.large:
          return 'font-light';
        case TextSize.medium:
          return 'font-normal';
        case TextSize.small:
          return 'font-normal';
        default:
          throw Error('not a valid size for headline');
      }
    case Variant.subtitle:
      switch (size) {
        case TextSize.large:
          return 'font-medium';
        case TextSize.medium:
          return 'font-semibold';
        default:
          throw Error('not a valid size for subtitle');
      }
    case Variant.body:
      switch (size) {
        case TextSize.large:
          return 'font-normal';
        case TextSize.medium:
          return 'font-normal';
        case TextSize.small:
          return 'font-normal';
        case TextSize.xsmall:
          return 'font-medium';
        default:
          throw Error('not a valid size for body');
      }
    case Variant.mono:
      switch (size) {
        case TextSize.large:
          return 'font-semibold';
        default:
          return 'font-normal';
      }

    default:
      switch (size) {
        case TextSize.large:
          return 'font-normal';
        case TextSize.medium:
          return 'font-normal';
        case TextSize.small:
          return 'font-normal';
        case TextSize.xsmall:
          return 'font-medium';
        default:
          return 'font-normal';
      }
  }
};

export default getTextWeight;
