import type { NextRouter, Router as RouterType } from 'next/router';

import getRouterQuery from '@utils/getRouterQuery';
import { normalizeUrl } from '@utils/normalizeUrl';

const queryFromRouter = (router: RouterType | NextRouter) => {
  return router.query;
};

const getNextPageBasePath = (router?: RouterType | NextRouter) => {
  const query = router ? queryFromRouter(router) : getRouterQuery();
  const { appId, nodeId } = query;

  return normalizeUrl(`/applications/${appId}/nodes/${nodeId}`);
};

export default getNextPageBasePath;
