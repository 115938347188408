import { createContext } from 'react';

import type { Candidate, ContextStateResponse } from '@core/types';

import type { CandidateState } from './types';

import { SpanTextsModelOptions } from '../../types';

export const initialCandidate: Candidate = {
  x_uid: undefined,
  context_uid: undefined,
  span_field: undefined,
  char_start: undefined,
  char_end: undefined,
  span_text: undefined,
};

const initialContextStateResp: ContextStateResponse = {
  data: [],
  compressed_data: {},
  total_count: 0,
  count: 0,
  offset: 0,
  context_fields: [],
  entity_fields: [],
  span_fields: [],
  span_gt_field: '',
  span_uid_field: '',
};

export const initialCandidateState: CandidateState = {
  // data:
  context: undefined,
  contextAbortController: null,
  current: undefined,
  docMode: false,
  offset: 0,
  selectedSpan: undefined,
  spans: [],

  // local state:
  candidate: initialCandidate,
  contextFields: [],
  contextIndex: 0,
  contexts: [],
  currentPage: 1,
  currLfVotes: {},
  lfVotesloading: false,
  loading: false,
  message: null,
  model: SpanTextsModelOptions.GT,
  postprocessors: [],
  selected: [],
  spanFields: [],
  total_count: 0,
  uidField: undefined,

  setCandidate: () => null,
  setContextIndex: () => null,
  setContexts: () => null,
  setContextAbortController: () => null,
  setCurrentPage: () => null,
  setLoading: () => null,
  setModel: () => null,
  setSelected: () => null,

  // fns:
  findSpanContext: () => initialContextStateResp,
  nextDoc: () => null,
  prevDoc: () => null,
  refreshContexts: async () => undefined,
  setPage: () => null,
};

export default createContext(initialCandidateState);
