import type { BackgroundColorType } from '../primitives/types';
import { FilledPrimitive } from '../primitives/types';

const generateBackgroundColor = <FilledDef>(
  filled: BackgroundColorType<FilledDef>,
): FilledPrimitive | string | FilledDef => {
  if (typeof filled === 'boolean') {
    return filled ? FilledPrimitive.DEFAULT : '';
  }

  return filled;
};

export default generateBackgroundColor;
