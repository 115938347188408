import React from 'react';

import Tooltip from '@snorkel/coral/components/tooltips/Tooltip';

const NotificationIndicator = () => (
  <Tooltip title="This application node is uncommitted — commit your best model on the Train page.">
    <div className="inline-flex" data-cy="nav-notification-indicator">
      <div className="ml-1 mb-2 h-2 min-w-2 rounded bg-yellow-400" />
    </div>
  </Tooltip>
);

export default NotificationIndicator;
