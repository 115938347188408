import { useEffect } from 'react';

import useNavigationContext from '@global/providers/NavigationProvider/hooks/useNavigationContext';

const NavigationContextInspector = () => {
  const { meta, navigation, currentApplicationNavigation } =
    useNavigationContext();

  useEffect(() => {
    (window as any).sf = (window as any).sf ?? {};
    (window as any).sf.getNavCtx = () => ({
      meta,
      navigation,
      currentApplicationNavigation,
    });

    return () => {
      // cleanup:
      (window as any).sf.getNavCtx = undefined;
    };
  }, [meta, navigation, currentApplicationNavigation]);

  return null;
};

export default NavigationContextInspector;
