import styled from 'styled-components';

import getTailwindColors from '@snorkel/coral/styles/getTailwindColors';

type ListItemProps = {
  relativeSelector?: boolean;
};

const ListItem = styled.div<ListItemProps>`
  &.selected::before {
    content: '';
    position: absolute;
    left: ${({ relativeSelector }) => (relativeSelector ? '-1px' : '0')};
    width: 1px;
    border-radius: 0 0.25rem 0.25rem 0;
    background-color: ${getTailwindColors().blue['100']};
    height: 35px;
  }
`;

export default ListItem;
