import type { HTMLAttributes } from 'react';
import React from 'react';

import combineClasses from '../utils/combineClasses';

interface DividerProps extends HTMLAttributes<HTMLDivElement> {
  vertical?: boolean;
}

const Divider = (props: DividerProps) => {
  const { vertical, className, ...dividerProps } = props;

  return (
    <div
      {...dividerProps}
      className={combineClasses(
        'border-coolGray-200',
        vertical ? 'h-full border-r' : 'w-full border-t',
        className,
      )}
    />
  );
};

export default Divider;
