type JWTResponse = {
  username: string;
  userUid: number;
  tokenType: string;
  exp: number;
};

const parseJWTFromAccessToken = (accessToken: string): JWTResponse => {
  const {
    username,
    user_uid: userUid,
    token_type: tokenType,
    exp,
  } = JSON.parse(atob(accessToken.split('.')[1]));

  return { username, userUid, tokenType, exp };
};

export default parseJWTFromAccessToken;
