import React from 'react';
import type { StackPrimitiveProps } from './__internal__/StackPrimative';
import StackPrimitive from './__internal__/StackPrimative';

export interface HStackProps extends StackPrimitiveProps {
  className?: string;
}

const HStack = ({ children, className, ...rest }: Partial<HStackProps>) => (
  <StackPrimitive
    {...rest}
    className={className ? `${className} flex-row` : 'flex-row'}
  >
    {children}
  </StackPrimitive>
);

export {
  LayoutAlignment as HStackAlignment,
  LayoutPosition as HStackPosition,
  LayoutSpacing as HStackSpacing,
  LayoutPadding as HStackPadding,
  LayoutTopPadding as HStackTopPadding,
  LayoutBottomPadding as HStackBottomPadding,
  LayoutLeftPadding as HStackLeftPadding,
  LayoutRightPadding as HStackRightPadding,
  LayoutWidth as HStackWidth,
  LayoutHeight as HStackHeight,
  LayoutColor as HStackColor,
  LayoutBorderColor as HStackBorderColor,
  LayoutTopBorderColor as HStackTopBorderColor,
  LayoutBottomBorderColor as HStackBottomBorderColor,
  LayoutLeftBorderColor as HStackLeftBorderColor,
  LayoutRightBorderColor as HStackRightBorderColor,
} from './types';

export default HStack;
