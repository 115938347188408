export enum ButtonThemes {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
}

export enum ButtonSizes {
  bare = 'bare',
  slim = 'slim',
  small = 'small',
  medium = 'medium',
  large = 'large',
}
