import { useState } from 'react';
import { useDebounce } from 'react-use';

import { STANDARD_DEBOUNCE } from '@core/constants';

const useDebouncedValue = <T>(defaultValue: T, delayOverride?: number) => {
  const [value, setValue] = useState<T>(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(defaultValue);

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    delayOverride || STANDARD_DEBOUNCE,
    [value],
  );

  return [value, setValue, debouncedValue] as const;
};

export default useDebouncedValue;
