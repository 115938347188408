import React, { useReducer } from 'react';

import type { TagsContextType, TagsReducerType } from './reducer';
import reducer, { TagsContext, tagsInitialState } from './reducer';

type TagsProps = {
  children: React.ReactNode;
  initialValue?: Partial<TagsContextType>;
};

const TagsProvider = ({ children, initialValue }: TagsProps) => {
  const [state, dispatch] = useReducer<TagsReducerType>(
    reducer,
    initialValue?.state || tagsInitialState,
  );

  return (
    <TagsContext.Provider
      value={{ dispatch: initialValue?.dispatch || dispatch, state }}
    >
      {children}
    </TagsContext.Provider>
  );
};

TagsProvider.defaultProps = {
  initialValue: {},
};

export default TagsProvider;
