import type { ErrorMetaData } from '@components/Notifications';
import type { RunningJob } from '@core/types';

const getJobFailureNotificationMetaData = (detail: RunningJob['detail']) => {
  const metaData: ErrorMetaData = {};

  if (detail?.exception_detail) {
    metaData.details = detail.exception_detail;
  }

  if (detail?.stacktrace) {
    metaData.traceback = detail.stacktrace;
  }

  return metaData;
};

export default getJobFailureNotificationMetaData;
