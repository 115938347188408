import { WorkspaceTypes } from '@app/StudioPage/types';
import {
  AnnotationRoutes,
  ApplicationAnnotationRoutes,
  DevelopmentRoutes,
} from '@core/constants';
import type { CurrentUserSupportedFeatures } from '@snorkel/api/lib';

const getWorkspaceFromFeaturesAndPath = (
  supportedFeatures: CurrentUserSupportedFeatures,
  currentPath: string,
  currentWorkspace?: WorkspaceTypes,
) => {
  const developmentSupported = supportedFeatures.development_workspace;

  if (
    Object.values(DevelopmentRoutes).some(path => currentPath.includes(path)) &&
    developmentSupported
  ) {
    return WorkspaceTypes.DEVELOPMENT;
  }

  if (
    Object.values(AnnotationRoutes).some(path => currentPath.includes(path))
  ) {
    return WorkspaceTypes.ANNOTATION;
  }

  if (currentPath.includes(ApplicationAnnotationRoutes.WORK)) {
    return WorkspaceTypes.ANNOTATION;
  }

  if (!developmentSupported) return WorkspaceTypes.ANNOTATION;

  return currentWorkspace || WorkspaceTypes.DEVELOPMENT;
};

export default getWorkspaceFromFeaturesAndPath;
