/* eslint-disable local-rules/no-layering-violations */
import useBackendFlags from '@hooks/useBackendFlags';
import useCachedRequest from '@hooks/useCachedRequest';
import useGetRequestParams from '@hooks/useGetRequestParams';
import { BackendFeatureFlagNames } from '@snorkel/api/lib';
import { applicationsApi } from '@utils/api/serverRequests';

const useIsCurrentAppOnboardingEnabled = () => {
  const { application_uid } = useGetRequestParams();

  const { isLoading: isLoadingSetupProgress, data: setupData } =
    useCachedRequest(
      applicationsApi.getSetupProgressApplicationApplicationUidSetupGet,
      { suspendFetch: !application_uid, getSnackbarDataOnError: null },
      Number(application_uid),
    );

  const { getBackendFlag } = useBackendFlags();

  const isOnboardingFlagEnabled =
    getBackendFlag(BackendFeatureFlagNames.Onboarding) ?? false;

  return Boolean(
    isOnboardingFlagEnabled && !isLoadingSetupProgress && setupData,
  );
};

export default useIsCurrentAppOnboardingEnabled;
