export default {
  tooltip: {
    backgroundColor: '#252525',
    color: 'white',
    fontSize: 12,
    borderRadius: 3,
  },
  arrow: {
    color: '#252525',
  },
};
