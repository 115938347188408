import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { withStyles } from '@material-ui/styles';

type Props = {
  color?: string;
};

const DropdownArrow = ({ color }: Props) => {
  const Icon = withStyles({
    root: {
      color: color || 'white',
    },
  })(ArrowDropUp);

  return <Icon />;
};

export default DropdownArrow;
