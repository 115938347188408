/**
 * Reference: https://www.figma.com/file/SqNJZTvByLQHLsb24s1lAk/Coral-2021?node-id=1729%3A6854
 */
export enum SpacingPrimitive {
  collapse = '0',
  xs = '1', // 4px
  s = '2', // 8px
  default = '3', // 12px
  l = '4', // 16
  xl = '6', // 24
  '2xl' = '8', // 32
  '3xl' = '16', // 64
  '4xl' = '32', // 128
}

export enum SizingPrimitive {
  xs,
  s,
  default,
  l,
  xl,
  '2xl',
  '3xl',
  '4xl',
}

export enum PaddingPrimitive {
  none = 'p-0',
  xs = 'p-1',
  s = 'p-2',
  default = 'p-3',
  l = 'p-4',
  xl = 'p-5',
  '2xl' = 'p-6',
}

export enum PaddingTopPrimitive {
  none = 'pt-0',
  xs = 'pt-1',
  s = 'pt-2',
  default = 'pt-3',
  l = 'pt-4',
  xl = 'pt-5',
  '2xl' = 'pt-6',
}

export enum PaddingBottomPrimitive {
  none = 'pb-0',
  xs = 'pb-1',
  s = 'pb-2',
  default = 'pb-3',
  l = 'pb-4',
  xl = 'pb-5',
  '2xl' = 'pb-6',
}

export enum PaddingRightPrimitive {
  none = 'pr-0',
  xs = 'pr-1',
  s = 'pr-2',
  default = 'pr-3',
  l = 'pr-4',
  xl = 'pr-5',
  '2xl' = 'pr-6',
}

export enum PaddingLeftPrimitive {
  none = 'pl-0',
  xs = 'pl-1',
  s = 'pl-2',
  default = 'pl-3',
  l = 'pl-4',
  xl = 'pl-5',
  '2xl' = 'pl-6',
}

export enum TextColorPrimitive {
  DEFAULT = 'text-coolGray-900',
  ALT = 'text-white',
  GRAY = 'text-coolGray-600',
  PRIMARY = 'text-primary',
  POSITIVE = 'text-green-600',
  DISABLED = 'text-coolGray-400',
  WARNING = 'text-yellow-600',
  NEGATIVE = 'text-red-600',
}

export enum BorderColorPrimitive {
  DEFAULT = 'border-gray-border',
  ALT = 'border-coolGray-200',
  PRIMARY = 'border-primary',
  WHITE = 'border-white',
  TRANSPARENT = 'border-transparent',
}

export enum BorderTopColorPrimitive {
  DEFAULT = 'border-t-gray-border',
  ALT = 'border-t-coolGray-200',
  PRIMARY = 'border-t-primary',
  WHITE = 'border-t-white',
  TRANSPARENT = 'border-t-transparent',
}

export enum BorderBottomColorPrimitive {
  DEFAULT = 'border-b-gray-border',
  ALT = 'border-b-coolGray-200',
  PRIMARY = 'border-b-primary',
  WHITE = 'border-b-white',
  TRANSPARENT = 'border-b-transparent',
}

export enum BorderLeftColorPrimitive {
  DEFAULT = 'border-l-gray-border',
  ALT = 'border-l-coolGray-200',
  PRIMARY = 'border-l-primary',
  WHITE = 'border-l-white',
  TRANSPARENT = 'border-l-transparent',
}

export enum BorderRightColorPrimitive {
  DEFAULT = 'border-r-gray-border',
  ALT = 'border-r-coolGray-200',
  PRIMARY = 'border-r-primary',
  WHITE = 'border-r-white',
  TRANSPARENT = 'border-r-transparent',
}

export enum FilledPrimitive {
  DEFAULT = 'bg-white',
  ALT = 'bg-coolGray-100',
}

export enum WidthPrimitive {
  FLEX = 'flex-1',
  FULL = 'w-full',
  AUTO = 'w-auto',
  DEFAULT = '',
}

export enum HeightPrimitive {
  FLEX = 'flex-1',
  FULL = 'h-full',
  AUTO = 'h-auto',
  DEFAULT = '',
}

export interface Position<Attribute> {
  top: boolean | Attribute;
  left: boolean | Attribute;
  right: boolean | Attribute;
  bottom: boolean | Attribute;
}

export type BackgroundColorType<FilledDef> = FilledDef | boolean;

export interface BackgroundColorProperty<BackgroundColorDef> {
  backgroundColor: BackgroundColorType<BackgroundColorDef>;
}

export type BorderType<BorderDef> =
  | boolean
  | BorderDef
  | Partial<Position<BorderDef>>;

export type PaddingType<SpacingDef> =
  | boolean
  | SpacingDef
  | Partial<Position<SpacingDef>>;

export interface VariantProperty<VariantDef> {
  variant: VariantDef;
}

export interface SizeProperty<SizeDef> {
  size: SizeDef;
}

export interface SpacingProperty<SpacingDef> {
  spacing: SpacingDef;
}

export interface TextColorProperty {
  color: TextColorPrimitive;
}

export interface PaddingProperty<SpacingDef> {
  padding: PaddingType<SpacingDef>;
}

export interface BorderProperty<BorderDef> {
  border: BorderType<BorderDef>;
}

export interface RoundedProperty {
  rounded: boolean;
}

export interface WrapProperty {
  wrap: boolean;
}

export interface BlankProperty {
  blank: boolean;
}

export interface WidthProperty<WidthDef> {
  width: WidthDef;
}

export interface HeightProperty<HeightDef> {
  height: HeightDef;
}
