import Link from 'next/link';
import type { LinkProps } from 'next/link';
import type { PropsWithChildren } from 'react';

import { normalizeUrl } from '@utils/normalizeUrl';

const SnorkelLink = ({ href, ...props }: PropsWithChildren<LinkProps>) => (
  <Link href={normalizeUrl(href.toString())} {...props} />
);

export default SnorkelLink;
