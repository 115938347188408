import { capitalize } from 'lodash';
import { useState } from 'react';

import useSetTimeout from '@hooks/useSetTimeout';
import Button, {
  ButtonSizes,
  ButtonThemes,
} from '@snorkel/coral/components/Button';
import Icon, { Icons } from '@snorkel/coral/components/Icon';
import useSnorkelCopyToClipboard from '@snorkel/coral/hooks/useCopyToClipboard';
import HStack from '@snorkel/coral/layout/HStack';

import type {
  AlertNotificationProps,
  NotificationId,
  NotificationItem,
} from '../types';
import { NotificationDuration, NotificationVariant } from '../types';
import getNotificationConfig from '../utils/getNotificationConfig';
import parsePythonError from '../utils/parsePythonError';

export type NotificationProps = {
  notification: AlertNotificationProps;
  onClose: (uid: NotificationId) => void;
  autoHideDuration?: NotificationDuration;
};

const noop = () => {};

const calcMetaDataEntries = (notification: NotificationItem) => {
  const metaData =
    notification.variant === NotificationVariant.error
      ? notification.metaData ?? {}
      : {};

  return Object.entries(metaData);
};

const calcTitle = (notification: NotificationItem) => {
  return notification.variant === NotificationVariant.success
    ? notification.title
    : undefined;
};

const calcButtonAction = (notification: NotificationItem) => {
  return notification.variant === NotificationVariant.success
    ? notification.buttonAction
    : undefined;
};

const Notification = ({
  notification,
  autoHideDuration = NotificationDuration.DEFAULT,
  onClose,
}: NotificationProps) => {
  const { variant, uid, message } = notification;
  const notificationConfig = getNotificationConfig(variant);
  const metaDataEntries = calcMetaDataEntries(notification);
  const title = calcTitle(notification) ?? notificationConfig.title;
  const buttonAction = calcButtonAction(notification);

  const [showDetails, setShowDetails] = useState<boolean>(false);

  // This would loop
  // const { showSuccessAlert, showErrorAlert } =
  //   usePageRegionAlerts();
  const { handleCopy } = useSnorkelCopyToClipboard(noop, noop);

  const handleClose = () => {
    onClose(uid);
  };

  useSetTimeout(handleClose, autoHideDuration);

  const toggleShowDetails = () => {
    setShowDetails(t => !t);
  };

  const parsedErrorMessage = parsePythonError(message);

  return (
    <div
      role="alert"
      data-cy="notification"
      className={`my-1 flex rounded border-l-4 bg-white shadow-lg ${notificationConfig.borderClass} py-4 px-2 text-sm`}
    >
      <div className="flex h-5 w-1/12 items-center justify-center" />

      <div className="flex w-10/12 flex-col">
        <p className={`flex ${notificationConfig.textClass}`}>{title}</p>
        <div className="my-2 max-h-76 overflow-y-auto break-words">
          <span data-cy="notification-message">{parsedErrorMessage}</span>
        </div>
        {buttonAction && (
          <div className="mt-1">
            <Button
              {...buttonAction}
              size={ButtonSizes.slim}
              blank
              theme={ButtonThemes.primary}
              data-cy={`notification-buttonAction-${notification.uid}`}
            />
          </div>
        )}
        {metaDataEntries.length > 0 && (
          <div className="mt-1">
            <HStack>
              <Button
                data-cy="view-notification-details"
                size={ButtonSizes.small}
                onClick={toggleShowDetails}
              >
                <>
                  <Icon
                    className={`inline ${showDetails ? 'rotate-90' : ''}`}
                    name={Icons.CHEVRON__RIGHT}
                  />
                  View {`${showDetails ? 'less' : 'details'}`}
                </>
              </Button>
              <Button
                data-cy="copy-notification-details"
                size={ButtonSizes.small}
                onClick={() =>
                  handleCopy(
                    metaDataEntries
                      .map(
                        ([label, value]) =>
                          `${label}: ${parsePythonError(value)}`,
                      )
                      .join('\n'),
                    'Details',
                  )
                }
              >
                <>
                  <Icon className="mr-1 inline" name={Icons.COPY} />
                  Copy details
                </>
              </Button>
            </HStack>
            {showDetails && (
              <div
                data-cy="notification-meta-data"
                className="mt-2 max-h-56 overflow-y-auto border-t border-gray-200 text-xs text-gray-700"
              >
                {metaDataEntries.map(([label, value]) => (
                  <div
                    key={label}
                    className="mt-1 flex flex-col border-b border-gray-200 p-2"
                  >
                    <div className="break-all">{capitalize(label)}</div>
                    <div className="mt-2 break-all font-mono">
                      {parsePythonError(value)}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="w-1/12">
        <button
          className="flex h-8 w-8 items-center justify-center rounded border border-solid border-transparent"
          onClick={handleClose}
          data-cy="close-notification"
          type="button"
        >
          <Icon name={Icons.X} />
        </button>
      </div>
    </div>
  );
};

export default Notification;
