import { createContext } from 'react';

import type { DocFiltersDispatch, DocFiltersState } from './utils/types';

import { initialFilterConfig } from '../DataFiltersContext';

export const docFiltersInitialState: DocFiltersState = {
  config: initialFilterConfig,
  training_set_uid: undefined,
  page: 0,
  limit: 10,
  showAnnotations: false,
};
const State = createContext<DocFiltersState>(docFiltersInitialState);

const defaultDispatch = () => null;
const Dispatch = createContext<DocFiltersDispatch>(defaultDispatch);

const DocFiltersContext = { State, Dispatch };

export default DocFiltersContext;
