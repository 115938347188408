import { isTruthy } from '@utils/isTruthy';

/**
 * Returns flag status based on env vars
 * that have been shared to a browser context via next.js
 * @param flagName
 */
const getFlagFromEnvVar = (flagName: string): boolean | null => {
  // When an env var is prefixed with NEXT_PUBLIC_,
  // next.js will expose it to the browser
  const publicRuntimePrefix = 'NEXT_PUBLIC_';
  const publicRuntimeEnvVarName = `${publicRuntimePrefix}${flagName}`;
  const flagNameFromEnvVar = process.env[publicRuntimeEnvVarName];

  if (isTruthy(flagNameFromEnvVar)) {
    return flagNameFromEnvVar === 'true';
  }

  return null;
};

export default getFlagFromEnvVar;
