/* eslint-disable local-rules/no-layering-violations */
import type { AxiosResponse } from 'axios';

import type { UseRequestOptions } from '@snorkel/useRequest/types';

/**
 * OpenAPI functions look something like this:
 *
 *   function (param1, param2, options) {
 *     var _this = this;
 *     return (0, exports.SomeApiFp)(this.configuration).getSomethingSomethingGet(param1, param2, options).then(function (request) { return request(_this.axios, _this.basePath); });
 *   }
 *
 * We want to grab the OpenAPI request name. To do that we can stringify
 * the function and then split the string a few times. In the end we are left
 * with the `getSomethingSomethingGet(param1, param2, options)` part.
 */
const createCacheKey = <
  TArgs extends any[] = any[],
  TPromise extends Promise<AxiosResponse<any>> = Promise<AxiosResponse<any>>,
>(
  openApiPromise: ((...args: TArgs) => TPromise) | null,
  args: TArgs,
  transformer: UseRequestOptions['onSuccess'] | null = null,
): string | null => {
  if (!openApiPromise) {
    return null;
  }

  const stringifiedRequestFunction = openApiPromise
    .toString()
    .split('(this.configuration).')?.[1]
    ?.split('.then(function (request)')?.[0];

  const stringifiedRequestArgs = args.map(arg => JSON.stringify(arg)).join('-');

  // If the request has a transformation function,
  // we should recognize it in the cache key. This prevents clobber between
  // same requests that desire different formats.
  const stringifiedResponseTransformer = transformer
    ? transformer.toString().replace(/\s+/g, '')
    : '';

  return `${stringifiedRequestFunction}:${stringifiedRequestArgs}${stringifiedResponseTransformer}`;
};

export default createCacheKey;
