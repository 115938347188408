import type { CSSProperties, ImgHTMLAttributes } from 'react';
import React from 'react';

import { useCoralContext } from '../CoralContext';
import Icons from './icons';

export { Icons };

function getFilter(hueRotationDegrees: number) {
  return `brightness(0) saturate(100%) invert(27%) sepia(90%) saturate(3072%) hue-rotate(${hueRotationDegrees}deg) brightness(98%) contrast(105%)`;
}

type IconColor = 'red' | 'blue' | 'yellow' | 'white';
export interface IconProps extends ImgHTMLAttributes<HTMLImageElement> {
  name: Icons;
  inverted?: boolean;
  disabled?: boolean;
  color?: IconColor;
}

const calcIconStyle = (
  inverted?: boolean,
  disabled?: boolean,
  color?: IconColor,
): CSSProperties => {
  if (inverted) {
    return { filter: 'invert(1) brightness(10)' };
  }

  if (disabled) {
    return { filter: 'opacity(0.5)' };
  }

  switch (color) {
    case 'blue':
      return { filter: getFilter(215) };
    case 'red':
      return { filter: getFilter(0) };
    case 'yellow':
      return { filter: getFilter(50) };
    case 'white':
      return { filter: `brightness(0) invert(1)` };
    default:
      return {};
  }
};

// Icons are purely decorative
const Icon = React.forwardRef(
  (
    { name, inverted, disabled, color, ...rest }: IconProps,
    ref: React.Ref<HTMLImageElement>,
  ) => {
    const { basePath } = useCoralContext();
    const src = `${basePath}/static/icons/${name}.svg`;

    return (
      <img
        alt={`${name} icon`}
        {...rest}
        src={src}
        ref={ref}
        style={calcIconStyle(inverted, disabled, color)}
      />
    );
  },
);

export default Icon;
