import Textv2, {
  TextColor,
  TextSize,
  Variant,
} from '@snorkel/coral/components/Textv2';

interface NavTitleProps {
  title: string;
}

const NavTitle = ({ title }: NavTitleProps) => {
  return (
    <div className="px-2">
      <Textv2
        variant={Variant.body}
        color={TextColor.GRAY}
        size={TextSize.small}
      >
        {title}
      </Textv2>
    </div>
  );
};

export default NavTitle;
