/**
 * This utility function will parse errors that are of the shape
 * { loc, msg, type }[] that are passed to us in certain unpredictable
 * circumstances from the backend.
 *
 * Once we standardize error shapes, we need to replace occurences of this
 */

// This is an example payload that we need to process
// {
//     "detail": [
//         {
//             "loc": [
//                 "body",
//                 "source_type"
//             ],
//             "msg": "field required",
//             "type": "value_error.missing"
//         }
//     ]
// }
export type PythonError = {
  loc: string[];
  msg: string;
  type: string;
};

const parsePythonError = (detail: string | PythonError[]): string => {
  if (typeof detail === 'string') return detail;
  if (Array.isArray(detail) && detail.length > 0) return detail[0].msg;

  return '';
};

export default parsePythonError;
