import uniq from 'lodash/uniq';
import { useEffect } from 'react';

import { usePageRegionAlerts } from '@components/PageRegion';
import { SplitType } from '@core/types';
import type { DataSource } from '@core/types';
import * as taskActionTypes from '@global/store/root/task/actionTypes';
import useRequest from '@hooks/useRequestWithLogging';
import { useTaskDispatchContext } from '@hooks/useRootContext/useTaskDispatchContext';
import { useTaskStateContext } from '@hooks/useRootContext/useTaskStateContext';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import { nodesApi } from '@utils/api/serverRequests';
import getInitialSplit from '@utils/getInitialSplit';
import { getItem, setItem } from '@utils/localStorage';
import shouldDisableSplit from '@utils/shouldDisableSplit';

export const getSplitLocalStorageKey = (nodeId: string | number) =>
  `${nodeId}-split`;

const useUpdateInitialSplit = () => {
  const request = useRequest();
  const { name, split } = useTaskStateContext();
  const nodeId: number = name;

  const dispatch = useTaskDispatchContext();

  const { query } = useSnorkelRouter();
  const { showErrorAlert } = usePageRegionAlerts();

  const setSplit = async (signal: AbortSignal) => {
    const dataSources = await request(
      nodesApi.getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet,
      {},
      nodeId,
    );

    if (!dataSources || signal.aborted) {
      return;
    }

    const splits: SplitType[] = uniq(
      dataSources
        .filter(
          ({
            is_active: isActive,
            supports_dev: supportsDev,
            split: splitName,
          }: DataSource) => {
            if (splitName === SplitType.TRAIN) {
              return supportsDev && isActive;
            }

            return isActive;
          },
        )
        .map(({ split: dsSplit }: DataSource) => dsSplit as SplitType),
    );

    if (!splits.length) {
      showErrorAlert({
        message:
          'No active data sources. Activate data sources and return to this page.',
        origin: 'useUpdateInitialSplit',
      });

      return;
    }

    const currentSplit =
      query.split ||
      split ||
      (getItem(getSplitLocalStorageKey(nodeId), '') as string);
    const newSplit = shouldDisableSplit(currentSplit, splits)
      ? getInitialSplit(splits)
      : currentSplit;

    dispatch({
      type: taskActionTypes.setTask,
      data: { split: newSplit, splits, dataSources },
    });

    setItem(getSplitLocalStorageKey(nodeId), newSplit);
  };

  useEffect(() => {
    if (!nodeId) {
      return () => {};
    }

    const abortController = new AbortController();
    setSplit(abortController.signal);

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId]);
};

export default useUpdateInitialSplit;
