type GenerateAPIUrlOptions = {
  protocol?: string;
  host?: string;
  basePath?: string;
};

const generateAPIUrl = (args?: GenerateAPIUrlOptions): string => {
  if (typeof window === 'undefined' || !window) return '';

  const {
    protocol = window.location.protocol,
    host = window.location.host,
    basePath = '',
  } = args ?? {};

  return `${protocol}//${host}${basePath}/api`;
};

export default generateAPIUrl;
