import React from 'react';
import getAlertColors from './utils/getAlertColors';
import { AlertTheme } from './types';

export { AlertTheme };

type AlertProps = {
  children: React.ReactNode;
  className?: string;
  theme?: AlertTheme;
  'data-cy'?: string;
};

const Alert = (props: AlertProps) => {
  const {
    children,
    className,
    theme = AlertTheme.info,
    'data-cy': dataCy,
  } = props;

  const classNameTheme = getAlertColors(theme);

  return (
    <div
      className={`rounded border-l-4 p-4 ${classNameTheme || ''} ${
        className || ''
      }`}
      {...(dataCy ? { 'data-cy': dataCy } : {})}
    >
      {children}
    </div>
  );
};

export default Alert;
