import type { MouseEventHandler } from 'react';
import React, { useState } from 'react';
import HStack, { HStackAlignment, HStackSpacing } from '../../../layout/HStack';
import Icon, { Icons } from '../../Icon';
import Text, { TextSize, TextVariant, TextWeight } from '../../Text';
import { TextColor } from '../../Text/types';
import type { OnboardingInnerAccordionProps } from '../types';

const OnboardingInnerAccordion = ({
  summary,
  children,
  open,
  isDisabled = false,
  ...props
}: OnboardingInnerAccordionProps) => {
  const [isOpen, toggleIsOpen] = useState<boolean>(open || false);

  if (open !== undefined && open !== isOpen) {
    toggleIsOpen(open);
  }

  const onAccordionClick: MouseEventHandler<HTMLDetailsElement> = e => {
    if (isDisabled || open !== undefined) {
      e.preventDefault();

      return;
    }

    e.stopPropagation();

    toggleIsOpen(!isOpen);
  };

  const onToggle = (e: React.MouseEvent<HTMLDetailsElement>) => {
    e.stopPropagation();
  };

  return (
    <details
      open={open}
      {...props}
      className="rounded border"
      onToggle={onToggle}
    >
      <summary
        onClick={onAccordionClick}
        className="flex cursor-pointer select-none items-center justify-between rounded p-2 focus:outline-none"
      >
        <HStack alignment={HStackAlignment.CENTER} spacing={HStackSpacing.s}>
          <Icon
            name={Icons.CHEVRON__RIGHT}
            className={isOpen ? 'rotate-90' : ''}
          />
          <Text
            size={TextSize.s}
            weight={TextWeight.semibold}
            variant={TextVariant.subtitle}
            className={isDisabled ? TextColor.DISABLED : ''}
          >
            {summary}
          </Text>
        </HStack>
      </summary>
      <div className="box-border">{children}</div>
    </details>
  );
};

export default OnboardingInnerAccordion;
