import palette from '../palette';

export default {
  root: {
    borderTop: '5px',
  },
  indicator: {
    top: '0',
  },
  scrollButtons: {
    backgroundColor: palette.background.paper,
    width: '20px',
  },
};
