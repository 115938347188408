import { ENABLE_MONITORING_TAB } from '@core/feature_flag';

const labelShortcuts = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  ...new Array(26).fill(0).map((e, i) => String.fromCharCode(i + 97)),
];

export const dataViewerKeyMap = {
  prevData: ['ctrl+k', 'left'],
  nextData: ['ctrl+j', 'right'],
  tableView: ['ctrl+t'],
  expandedView: ['ctrl+e'],
  toggleRawDisplay: ['ctrl+r'],
  nextPage: ['ctrl+i'],
  prevPage: ['ctrl+u'],
  prevLabel: ['ctrl+o'],
  nextLabel: ['ctrl+p'],
};

export const labelViewerKeyMap = {
  handleNumberPressed: labelShortcuts,
};

export const autoSuggestKeyMap = {
  autoSuggestLf: ['a'],
};

export const createLFMenuKeyMap = {
  openMenu: ['n'],
};

export const searchMap = {
  focusSearchLFs: ['ctrl+shift+f'],
  focusSearchPDF: ['ctrl+k'],
};

export const lfWidgetKeyMap = {
  saveLf: ['ctrl+s'],
  duplicateLf: ['ctrl+d'],
};

export const lfBuilderHeaderKeyMap = {
  closeLF: ['esc'],
};

export const navigationKeyMap = {
  navigateTo: [
    'g+1',
    'g+2',
    'g+3',
    'g+4',
    'g+5',
    'g+6',
    ...(ENABLE_MONITORING_TAB ? ['g+7'] : []),
  ],
};

export const documentKeyMap = {
  nextDoc: ['right'],
  prevDoc: ['left'],
  nextCandidate: ['down'],
  prevCandidate: ['up'],
  handleNumberPressed: labelShortcuts,
};

export const shortcutsModalKeyMap = {
  toggleShortcutsModal: ['shift+/'],
};

export const openDocsKeyMap = {
  openDocs: ['shift+d'],
};

export const tagsMenuMap = {
  openTaggingMenu: ['shift+t'],
};
