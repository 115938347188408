import React, { useEffect } from 'react';

import type { WorkspaceTypes } from '@app/StudioPage/types';
import useUserWorkspaceType from '@global/PrivateRouteProviders/UserSettingsProvider/hooks/useUserWorkspaceType';
import useAuthContext from '@hooks/useAuthContext';
import useCurrentPath from '@hooks/useCurrentPath';

import WorkspaceContext from './context';
import getWorkspaceFromFeaturesAndPath from './utils/getWorkspaceFromFeaturesAndPath';

type WorkspaceProviderProps = React.PropsWithChildren<{}>;

const WorkspaceProvider = ({ children }: WorkspaceProviderProps) => {
  const { workspaceType, updateWorkspaceType, isFetching } =
    useUserWorkspaceType();
  const { user_uid: userUid, supported_features: supportedFeatures } =
    useAuthContext();
  const stringifedSupportedFeatures = JSON.stringify(supportedFeatures);
  const currentPath = useCurrentPath();

  const setWorkspaceType = async (nextWorkspace: WorkspaceTypes) => {
    if (isFetching || !userUid) {
      // Don't call `updateRoute` until `workspaceType` returned via `user_settings`
      // `workspaceType` will be defined eventually, but delay to prevent a race condition on refresh
      return;
    }

    await updateWorkspaceType(nextWorkspace);
  };

  useEffect(() => {
    const nextWorkspace = getWorkspaceFromFeaturesAndPath(
      supportedFeatures,
      currentPath,
      workspaceType,
    );

    setWorkspaceType(nextWorkspace);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userUid,
    currentPath,
    stringifedSupportedFeatures,
    workspaceType,
    isFetching,
  ]);

  return (
    <WorkspaceContext.Provider value={{ workspaceType, setWorkspaceType }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;
