import { usePageRegionAlerts } from '@components/PageRegion';
import useRequest from '@snorkel/useRequest';

const useRequestWithLogging = () => {
  const { showErrorAlert } = usePageRegionAlerts();

  return useRequest(showErrorAlert);
};

export default useRequestWithLogging;
