import copyToClipboard from './utils/copyToClipboard';

const useCopyToClipboard = (onSuccess, onFailure) => {
  const handleCopy = (value: string, successText?: string) => {
    const { success, error = '' } = copyToClipboard(value);

    if (success) {
      onSuccess({
        message: `${successText || value} copied to clipboard`,
      });

      return;
    }

    onFailure({
      message: `There was an error copying text to your clipboard: ${error}`,
    });
  };

  return { handleCopy };
};

export default useCopyToClipboard;
