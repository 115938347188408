import * as FullStory from '@fullstory/browser';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import type { NextPage } from 'next';
import { withSecureHeaders } from 'next-secure-headers';
import type { AppContext } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import type { ReactElement, ReactNode } from 'react';
import React from 'react';

import { NotificationsProvider } from '@components/Notifications';
import SkipToMainContent from '@components/SkipToMainContent';
import { TITLE } from '@core/strings';
import AuthContextProvider from '@global/AuthContextProvider';
import CacheProvider from '@global/CacheProvider';
import JobProgressNotificationsProvider from '@global/JobProgressNotificationsProvider';
import NavigationWrapper from '@global/NavigationWrapper';
import NotificationWebSocketHandler from '@global/NotificationWebSocketsWrapper';
import PrivateRouteProviders from '@global/PrivateRouteProviders';
import RootApp from '@global/RootApp';
import type { GetCurrentUserResponse } from '@snorkel/api/lib';
import { CoralContextProvider } from '@snorkel/coral/components/CoralContext';
import generateDefaultHeaders from '@snorkel/useRequest/utils/generateDefaultHeaders';
import { calcBasePath } from '@utils/api/calcBasePath';
import { tdmServerRequest } from '@utils/api/serverRequests';
import auth from '@utils/auth';
import getIsPublicRoute from '@utils/auth/utils/getIsPublicRoute';
import { calcApiUrl } from '@utils/calcApiUrl';
import getFlag, { Flags } from '@utils/getFlag';
import getIsServerRendered from '@utils/getIsServerRendered';
import initSentry from '@utils/initSentry';
import isPageGatedByRunningJob from '@utils/isPageGatedByRunningJob';
import { normalizeUrl } from '@utils/normalizeUrl';
import setWindowLocationHref from '@utils/setWindowLocationHref';

import Error from './_error';

import theme from '../theme';

import '@snorkel/coral/styles/snorkel.css';
import '../css/tailwind.css';

type AppProps = {
  accessToken: string;
  accessTokenExpiry: number;
  existingUser?: GetCurrentUserResponse;
  statusCode?: number;
};

type AppState = {
  isPublicRoute: boolean;
};

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

/**
 * Accessibility tool - outputs to devtools console on dev only and client-side only.
 * @see https://github.com/dequelabs/react-axe
 */
if (process.env.NODE_ENV !== 'production' && !getIsServerRendered()) {
  if (window.location.search.includes('axe=true')) {
    // eslint-disable-next-line global-require
    const ReactDOM = require('react-dom');

    // eslint-disable-next-line
    const axe = require('react-axe');

    axe(React, ReactDOM, 1000);
  }
}

const basePath = calcBasePath();

const FULLSTORY_ORG_ID = '17N11F';
class MyApp extends App<AppPropsWithLayout, AppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isPublicRoute: getIsServerRendered()
        ? props.isPublicRoute
        : getIsPublicRoute(window.location.pathname),
    };
  }

  static async getInitialProps(appContext: AppContext) {
    const location =
      (getIsServerRendered() ? appContext.router : appContext.ctx) || {};
    const currentPath = (location.asPath || '').split('?')[0];

    const isPublicRoute = getIsPublicRoute(currentPath);

    const {
      accessToken,
      expiry,
      user: existingUser,
    } = await auth(
      appContext.ctx,
      { currentPath, isPublicRoute },
      location.query || {},
    );

    const getComponentInitialProps = async () => {
      if (!appContext.Component.getInitialProps) {
        return App.getInitialProps(appContext);
      }

      // App.getInitialProps basically calls below, and wraps the result around { pageProps }
      // see https://nextjs.org/docs/advanced-features/custom-app
      const pageProps = await appContext.Component.getInitialProps({
        ...appContext.ctx,
        accessToken,
        existingUser,
      } as any);

      return { pageProps };
    };

    const appProps = await getComponentInitialProps();

    try {
      // Test that tdm url is valid [ch2103]
      if (getIsServerRendered()) {
        await tdmServerRequest('');
      } else {
        const apiUrl = calcApiUrl();

        await fetch([apiUrl, 'version'].join('/'), {
          headers: generateDefaultHeaders(),
        });
      }
    } catch (err: any) {
      return { ...appProps, isPublicRoute, statusCode: 500 };
    }

    if (
      !isPublicRoute &&
      (await isPageGatedByRunningJob(currentPath, accessToken))
    ) {
      const dataSourceUrl = `${currentPath
        .split('/')
        .slice(0, -1)
        .join('/')}/datasources`;

      if (getIsServerRendered() && appContext.ctx.res) {
        appContext.ctx.res.writeHead(302, {
          Location: normalizeUrl(dataSourceUrl),
        });

        appContext.ctx.res.end();

        return { ...appProps, isPublicRoute };
      }

      setWindowLocationHref(normalizeUrl(dataSourceUrl));
    }

    return {
      ...appProps,
      accessToken,
      accessTokenExpiry: expiry,
      existingUser,
      isPublicRoute,
    };
  }

  componentDidMount = () => {
    const jssStyles = document.querySelector('#jss-server-side');

    if (getFlag(Flags.FULL_STORY)) {
      FullStory.init({
        orgId: FULLSTORY_ORG_ID,
        devMode: process.env.NODE_ENV !== 'production',
      });
    }

    initSentry();

    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  };

  static getDerivedStateFromProps(): any | null {
    if (getIsServerRendered()) {
      return {};
    }

    return {
      isPublicRoute: getIsPublicRoute(window.location.pathname),
    };
  }

  render = () => {
    const {
      props: {
        Component,
        pageProps,
        accessToken = '',
        accessTokenExpiry,
        pageProps: { statusCode } = {},
        existingUser,
      },
    } = this;

    const { isPublicRoute } = this.state as AppState;

    if (statusCode && (statusCode >= 500 || statusCode === 404)) {
      // We only want to show the error for 500s, 401s are valid health checks
      return <Error statusCode={statusCode} />;
    }

    const defaultGetLayout = page => (
      <NavigationWrapper isPublicRoute={isPublicRoute}>
        {page}
      </NavigationWrapper>
    );
    const getLayout = Component.getLayout ?? defaultGetLayout;
    const renderPageContent = () => getLayout(<Component {...pageProps} />);

    const renderHead = () => (
      <Head>
        <title>{TITLE}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <link rel="icon" href={normalizeUrl('/static/favicon.ico')} />
        <meta name="theme-color" content={theme.palette.primary.main} />
        <script src="/__ENV.js" />
      </Head>
    );

    const renderBody = () => (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CoralContextProvider basePath={basePath}>
          <NotificationsProvider>
            <RootApp>
              <AuthContextProvider
                accessToken={accessToken}
                accessTokenExpiry={accessTokenExpiry}
                existingUser={existingUser}
                basePath={basePath}
              >
                <CacheProvider>
                  <JobProgressNotificationsProvider>
                    {!isPublicRoute ? (
                      <PrivateRouteProviders>
                        {/* The NotificationWebSocketHandler must be inside the PrivateRouteProvider, so it can access user settings state */}
                        <NotificationWebSocketHandler>
                          <SkipToMainContent />
                          {renderPageContent()}
                        </NotificationWebSocketHandler>
                      </PrivateRouteProviders>
                    ) : (
                      renderPageContent()
                    )}
                  </JobProgressNotificationsProvider>
                </CacheProvider>
              </AuthContextProvider>
            </RootApp>
          </NotificationsProvider>
        </CoralContextProvider>
      </ThemeProvider>
    );

    return (
      <>
        {renderHead()}
        {renderBody()}
      </>
    );
  };
}

const ProcessedApp =
  process.env.NODE_ENV === 'production'
    ? withSecureHeaders({
        contentSecurityPolicy: {
          directives: {
            defaultSrc: "'self'",
            styleSrc: ['*', 'data:', 'blob:', "'unsafe-inline'"],
            scriptSrc: [
              '*',
              'data:',
              'blob:',
              "'unsafe-inline'",
              "'unsafe-eval'",
            ],
            imgSrc: ['*', 'data:', 'blob:'],
            fontSrc: ['*', 'data:', 'blob:'],
            connectSrc: ['*', 'data:', 'blob:'],
            mediaSrc: ['*', 'data:', 'blob:', 'filesystem:'],
            frameSrc: ['*', 'data:', 'blob:'],
            frameAncestors: ['*', 'data:', 'blob:'],
          },
        },
      })(MyApp)
    : MyApp;

export default ProcessedApp;
