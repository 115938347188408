import { useRouter } from 'next/router';

import { calcBasePath } from '@utils/api/calcBasePath';
import { normalizeUrl } from '@utils/normalizeUrl';

type UseRouterResult = ReturnType<typeof useRouter>;

const normalizeUrlObj = url => {
  return typeof url === 'object'
    ? { ...url, pathname: normalizeUrl(url.pathname) }
    : normalizeUrl(url);
};

const useSnorkelRouter = (): UseRouterResult => {
  const { asPath, push, replace, ...router } = useRouter();
  const basePath = calcBasePath();

  return {
    ...router,
    asPath: asPath ? asPath.replace(basePath, '') : asPath,
    push: (url, as, options) => push(normalizeUrlObj(url), as, options),
    replace: (url, as, options) => replace(normalizeUrlObj(url), as, options),
  };
};

export default useSnorkelRouter;
