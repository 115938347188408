import type { WorkspaceTypes } from '@app/StudioPage/types';
import getCustomColors from '@global/PrivateRouteProviders/UserSettingsProvider/utils/getCustomColors';
import getWorkspaceFromFeaturesAndPath from '@global/PrivateRouteProviders/WorkspaceProvider/utils/getWorkspaceFromFeaturesAndPath';
import useAuthContext from '@hooks/useAuthContext';
import useCurrentPath from '@hooks/useCurrentPath';
import useGetRequestParams from '@hooks/useGetRequestParams';
import type { UserSettingsJson } from '@snorkel/api/lib';

import type { UserSettings } from '../types';

const useSettingsResolver = () => {
  const requestParams = useGetRequestParams();
  const { supported_features: supportedFeatures } = useAuthContext();
  const currentPath = useCurrentPath();

  const settingsResolver = (result: UserSettingsJson) => {
    const {
      custom_colors: customColors,
      workspace_type: workspaceType,
      studio_preferences: studioPreferences,
      ...settings
    } = result || {};

    // Do a snake to camel case transforming here, if necessary
    const nextSettings: UserSettings = {
      ...settings,
      ...(customColors ? { custom_colors: getCustomColors(customColors) } : {}),
      ...(requestParams.application_uid
        ? {
            workspace_type: getWorkspaceFromFeaturesAndPath(
              supportedFeatures,
              currentPath,
              workspaceType as WorkspaceTypes | undefined,
            ),
          }
        : {}),
      // Trim studio_preferences if request does not contain application_uid
      // or node_uid, since we are not in Studio
      ...(studioPreferences &&
      requestParams.application_uid &&
      requestParams.node_uid
        ? { studio_preferences: studioPreferences }
        : {}),
    };

    return nextSettings;
  };

  return { settingsResolver };
};

export default useSettingsResolver;
