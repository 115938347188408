import { isEqual } from 'lodash';

import * as actionTypes from './actionTypes';
import type { DataAction, DataState } from './types';

export const initialState: DataState = {
  fields: [],
  field_types: [],
  fieldsMap: {},
  expanded: true,
  labels: [],
  config: {
    span_preview_offset_field: '',
    span_preview_field: '',
    span_text_field: '',
    span_field: '',
    char_start_field: '',
    char_end_field: '',
    context_x_uid_field: '',
  },
  uid_field: '',
  gt_field: '',
  nextPageData: [],
  previousPageData: [],
  paginatedData: [],
  loading: true,
  selectedIndex: 0,
  total_count: 0,
  total_labeled_count: 0,
  total_dev_count: 0,
  session_labeled_count: 0,
  total_index: [],
  regex: {
    spans: [],
    field: '',
  },
  initialLoad: false,
  datasources: [],
};

const reducer = (
  state: DataState = initialState,
  { type, data }: DataAction,
): DataState => {
  switch (type) {
    case actionTypes.updateDataState: {
      const newState = { ...state, ...data };

      return isEqual(state, newState) ? state : newState;
    }
    case actionTypes.setExpandedView: {
      return data === state.expanded ? state : { ...state, expanded: data };
    }
    case actionTypes.setDataConfig: {
      const newState = { ...state, config: data };

      return isEqual(state, newState) ? state : newState;
    }
    case actionTypes.setSelectedIndex: {
      return data === state.selectedIndex
        ? state
        : { ...state, selectedIndex: data };
    }
    case actionTypes.setDataLoading: {
      return data === state.loading ? state : { ...state, loading: data };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
