import { useEffect, useRef } from 'react';

const noop: Function = () => {};

const useInterval = (callback: Function, delay: number | null) => {
  const savedCallback = useRef(noop);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }

    return undefined;
  });
};

export default useInterval;
