import type { ReactNode, MouseEvent, HTMLAttributes, Ref } from 'react';
import React, { forwardRef } from 'react';
import type { Icons } from '../../components/Icon';
import Icon from '../../components/Icon';
import combineClasses from '../../utils/combineClasses';
import Tooltip from '../../components/tooltips/TooltipV2';

export enum IconButtonSizes {
  default = 'default',
  large = 'large',
  small = 'small',
  Xsmall = 'x-small',
}

type IconButtonChildrenOptions =
  | { children: ReactNode; icon?: never }
  | { children?: never; icon: Icons };

interface IconButtonOptions extends HTMLAttributes<HTMLButtonElement> {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  active?: boolean;
  size?: IconButtonSizes;
  submit?: boolean;
  tooltip?: string;
  href?: string;
  'data-cy'?: string;
}

export type IconButtonProps = IconButtonOptions & IconButtonChildrenOptions;

const IconButton = (
  props: IconButtonProps,
  forwardedRef: Ref<HTMLButtonElement>,
) => {
  const {
    children,
    icon,
    onClick,
    disabled,
    tooltip,
    href,
    active,
    className,
    size = IconButtonSizes.default,
    submit,
    ...otherProps
  } = props;

  const activeClasses = active
    ? 'border-cyan-300 bg-cyan-100 text-blue-600'
    : 'border-transparent text-gray-600';

  const getSize = () => {
    if (size === IconButtonSizes.small) {
      return 'w-smallButton h-smallButton';
    }

    if (size === IconButtonSizes.Xsmall) {
      return 'w-5 h-5';
    }

    if (size === IconButtonSizes.large) {
      return 'w-10 h-10';
    }

    return 'h-8 w-8';
  };

  const renderIcon = icon ? (
    <Icon name={icon} className="m-auto" disabled={disabled} />
  ) : (
    children
  );

  return (
    <Tooltip title={tooltip}>
      <button
        ref={forwardedRef}
        type={submit ? 'submit' : 'button'}
        disabled={disabled}
        {...(typeof active === 'boolean' ? { 'aria-selected': active } : {})}
        aria-disabled={disabled}
        onClick={onClick}
        className={combineClasses(
          getSize(),
          'flex-none rounded border text-sm',
          disabled
            ? 'cursor-not-allowed text-gray-100'
            : 'hover:border-cyan-300 hover:bg-cyan-200',
          activeClasses,
          className,
        )}
        {...otherProps}
      >
        {href ? (
          <a href={href} target="_blank" rel="noreferrer">
            {renderIcon}
          </a>
        ) : (
          renderIcon
        )}
      </button>
    </Tooltip>
  );
};

export default forwardRef(IconButton);
