import { useEffect } from 'react';

const useOnEscape = (operation: VoidFunction) => {
  useEffect(() => {
    const onEscape = ({ key }: KeyboardEvent) => {
      if (['Escape', 'Esc'].includes(key)) {
        operation();
      }
    };

    window.addEventListener('keydown', onEscape);

    return () => {
      window.removeEventListener('keydown', onEscape);
    };
  }, []);
};

export default useOnEscape;
