import { atom } from 'jotai/vanilla';

import { DEFAULT_WORKSPACE } from '@core/constants';
import type { Workspace } from '@snorkel/api/lib';

export const workspacesAtom = atom<Workspace[]>([]);

export const adminWorkspacesAtom = atom<Workspace[]>([]);

export const selectedWorkspaceAtom = atom<Workspace>(DEFAULT_WORKSPACE);

export default null;
