import { FONT_SANS } from '@core/constants';

export default {
  h1: {
    fontFamily: FONT_SANS,
  },
  h2: {
    fontFamily: FONT_SANS,
  },
  h3: {
    fontFamily: FONT_SANS,
  },
  h4: {
    fontFamily: FONT_SANS,
  },
  h5: {
    fontFamily: FONT_SANS,
  },
  h6: {
    fontFamily: FONT_SANS,
  },
  caption: {
    fontStyle: 'italic',
    fontSize: '0.6em',
    fontFamily: FONT_SANS,
  },
  body1: {
    fontSize: '1em',
  },
  root: {},
};
