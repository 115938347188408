import type { ParsedUrlQuery } from 'querystring';

import type { NextComponentType, NextPageContext } from 'next';

import { PublicRoutes } from '@core/constants';
import type { AccessTokenData } from '@core/types';
import { usersApi } from '@utils/api/serverRequests';
import getUnauthenticatedHomeRoute from '@utils/auth/utils/getUnauthenticatedHomeRoute';
import makeGetAccessToken from '@utils/makeGetAccessToken';
import redirect from '@utils/redirect';

type AuthArgs = {
  currentPath: string;
  isPublicRoute: boolean;
};

const auth = async (
  ctx: NextPageContext,
  args: AuthArgs,
  query: ParsedUrlQuery,
): Promise<AccessTokenData> => {
  const { currentPath, isPublicRoute } = args;

  try {
    const authData = await makeGetAccessToken()(ctx);

    if (!isPublicRoute && !authData.accessToken) {
      throw new Error('No access token');
    }

    if (isPublicRoute && authData.accessToken) {
      let nextURL = '/';

      if (typeof query.nextURL === 'string') {
        const queryURL = decodeURIComponent(query.nextURL);

        if (queryURL.startsWith('/')) {
          nextURL = queryURL;
        } else {
          const urlObject = new URL(queryURL);

          if (urlObject.origin === window.location.origin) {
            nextURL = queryURL;
          }
        }
      }

      redirect(nextURL, ctx, {
        noReturnUrl: true,
      });
    }

    const user = await usersApi
      .getCurrentUserCurrentUserGet(undefined, {
        headers: {
          Authorization: `Bearer ${authData.accessToken}`,
        },
      })
      .then(({ data }) => data);

    return {
      ...authData,
      user,
    };
  } catch (err: any) {
    if (
      !isPublicRoute ||
      !(
        currentPath === PublicRoutes.ADMIN ||
        currentPath === PublicRoutes.INVITE
      )
    ) {
      const redirectUrl = await getUnauthenticatedHomeRoute(
        ctx.asPath
          ? {
              disableUsers: ctx.asPath.includes('__cy__noUsers'),
            }
          : {},
      );

      if (redirectUrl !== currentPath) {
        redirect(redirectUrl, ctx);
      }
    }

    // need to send a proper return value even if user is redirected
    return {
      accessToken: '',
      expiry: 0,
    };
  }
};

export const getDisplayName = <P>(
  Component: NextComponentType<NextPageContext, {}, P>,
) => Component.displayName || Component.name || 'Component';

export default auth;
