import { useCallback, useState } from 'react';

import type { ErrorAlertData, FnSetErrorAlertData } from './types';

type UseAlertTriggerValue = {
  errorAlertData: ErrorAlertData | null;
  setErrorAlertData: FnSetErrorAlertData;
  resetError: () => void;
};

const useAlertTrigger = (): UseAlertTriggerValue => {
  const [errorAlertData, setErrorAlertData] = useState<ErrorAlertData | null>(
    null,
  );

  const resetError = useCallback(
    () => setErrorAlertData(null),
    [setErrorAlertData],
  );

  return { errorAlertData, setErrorAlertData, resetError };
};

export default useAlertTrigger;
