import { TextSize, Variant } from '../types';

const getTextWeight = (variant: Variant, size: TextSize) => {
  switch (variant) {
    case Variant.headline:
      switch (size) {
        case TextSize.large:
          return 'leading-9';
        case TextSize.medium:
          return 'leading-8';
        case TextSize.small:
          return 'leading-8';
        default:
          throw Error('not a valid size for headline');
      }
    case Variant.subtitle:
      return 'leading-6';
    case Variant.body:
      switch (size) {
        case TextSize.large:
          return 'leading-6';
        case TextSize.medium:
          return 'leading-6';
        case TextSize.small:
          return 'leading-4';
        case TextSize.xsmall:
          return 'leading-4';
        default:
          throw Error('not a valid size for body');
      }
    case Variant.mono:
      return 'leading-8';
    default:
      throw Error('not a valid variant');
  }
};

export default getTextWeight;
