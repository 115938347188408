import type { NavItem } from '@global/providers/NavigationProvider/types';

type CalcParentPathsArgs = {
  navItems: NavItem[];
  targetHref: string;
};

const calcParentPaths = ({ navItems, targetHref }: CalcParentPathsArgs) => {
  return navItems.reduce((acc: string[], navItem: NavItem) => {
    if (navItem.hidden) return acc;

    if (
      navItem.href &&
      (navItem.href === targetHref || navItem.hrefOverride === targetHref)
    ) {
      return acc.concat(navItem.href);
    }

    if (navItem?.children?.data) {
      const childHrefs = calcParentPaths({
        navItems: navItem.children.data,
        targetHref,
      });

      if (childHrefs.length > 0 && navItem.href) {
        return acc.concat(navItem.href, childHrefs);
      }
    }

    return acc;
  }, []);
};

export default calcParentPaths;
