import type { LabelColorObject as LabelColorForDatabase } from '@snorkel/api/lib';
import type { LabelColor } from '@snorkel/coral/utils/styles/labelColors';

const getCustomColors = (customColors: LabelColorForDatabase[]) =>
  customColors.reduce(
    (
      acc: LabelColor[],
      {
        label_text_color: labelTextColor,
        label_background_color: labelBackgroundColor,
      }: LabelColorForDatabase,
    ) => {
      if (!(labelTextColor && labelBackgroundColor)) {
        return acc;
      }

      return [
        ...acc,
        {
          labelTextColor,
          labelBackgroundColor,
        },
      ];
    },
    [],
  );

export default getCustomColors;
