import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { GlobalHotKeys } from 'react-hotkeys';

import { FONT_MONO } from '@core/constants';
import * as taskActionTypes from '@global/store/root/task/actionTypes';
import { useTaskDispatchContext } from '@hooks/useRootContext/useTaskDispatchContext';
import { useTaskStateContext } from '@hooks/useRootContext/useTaskStateContext';
import { shortcutsModalKeyMap } from '@utils/hotKeysMap';

const dataviewerShortcuts = [
  { shortcut: '⬆ or ⬅', description: 'Previous data' },
  { shortcut: '⮕ or ⬇', description: 'Next data' },
  { shortcut: '0', description: 'Label UNKNOWN class' },
  { shortcut: '[1-9]', description: 'Label using the label map index' },
];

const dataviewerDocumentShortcuts = [
  { shortcut: '⬅', description: 'Previous document' },
  { shortcut: '⮕', description: 'Next document' },
  { shortcut: '⬆', description: 'Previous candidate' },
  { shortcut: '⬇', description: 'Next candidate' },
  { shortcut: '0', description: 'Label UNKNOWN class' },
  { shortcut: '[1-9]', description: 'Label using the label map index' },
];

const tableShortcuts = [
  { shortcut: '⬅', description: 'Previous page' },
  { shortcut: '⮕', description: 'Next page' },
  { shortcut: '⬆', description: 'Previous document' },
  { shortcut: '⬇', description: 'Next document' },
  { shortcut: 'option+t', description: 'Open tagging menu' },
  { shortcut: 'option+x', description: 'Select document' },
];

const useStyles = makeStyles(() => ({
  shortcuts: {
    margin: '4px 0 8px',
    fontFamily: FONT_MONO,
  },
  button: {
    float: 'right',
    padding: '4px',
  },
  content: {
    width: '700px',
  },
}));

const HotKeysModal = () => {
  const classes = useStyles();
  const { shortcutsModalOpen } = useTaskStateContext();

  const dispatch = useTaskDispatchContext();

  const toggleModal = () => {
    dispatch({
      type: taskActionTypes.setShortcutsOpen,
      data: !shortcutsModalOpen,
    });
  };

  const handlers = {
    toggleShortcutsModal: toggleModal,
  };

  return (
    <>
      <GlobalHotKeys
        handlers={handlers}
        keyMap={shortcutsModalKeyMap}
        allowChanges
      />
      <Dialog open={shortcutsModalOpen} onClose={toggleModal} maxWidth={false}>
        <DialogTitle disableTypography>
          Shortcuts
          <IconButton onClick={toggleModal} className={classes.button}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography variant="h6">Dataviewer</Typography>
          <Typography variant="body2" color="textSecondary">
            Note: For these to work you need to select a row in dataviewer
          </Typography>
          <Grid className={classes.shortcuts} container spacing={2}>
            {dataviewerShortcuts.map(({ shortcut, description }: any) => (
              <Grid item xs={6} key={shortcut}>
                - {shortcut} : {description}
              </Grid>
            ))}
          </Grid>
          <Typography variant="h6">Dataviewer (Document mode)</Typography>
          <Grid className={classes.shortcuts} container spacing={2}>
            {dataviewerDocumentShortcuts.map(
              ({ shortcut, description }: any) => (
                <Grid item xs={6} key={shortcut}>
                  - {shortcut} : {description}
                </Grid>
              ),
            )}
          </Grid>
          <Typography variant="h6">Table View</Typography>
          <Grid className={classes.shortcuts} container spacing={2}>
            {tableShortcuts.map(({ shortcut, description }: any) => (
              <Grid item xs={6} key={shortcut}>
                - {shortcut} : {description}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default HotKeysModal;
