import type { Flag } from '@core/types';
import { FLAG_LOCALSTORAGE_KEY } from '@utils/getFlag/constants';
import { getItem } from '@utils/localStorage';

const getFlagFromBrowserStorage = (flag: string) => {
  const storedFlags = getItem(FLAG_LOCALSTORAGE_KEY, {}) as Flag;

  return storedFlags[flag];
};

export default getFlagFromBrowserStorage;
