import type { CSSProperties } from 'react';
import React, { useState } from 'react';
import useOnEscape from '../../hooks/useOnEscape';

// eslint-disable-next-line import/no-cycle
import ConfirmCloseModal from './ConfirmCloseModal';

const DEFAULT_MODAL_WIDTH = 600;

type ModalItemProps = {
  width: number;
  height?: CSSProperties['height'];
};

type ModalProps = {
  onClose: VoidFunction;
  children: React.ReactNode;
  confirmClose?: { text: string };
  open?: boolean;
  flex?: boolean;
  noBackdrop?: boolean;
} & Partial<ModalItemProps>;

/**
 * Renders a modal with an overlay. Closes upon clicking on cancel button, the overlay, or escape key.
 *
 * Caller is responsible for clearing state after submit. When the open prop is passed, <Modal/> will persist state
 * when closed by toggling CSS to avoid rerenders that would clear the state.
 * @param props
 * @returns <Modal>
 */
const Modal = ({
  onClose,
  confirmClose,
  children,
  width,
  height,
  open = true,
  flex,
  noBackdrop,
}: ModalProps) => {
  const [confirmCloseModalVisible, setConfirmCloseModalVisible] =
    useState<boolean>(false);

  const handleClose = () => {
    if (confirmClose) {
      setConfirmCloseModalVisible(true);

      return;
    }

    onClose();
  };

  const handleConfirmClose = () => {
    setConfirmCloseModalVisible(false);
  };

  useOnEscape(handleClose);

  const modalItemStyles: CSSProperties = {
    width: width || DEFAULT_MODAL_WIDTH,
    maxWidth: width || DEFAULT_MODAL_WIDTH,
    height: height || '',
    maxHeight: '95%',
  };

  return (
    <>
      {confirmClose && confirmCloseModalVisible && (
        <ConfirmCloseModal
          onClose={handleConfirmClose}
          onConfirm={onClose}
          text={confirmClose.text}
        />
      )}
      <div
        className={`fixed top-0 left-0 z-backdrop flex h-full w-full items-center justify-center overflow-y-auto ${
          open ? '' : 'hidden'
        }`}
        data-cy="modal"
        role="dialog"
      >
        <div
          style={modalItemStyles}
          className={`relative z-dialog overflow-y-auto rounded bg-white shadow-lg ${
            flex ? 'flex flex-col' : ''
          }`}
          data-cy="modal-item"
        >
          {children}
        </div>
        <div
          className={`fixed top-0 left-0 z-backdrop h-full w-full opacity-70 ${
            noBackdrop ? '' : 'bg-black'
          }`}
          onClick={handleClose}
          data-cy="modal-backdrop"
        />
      </div>
    </>
  );
};

export default Modal;
