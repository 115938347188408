import { merge } from 'lodash';

import { usePageRegionAlerts } from '@components/PageRegion';
import useAuthContext from '@hooks/useAuthContext';
import useCachedRequest from '@hooks/useCachedRequest';
import useRequest from '@hooks/useRequestWithLogging';
import type {
  BackendFeatureFlags,
  UnifiedFlagResponse,
} from '@snorkel/api/lib';
import { userSettingsApi } from '@utils/api/serverRequests';

/**
 * Hook for getting and setting backend feature flags.
 * TODO(ENG-16376): Unify with frontend feature flags.
 */
const useBackendFlags = () => {
  const request = useRequest();
  const { user_uid: userUid } = useAuthContext();
  const { showErrorAlert } = usePageRegionAlerts();

  const updateBackendFlag = async (
    flagEntry: Record<string, UnifiedFlagResponse>,
  ) => {
    const flagToUpdate = Object.entries(flagEntry);

    if (flagToUpdate.length !== 1) {
      showErrorAlert({
        message: `Expected exactly one flag to update, but got ${flagToUpdate.length}`,
        origin: 'useBackendFlags',
      });
    }

    const [flagName, { enabled }] = flagToUpdate[0];

    const backendFlag: BackendFeatureFlags = {
      [flagName]: enabled,
    };

    await request(
      userSettingsApi.updateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost,
      {},
      { backend_feature_flags: backendFlag, user_uid: userUid },
    );
  };

  const {
    data: backendFlags,
    mutate,
    isLoading,
  } = useCachedRequest(
    userSettingsApi.getBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet,
    {
      onMutate: updateBackendFlag,
    },
    userUid,
  );

  const getBackendFlag = (flagName: keyof BackendFeatureFlags) =>
    backendFlags?.[flagName]?.enabled;

  const mutateWithArgs = (
    flagName: keyof BackendFeatureFlags,
    flagValue: boolean,
  ) => {
    const nextFlagEntry: Record<string, UnifiedFlagResponse> = {
      [flagName]: {
        description: backendFlags?.[flagName].description ?? '',
        enabled: flagValue,
      },
    };

    mutate({
      onMutateArgs: nextFlagEntry,
      optimisticData: merge({}, backendFlags, nextFlagEntry),
    });
  };

  return {
    backendFlags: backendFlags ?? {},
    getBackendFlag,
    updateBackendFlag: mutateWithArgs,
    isLoading,
  };
};

export default useBackendFlags;
