const classes = {
  open: 'border border-gray-300 bg-gray-100',
  active:
    'border border-cyan-400 bg-cyan-200 text-cyan-500 hover:border-cyan-500 hover:bg-cyan-300',
};

const getButtonClasses = (open: boolean, active?: boolean) => {
  if (open) return classes.open;
  if (active) return classes.active;

  return '';
};

export default getButtonClasses;
