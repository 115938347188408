import useSnorkelRouter from '@hooks/useSnorkelRouter';

const useCurrentPath = () => {
  const { asPath = '' } = useSnorkelRouter();

  const currentPathWithoutQueries = asPath.split('?')[0];

  return currentPathWithoutQueries;
};

export default useCurrentPath;
