import type { PropsWithChildren } from 'react';
import React from 'react';

import Menu from '@components/Menu';

type SidebarMenuProps = PropsWithChildren<{}>;

const SidebarMenu = ({ children }: SidebarMenuProps) => {
  return <Menu width={250}>{children}</Menu>;
};

export default SidebarMenu;
