export enum InputSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

const AllInputVariants = ['default', 'filled'] as const;
export type InputVariant = (typeof AllInputVariants)[number];

export default null;
