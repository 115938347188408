import { useSetAtom } from 'jotai/react';

import type { WorkspaceMap } from '@app/AdminPage/AdminSettings/types';
import useCurrentUser from '@global/AuthContextProvider/hooks/useCurrentUser';
import useGlobalPreferences from '@global/PrivateRouteProviders/UserSettingsProvider/hooks/useGlobalPreferences';
import {
  adminWorkspacesAtom,
  selectedWorkspaceAtom,
  workspacesAtom,
} from '@global/store/workspaces/workspacesAtomStore';
import useAuthContext from '@hooks/useAuthContext';
import type { Workspace } from '@snorkel/api/lib';

const useSetWorkspaces = () => {
  const { savedWorkspace, updateSavedWorkspace } = useGlobalPreferences();
  const setWorkspaces = useSetAtom(workspacesAtom);
  const setAdminWorkspaces = useSetAtom(adminWorkspacesAtom);
  const setSelectedWorkspace = useSetAtom(selectedWorkspaceAtom);
  const { getUser } = useCurrentUser();
  const { setUser } = useAuthContext();

  const updateWorkspaces = (
    workspaces: Workspace[],
    adminWorkspaces: Workspace[],
  ) => {
    const workspaceMap = workspaces.reduce(
      (map: WorkspaceMap, workspace: Workspace) => {
        const { workspace_uid } = workspace;

        return { ...map, [workspace_uid!]: workspace };
      },
      {},
    );

    if (savedWorkspace) {
      setWorkspaces(workspaces);
      setAdminWorkspaces(adminWorkspaces);
      setSelectedWorkspace(workspaceMap[savedWorkspace]);
    }
  };

  const updateSelectedWorkspace = async (workspace: Workspace) => {
    setSelectedWorkspace(workspace);
    updateSavedWorkspace(workspace.workspace_uid!);

    const currentUser = await getUser(workspace.workspace_uid);

    if (currentUser) {
      setUser(currentUser);
    }
  };

  return {
    updateSelectedWorkspace,
    updateWorkspaces,
  };
};

export default useSetWorkspaces;
