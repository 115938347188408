import { FONT_SANS } from '@core/constants';

export default {
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 48,
    padding: '0px 15px',
  },
  head: {
    zIndex: 2,
    lineHeight: '1rem',
    backgroundColor: '#DCDCDC',
    fontFamily: FONT_SANS,
    '&:hover': {
      backgroundColor: '#CACACA',
    },
  },
  stickyHeader: {
    backgroundColor: '#DCDCDC',
    '&:hover': {
      backgroundColor: '#CACACA',
    },
  },
  body: {
    color: '#6F6F6F',
  },
};
