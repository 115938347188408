import type { PropsWithChildren } from 'react';

import AlertContext from './AlertContext';
import useAlertDispatchers from './useAlertDispatchers';
import useAlertTrigger from './useAlertTrigger';

import type { PageRegionTag } from '../types';

type AlertProviderProps = PropsWithChildren<{
  tag: PageRegionTag;
}>;

const AlertProvider = ({ tag, children }: AlertProviderProps) => {
  const { errorAlertData, setErrorAlertData, resetError } = useAlertTrigger();
  const dispatchers = useAlertDispatchers(tag, setErrorAlertData);

  return (
    <AlertContext.Dispatchers.Provider value={dispatchers}>
      <AlertContext.Trigger.Provider value={{ errorAlertData, resetError }}>
        {children}
      </AlertContext.Trigger.Provider>
    </AlertContext.Dispatchers.Provider>
  );
};

export default AlertProvider;
