import { WorkspaceTypes } from '@app/StudioPage/types';
import type {
  CommentTagAssets,
  CommitGTAssets,
  CompleteBatchAnnotationAssets,
  CompleteLongRunningLFAssets,
} from '@snorkel/api/lib';
import { NotificationType } from '@snorkel/api/lib';

export type NotificationAssetUri =
  | CommentTagAssets
  | CommitGTAssets
  | CompleteBatchAnnotationAssets
  | CompleteLongRunningLFAssets;

type GetNotificationRedirectPropsArgs = {
  notificationType: NotificationType;
  assetUri: NotificationAssetUri;
};

const getNotificationRedirectProps = ({
  notificationType,
  assetUri,
}: GetNotificationRedirectPropsArgs) => {
  switch (notificationType) {
    case NotificationType.CompleteBatchAnnotation:
      return {
        url: `/applications/${assetUri.application_uid}/nodes/${assetUri.node_uid}/batch`,
        workspace: WorkspaceTypes.ANNOTATION,
      };
    case NotificationType.CommitGt:
      return {
        url: `/applications/${assetUri.application_uid}/nodes/${assetUri.node_uid}/overview/ground-truth-versions`,
        workspace: WorkspaceTypes.ANNOTATION,
      };
    case NotificationType.CompleteLongRunningLf:
      return {
        url: `/applications/${assetUri.application_uid}/nodes/${assetUri.node_uid}/studio`,
        workspace: WorkspaceTypes.DEVELOPMENT,
      };
    default:
      return { url: ``, workspace: WorkspaceTypes.DEVELOPMENT };
  }
};

export default getNotificationRedirectProps;
