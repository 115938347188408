import type { PropsWithChildren } from 'react';
import { useReducer } from 'react';

import reducer, { initialState } from '@global/store/root/data/reducer';

import DataContext from './DataContext';

type DataContextProviderProps = PropsWithChildren<{}>;

const DataContextProvider = ({ children }: DataContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DataContext.Dispatch.Provider value={dispatch}>
      <DataContext.State.Provider value={state}>
        {children}
      </DataContext.State.Provider>
    </DataContext.Dispatch.Provider>
  );
};

export default DataContextProvider;
