import { useMemo } from 'react';

import useNodeDetails from '@app/Node/NodeDetailsProvider/hooks/useNodeDetails';
import { useTaskStateContext } from '@hooks/useRootContext/useTaskStateContext';

const useNodeId = (): number => {
  // TODO: ENG-20683
  const { name: nodeId } = useTaskStateContext();
  const {
    state: {
      node: { id },
    },
  } = useNodeDetails();

  return useMemo(() => id || nodeId, [id, nodeId]);
};

export default useNodeId;
