import type { PropsWithChildren } from 'react';
import { useMemo, useReducer } from 'react';

import NotificationsContainer from './NotificationsContainer';

import NotificationsContext from '../state/context';
import calcDispatchers from '../state/dispatchers';
import reducer, { initialState } from '../state/reducer';

type NotificationsProviderProps = PropsWithChildren<{}>;

const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchers = useMemo(() => calcDispatchers(dispatch), [dispatch]);

  return (
    <NotificationsContext.State.Provider value={state}>
      <NotificationsContext.Dispatchers.Provider value={dispatchers}>
        {children}
        <NotificationsContainer />
      </NotificationsContext.Dispatchers.Provider>
    </NotificationsContext.State.Provider>
  );
};

export default NotificationsProvider;
