import { useAtom } from 'jotai/react';

import { PageRegion } from '@components/PageRegion';
import JobsView from '@global/providers/NavigationProvider/NavigationSidebarNav/JobsDialog/JobsView';
import { isJobsModalOpenAtom } from '@global/store/sidebarNav/jobsModalAtom';
import Modal from '@snorkel/coral/components/Modal';
import DialogContent from '@snorkel/coral/components/Modal/DialogContent';
import DialogHeader from '@snorkel/coral/components/Modal/DialogHeader';

const JobsDialog = () => {
  const [isJobsModalOpen, setIsJobsModalOpen] = useAtom(isJobsModalOpenAtom);

  const handleJobModalClose = () => setIsJobsModalOpen(false);

  return (
    <>
      <Modal
        height={700}
        width={950}
        open={isJobsModalOpen}
        onClose={handleJobModalClose}
      >
        <DialogHeader onClose={handleJobModalClose}>
          <span className="text-lg">Jobs Dashboard</span>
        </DialogHeader>
        <DialogContent className="h-full">
          <PageRegion tag="in-app-jobs/dashboard">
            <JobsView isOpen={isJobsModalOpen} />
          </PageRegion>
        </DialogContent>
      </Modal>
    </>
  );
};

export default JobsDialog;
