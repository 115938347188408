import React from 'react';

import {
  urlZendeskUserGuide,
  USER_GUIDE_PYTHON_SDK_URL,
} from '@core/constants';
import SidebarMenu from '@global/providers/NavigationProvider/NavigationSidebarFooter/common/SidebarMenu';
import SidebarMenuItem from '@global/providers/NavigationProvider/NavigationSidebarFooter/common/SidebarMenuItem';

const urlPythonSDK = USER_GUIDE_PYTHON_SDK_URL;

const DocumentationMenu = () => {
  return (
    <SidebarMenu>
      <SidebarMenuItem href={urlZendeskUserGuide} openInNewTab>
        User Guide
      </SidebarMenuItem>
      <SidebarMenuItem href={urlPythonSDK} openInNewTab>
        Python SDK
      </SidebarMenuItem>
    </SidebarMenu>
  );
};

export default DocumentationMenu;
