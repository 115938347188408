import { useEffect } from 'react';

import * as taskActionTypes from '@global/store/root/task/actionTypes';
import useAuthContext from '@hooks/useAuthContext';
import useCachedIsTrial from '@hooks/useCachedIsTrial';
import useRequest from '@hooks/useRequestWithLogging';
import { useTaskDispatchContext } from '@hooks/useRootContext/useTaskDispatchContext';
import { useTaskStateContext } from '@hooks/useRootContext/useTaskStateContext';
import { notebooksApi } from '@utils/api/serverRequests';

type UseInitializeNotebookProps = Readonly<{
  onStart: () => void;
  onComplete: () => void;
}>;

const defaultProps: UseInitializeNotebookProps = {
  onStart: () => {},
  onComplete: () => {},
};

const useInitializeNotebook = ({
  onStart,
  onComplete,
}: UseInitializeNotebookProps = defaultProps) => {
  const { userDir: existingUserDir, jupyter: existingJupyter } =
    useTaskStateContext();
  const dispatch = useTaskDispatchContext();
  const request = useRequest();
  const user = useAuthContext();
  const isTrial = useCachedIsTrial();

  const triggerTaskUpdates = async () => {
    const notebookUrl =
      notebooksApi.getSingleruserUserNotebookSingleuserNotebookUserUserUidGet;
    const notebookResults = await request(
      notebookUrl,
      {},
      user.user_uid,
      user.username,
    );

    if (!notebookResults) return onComplete();

    const notebookURL = `${window.location.origin}${notebookResults.path}`;
    dispatch({
      type: taskActionTypes.updateTask,
      data: { jupyter: notebookURL },
    });

    const notebookDirUrl =
      notebooksApi.getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet;
    const userDirResults = await request(
      notebookDirUrl,
      {},
      user.user_uid,
      user.username,
    );

    if (!userDirResults) return onComplete();

    const userDirURL = `${window.location.origin}${userDirResults.path}`;
    dispatch({
      type: taskActionTypes.updateTask,
      data: { userDir: userDirURL },
    });

    return onComplete();
  };

  useEffect(() => {
    if (isTrial !== false) return onComplete();

    if (!user.user_uid || !user.username) return onComplete();

    if (existingUserDir && existingJupyter) return onComplete();

    triggerTaskUpdates();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user), isTrial]);

  onStart();

  return { user };
};

export default useInitializeNotebook;
