import { atom } from 'jotai/vanilla';

import { DEFAULT_ZOOM } from '@snorkel/coral/prebuilts/ZoomControl/constants';

export const pdfDataLoadingAtom = atom<boolean>(false);

export const pdfDataAtom = atom<Uint8Array | undefined>(undefined);

export const pdfZoomAtom = atom<number>(DEFAULT_ZOOM);

export default null;
