const saveAsFile = (
  body: string | Blob,
  filename: string,
  isBlob?: boolean,
) => {
  const blob = isBlob ? body : new Blob([body]);

  const a = document.createElement('a');
  document.body.appendChild(a);

  const url = window.URL.createObjectURL(blob as Blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

export default saveAsFile;
