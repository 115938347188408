import { TextSize, Variant } from '../types';

const getTextSpacing = (variant: Variant, size: TextSize) => {
  if (variant === Variant.headline) {
    switch (size) {
      case TextSize.large:
        return 'tracking-narrow1';
      case TextSize.medium:
        return 'tracking-narrow2';
      case TextSize.small:
        return 'tracking-normal';
      default:
        throw Error('not a valid size for Heading');
    }
  }

  if (variant === Variant.subtitle) {
    return 'tracking-wide';
  }

  return 'tracking-normal';
};

export default getTextSpacing;
