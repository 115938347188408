import type { Nominal } from '@snorkel/coral/types/nominal';

export type TaskActionType = Nominal<string, 'TaskAction'>;

export const setTask = 'task/set-task' as TaskActionType;
export const setSummary = 'task/set-summary' as TaskActionType;
export const setTool = 'task/set-tool' as TaskActionType;
export const updateTask = 'task/update-task' as TaskActionType;
export const changeSplit = 'task/change-split' as TaskActionType;
export const setShortcutsOpen = 'task/set-shortcuts-open' as TaskActionType;
export const setProcessingDataSources =
  'task/set-processing-data-sources' as TaskActionType;
export const setSettingsLoading = 'task/set-settings-loading' as TaskActionType;
export const setSettings = 'task/set-settings' as TaskActionType;
export const setDataInitialized = 'task/set-data-initialized' as TaskActionType;
export const resetSummary = 'task/reset-summary' as TaskActionType;
export const setLFsInfo = 'task/set-lfs-info' as TaskActionType;
export const startLoadingTaskDatasources =
  'task/start-loading-task-datasources' as TaskActionType;
export const setActiveTaskDatasources =
  'task/set-active-task-datasources' as TaskActionType;
export const setTaskDatasources = 'task/set-task-datasources' as TaskActionType;
export const setUsers = 'task/set-users' as TaskActionType;
export const setTaskConfig = 'task/set-task-config' as TaskActionType;
