import { CircularProgress } from '@material-ui/core';
import type { HTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';

import SplitButton from './SplitButton';
import type { Color, Variant } from './types';
import getClasses, { baseClasses, getSizeClasses } from './utils/getClasses';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  color?: Color;
  variant?: Variant;
  children: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fontSize?: 'small' | 'large';
  additionalOptions?: Array<{
    children: ReactNode;
    onClick: Function;
    dataCy?: string;
  }>;
  autoFocus?: boolean;
  dropdownDataCy?: string;
  className?: string;
  size?: 'large' | 'small';
  dropdownPosition?: 'top' | 'bottom';
  disabled?: boolean;
  active?: boolean;
  loading?: boolean;
  dataCy?: string;
  type?: 'submit' | 'reset' | 'button';
}

/** @deprecated Use Coral Button instead. */
const DeprecatedButton = forwardRef((props: ButtonProps, ref: any) => {
  const {
    color = 'default',
    variant = 'default',
    dropdownPosition = 'top',
    dataCy = '',
    children,
    disabled,
    active,
    additionalOptions,
    className,
    startIcon,
    endIcon,
    fontSize = 'large',
    size = 'large',
    loading,
    ...otherProps
  } = props;

  const propsWithDefaults = {
    ...props,
    color,
    variant,
    dropdownPosition,
  };

  const fontClass = fontSize === 'large' ? 'text-sm' : 'text-xs';

  if (!additionalOptions) {
    return (
      <button
        type="button"
        disabled={disabled}
        className={`relative inline-flex items-center justify-center rounded ${fontClass} ${baseClasses} ${getClasses(
          color,
          variant,
          disabled ?? false,
          active ?? false,
        )} ${getSizeClasses(size)} ${className}`}
        data-cy={dataCy}
        ref={ref}
        {...otherProps}
      >
        {startIcon && <div className="mr-1 -ml-1 inline">{startIcon}</div>}
        {children}
        {endIcon && <div className="ml-1 -mr-1 inline">{endIcon}</div>}
        {loading && (
          <CircularProgress color="inherit" className="ml-2" size="1rem" />
        )}
      </button>
    );
  }

  return <SplitButton {...propsWithDefaults}>{children}</SplitButton>;
});

export default DeprecatedButton;
