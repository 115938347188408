/* eslint-disable react/no-multi-comp */
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import type { PropsWithChildren } from 'react';
import React, { memo, useEffect } from 'react';
import { configure } from 'react-hotkeys';

import { PageRegion, tagPageRegionRoot } from '@components/PageRegion';
import { SETTINGS_VERSION } from '@core/constants';
import { clearStorage, getItem, setItem } from '@utils/localStorage';

import ContextInspector from './ContextInspector';
import DataContextProvider from './DataContextProvider';
import EnvVarInspector from './EnvVarInspector';
import JotaiAtomsInspector from './JotaiAtomsInspector';
import LFsContextProvider from './LFsContextProvider';
import PageContainer from './PageContainer';
import TaskContextProvider from './TaskContextProvider';

const ignoreTags = { input: true, select: true, textarea: true, li: true };
configure({
  // this is added so that react-hotkeys will not listen to events
  // when user is doing actions through cmd+<key>
  // ticket: https://app.shortcut.com/snorkel-ai/story/21021/user-has-gt-unexpectedly-changed-for-them-when-trying-to-use-keyboard-shortcuts-for-changing-browser-zoom
  ignoreEventsCondition: (keyEvent: KeyboardEvent) => {
    if (keyEvent.metaKey) return true;
    const target = keyEvent.target as HTMLElement;

    if (target && target.tagName) {
      const tagName = target.tagName.toLowerCase();

      return ignoreTags[tagName] || target.isContentEditable;
    }

    return false;
  },
});

type RootAppProps = PropsWithChildren<{}>;

const Contents = memo(({ children }: RootAppProps) => {
  return <>{children}</>;
});

const RootApp = ({ children }: RootAppProps) => {
  useEffect(() => {
    const snorkelSettingsVersion = getItem(
      'settings-version',
      SETTINGS_VERSION,
    );

    if (snorkelSettingsVersion !== SETTINGS_VERSION) {
      clearStorage();
    }

    setItem('settings-version', SETTINGS_VERSION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SETTINGS_VERSION]);

  return (
    <PageRegion tag={tagPageRegionRoot}>
      <DataContextProvider>
        <LFsContextProvider>
          <TaskContextProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <PageContainer>
                <Contents>{children}</Contents>
              </PageContainer>
            </MuiPickersUtilsProvider>
            <ContextInspector />
            <JotaiAtomsInspector />
            <EnvVarInspector />
          </TaskContextProvider>
        </LFsContextProvider>
      </DataContextProvider>
    </PageRegion>
  );
};

export default RootApp;
