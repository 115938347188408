import { useAtomValue } from 'jotai/react';
import { useEffect } from 'react';

import { selectedLabelSortTypeAtom } from '@global/store/labels/labelsAtomStore';
import selectedLFAtom from '@global/store/root/selectedLFAtom';

const JotaiAtomsInspector = () => {
  const selectedLabelSortType = useAtomValue(selectedLabelSortTypeAtom);
  const selectedLFUid = useAtomValue(selectedLFAtom);

  useEffect(() => {
    (window as any).sf = (window as any).sf ?? {};
    (window as any).sf.getJotaiAtoms = () => ({
      selectedLabelSortType,
      selectedLFUid,
    });

    return () => {
      (window as any).sf.getJotaiAtoms = undefined;
    };
  }, [selectedLabelSortType, selectedLFUid]);

  return null;
};

export default JotaiAtomsInspector;
