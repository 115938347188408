import { useMemo } from 'react';

import useAuthContext from '@hooks/useAuthContext';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import type { UpdateUserSettingsRequest } from '@snorkel/api/lib';
import { validateUid } from '@utils/validateRequestParams';

export type UserSettingsRequestParams = Omit<
  UpdateUserSettingsRequest,
  'settings'
>;

export type RequestParams = UserSettingsRequestParams & {
  batch_uid?: number;
};

export const defaultRequestParams: RequestParams = {
  organization: undefined,
  application_uid: undefined,
  node_uid: undefined,
  user_uid: undefined,
  batch_uid: undefined,
};

const useGetRequestParams = () => {
  const { user_uid: userUid } = useAuthContext();
  // Use appId and nodeId from query params to minimize rerenders from root context updates.
  const {
    query: { appId = undefined, nodeId = undefined, batchUid = undefined } = {},
  } = useSnorkelRouter() || {};

  const requestParams = useMemo(
    () => ({
      ...defaultRequestParams,
      application_uid: validateUid(appId),
      node_uid: validateUid(nodeId),
      user_uid: validateUid(userUid),
      batch_uid: validateUid(batchUid),
    }),
    [appId, batchUid, nodeId, userUid],
  );

  return requestParams;
};

export default useGetRequestParams;
