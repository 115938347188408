import type { MouseEvent } from 'react';
import { createElement } from 'react';
import type { TextColor } from './types';
import combineClasses from '../../utils/combineClasses';

export enum TextSize {
  xs = 'text-xsmall',
  s = 'text-small',
  md = 'text-medium',
  l = 'text-large',
  xl = 'text-xlarge',
  '2xl' = 'text-xxlarge',
}

export enum TextWeight {
  normal = 'font-normal',
  medium = 'font-medium',
  semibold = 'font-semibold',
  bold = 'font-bold',
}

export enum TextVariant {
  title = 'title',
  subtitle = 'subtitle',
  body = 'body',
  link = 'link',
  mono = 'mono',
}

type Title = {
  variant: TextVariant.title;
  size: TextSize.l | TextSize.xl;
  weight: TextWeight.normal | TextWeight.semibold;
};

type LargeSubtitle = { size: TextSize.l; weight: TextWeight.medium };
type MediumSubtitle = { size: TextSize.md; weight: TextWeight.semibold };
type SmallSubtitle = {
  size: TextSize.s | TextSize.xs;
  weight: TextWeight;
};

type Subtitle = {
  variant: TextVariant.subtitle;
  color?: TextColor;
} & (LargeSubtitle | MediumSubtitle | SmallSubtitle);

type Body = {
  variant?: TextVariant.body;
  size?: TextSize.s | TextSize.md | TextSize.l | TextSize.xl;
  weight?: TextWeight.normal | TextWeight.medium;
  color?: TextColor;
};

type XSmallLink = {
  size: TextSize.xs;
  weight: Exclude<TextWeight, TextWeight.bold>;
};
type OtherLink = {
  size: TextSize.s | TextSize.md | TextSize.l;
  weight: TextWeight.normal;
};

type Link = {
  variant: TextVariant.link;
} & (XSmallLink | OtherLink);

type XLargeMono = { size: TextSize.xl; weight: TextWeight.normal };
type MediumMono = {
  size: TextSize.md;
  weight: TextWeight.normal | TextWeight.bold;
};
type SmallMono = { size: TextSize.s; weight: TextWeight.normal };

type Mono = {
  variant: TextVariant.mono;
} & (XLargeMono | MediumMono | SmallMono);

export type TextProps = {
  children: string;
  truncate?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  'data-cy'?: string;
  'aria-label'?: string;
  className?: string;
} & (Title | Subtitle | Body | Link | Mono);

const getElement = (variant: TextVariant) => {
  if (variant === TextVariant.title) return 'h1';
  if (variant === TextVariant.subtitle) return 'h2';

  return 'span';
};

const Text = ({
  children,
  weight = TextWeight.normal,
  size = TextSize.md,
  truncate,
  className,
  ...props
}: TextProps) => {
  const { variant = TextVariant.body } = props;

  const classes = combineClasses(
    size,
    weight,
    props.variant === TextVariant.body && props.color ? props.color : '',
    size === TextSize.xl && variant === TextVariant.mono ? 'tracking-mono' : '',
    truncate ? 'truncate' : '',
    className,
  );

  return createElement(
    getElement(variant),
    {
      className: classes,
      ...props,
    },
    children,
  );
};

export default Text;
