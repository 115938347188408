import type { CSSProperties, Ref } from 'react';
import React, { forwardRef } from 'react';
import type { TooltipProps } from '../tooltips/Tooltip';
import Tooltip from '../tooltips/Tooltip';
import Icon, { Icons } from '../Icon';
import { InputSizes } from './types';

export type InputContainerDetailsProps = {
  size?: InputSizes;
  fullWidth?: boolean;
  label?: React.ReactNode;
  infoText?: string;
  error?: string | boolean;
  warning?: string | boolean;
  loading?: boolean;
  disabled?: boolean;
  id?: string;
  tooltipTitle?: TooltipProps['title'];
  linkToDocumentation?: boolean;
  href?: string;
  isInlineBlock?: boolean;
};

interface InputContainerProps extends InputContainerDetailsProps {
  children: React.ReactNode;
}

const InputContainer = (
  {
    children,
    label,
    size = InputSizes.large,
    fullWidth,
    infoText,
    loading,
    error,
    warning,
    tooltipTitle,
    linkToDocumentation,
    href,
    isInlineBlock = true,
    ...props
  }: InputContainerProps,
  ref: Ref<HTMLLabelElement>,
) => {
  const fontSizeClasses = size === InputSizes.large ? 'text-sm' : 'text-xs';
  const widthClasses = fullWidth ? 'w-full' : '';
  const { id, disabled } = props;

  const disabledClasses = disabled ? 'opacity-70 cursor-not-allowed' : '';
  const warningIconStyle: CSSProperties = {
    filter:
      'invert(15%) sepia(66%) saturate(7397%) hue-rotate(60deg) brightness(101%) contrast(101%)',
  };
  const errorIconStyle: CSSProperties = {
    filter:
      'invert(13%) sepia(82%) saturate(5131%) hue-rotate(348deg) brightness(61%) contrast(92%)',
  };

  return (
    <label
      ref={ref}
      className={`${
        isInlineBlock ? 'inline-block' : ''
      } ${widthClasses} ${disabledClasses}`}
      htmlFor={id}
      id={`${id}-container`}
    >
      {label && (
        <div
          className={`mb-2 flex justify-between text-black ${fontSizeClasses}`}
        >
          <span className="flex items-center">
            {label}
            {tooltipTitle && (
              <Tooltip title={tooltipTitle}>
                {linkToDocumentation ? (
                  <button
                    type="button"
                    className="ml-2"
                    data-cy="link-to-documentation-button"
                  >
                    <a href={href} target="_blank" rel="noopener noreferrer">
                      <Icon name={Icons.LAUNCH} />
                    </a>
                  </button>
                ) : (
                  <button type="button" className="ml-2">
                    <Icon name={Icons.INFORMATION__NORMAL} />
                  </button>
                )}
              </Tooltip>
            )}
          </span>
          {loading && (
            <div className="flex items-center overflow-hidden">
              <span className="mr-2">Loading...</span>
            </div>
          )}
        </div>
      )}
      {children}
      {infoText && !error && (
        <div className={`mt-2 text-gray-600 ${fontSizeClasses}`}>
          {infoText}
        </div>
      )}
      {warning && typeof warning === 'string' && (
        <div className="mt-2 flex items-center gap-1">
          <Icon
            name={Icons.WARNING_SQUARE} // yellow-700
            style={warningIconStyle}
          />
          <div
            data-cy="input-warning"
            className={`text-yellow-700 ${fontSizeClasses}`}
          >
            {warning}
          </div>
        </div>
      )}
      {error && typeof error === 'string' && (
        <div className="mt-2 flex items-center gap-1">
          <Icon
            name={Icons.SUBTRACT_CIRCLE_FAILURE} // red-700
            style={errorIconStyle}
          />
          <div
            data-cy="input-error"
            className={`text-red-700 ${fontSizeClasses}`}
          >
            {error}
          </div>
        </div>
      )}
    </label>
  );
};

export default forwardRef(InputContainer);
