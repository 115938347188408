import type { NextPageContext } from 'next';

import { PublicRoutes } from '@core/constants';
import getIsServerRendered from '@utils/getIsServerRendered';
import { normalizeUrl } from '@utils/normalizeUrl';
import setWindowLocationHref from '@utils/setWindowLocationHref';

type RedirectOptions = {
  noReturnUrl?: boolean;
};

const redirect = (
  redirectUrl: string,
  ctx?: NextPageContext,
  options: RedirectOptions = {},
) => {
  const nextRedirectUrl = normalizeUrl(redirectUrl);

  if (ctx?.pathname === redirectUrl) {
    return;
  }

  if (!getIsServerRendered() || !ctx || !ctx.req || !ctx.res) {
    setWindowLocationHref(nextRedirectUrl);

    return;
  }

  const { res, req } = ctx;

  const nextUrl =
    !options.noReturnUrl &&
    req.url &&
    req.url !== '/' &&
    !(Object.values(PublicRoutes) as string[]).includes(req.url)
      ? `?nextURL=${encodeURIComponent(req.url || '')}`
      : '';

  const destination = `${nextRedirectUrl}${nextUrl}`;
  res.writeHead(302, { Location: destination });
  res.end();
};

export default redirect;
