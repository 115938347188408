import type { Dispatch, SetStateAction, MouseEvent } from 'react';
import { useRef, useState } from 'react';
import useOutside from '../../../hooks/useOutside';
import type { DropdownOptionType, ToggleDropdown } from '../types';

export type OpenDropdown = () => void;
export type CloseDropdown = (simple?: boolean) => void;

type UseDropdownVisibilityProps<ValueType> = {
  setShouldFilter: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  searchValue: string;
  combobox?: boolean;
  searchable: boolean;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setValuesByIndex: (valueIndex: number) => void;
  currentTitle: string;
  options: DropdownOptionType<ValueType>[];
  fallbackIndex: number;
  multiSelect: boolean;
  autoFocus?: boolean;
};

const useDropdownVisibility = <ValueType>(
  props: UseDropdownVisibilityProps<ValueType>,
) => {
  const {
    disabled,
    combobox,
    searchValue,
    currentTitle,
    options,
    searchable,
    setSearchValue,
    setValuesByIndex,
    fallbackIndex,
    multiSelect,
    autoFocus = false,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const [isDropdownVisible, setDropdownVisibility] =
    useState<boolean>(autoFocus);

  const openDropdown: OpenDropdown = () => {
    setDropdownVisibility(true);
  };

  const hideDropdown = () => {
    setDropdownVisibility(false);
  };

  const closeDropdown: CloseDropdown = (simple: boolean = false) => {
    if (simple) {
      hideDropdown();

      return;
    }

    if (!searchValue && currentTitle && !multiSelect) {
      setSearchValue(currentTitle);
    }

    if (combobox) {
      // TODO: fix
      if (
        searchValue &&
        currentTitle &&
        !options.some(({ title }) => title === searchValue)
      ) {
        setValuesByIndex(fallbackIndex);
      }
    } else if (searchable) {
      setSearchValue('');
    }

    hideDropdown();
  };

  const toggleDropdown: ToggleDropdown = (
    e: MouseEvent<HTMLButtonElement | HTMLInputElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.nativeEvent.stopPropagation) {
      e.nativeEvent.stopPropagation();
    }

    if (disabled) {
      return;
    }

    if (!isDropdownVisible) {
      openDropdown();

      return;
    }

    closeDropdown();
  };

  useOutside([ref, childRef], closeDropdown);

  return {
    ref,
    childRef,
    toggleDropdown,
    openDropdown,
    closeDropdown,
    isDropdownVisible,
  };
};

export default useDropdownVisibility;
