import type { PropsWithChildren } from 'react';

import combineClasses from '@utils/combineClasses';

import type { ErrorAlertData } from './Alert/types';
import PageRegionError from './PageRegionError';
import type { PageRegionTag } from './types';

export type PageRegionContentProps = PropsWithChildren<{
  tag: PageRegionTag;
  errorAlertData: ErrorAlertData | null;
  resetError: () => void;
  isUncaughtError?: boolean;
  wrapperClasses?: string;
  isWrappingPopUp?: boolean;
}>;

const PageRegionContent = ({
  tag,
  errorAlertData,
  resetError,
  children,
  isUncaughtError,
  wrapperClasses = '',
  isWrappingPopUp,
}: PageRegionContentProps) => {
  const className = combineClasses(
    'w-full',
    errorAlertData ? 'relative' : undefined,
    wrapperClasses,
  );

  return (
    <div data-cy={`page-region-${tag}`} className={className}>
      {isUncaughtError ? null : children}
      {errorAlertData ? (
        <PageRegionError
          tag={tag}
          errorAlertData={errorAlertData}
          resetError={resetError}
          isUncaughtError={isUncaughtError}
          isWrappingPopUp={isWrappingPopUp}
        />
      ) : null}
    </div>
  );
};

export default PageRegionContent;
