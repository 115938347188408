import { useContext } from 'react';

import AlertContext from './AlertContext';
import type { AlertDispatchers, TriggerContext } from './types';

export const useAlertDispatchersContext = (): AlertDispatchers =>
  useContext(AlertContext.Dispatchers);
export const useAlertTriggerContext = (): TriggerContext =>
  useContext(AlertContext.Trigger);

export default null;
