import { TextSize, Variant } from '../types';

const getTextSize = (variant: Variant, size: TextSize) => {
  switch (variant) {
    case Variant.heading:
      switch (size) {
        case TextSize.xsmall:
          return 'text-sm';
        case TextSize.small:
          return 'text-base';
        case TextSize.main:
          return 'text-xl';
        case TextSize.large:
          return 'text-xxl';
        default:
          return 'text-xl';
      }
    case Variant.mono: // falls through
      switch (size) {
        case TextSize.large:
          return 'text-xxl';
        case TextSize.main:
          return 'text-base';
        case TextSize.small:
          return 'text-xs';
        case TextSize.xsmall:
          return 'text-xxs';
        default:
          return 'text-base';
      }
    case Variant.body: // falls through

    default:
      switch (size) {
        case TextSize.xsmall:
          return 'text-xxs';
        case TextSize.small:
          return 'text-xs';
        case TextSize.main:
          return 'text-sm';
        case TextSize.large:
          return 'text-base';
        default:
          return 'text-sm';
      }
  }
};

export default getTextSize;
