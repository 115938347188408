import { LinearProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useTaskStateContext } from '@hooks/useRootContext/useTaskStateContext';

type NotebookIframeProps = {
  url?: string;
  iframeKey?: string;
};

const NotebookContainer = styled.iframe`
  height: calc(100% + 44px);
  margin-top: -44px;
`;

const NotebookIframe = ({ url, iframeKey }: NotebookIframeProps) => {
  const [loading, setLoading] = useState<boolean>(true);

  const { jupyter } = useTaskStateContext();

  useEffect(() => {
    setLoading(true);
  }, [jupyter, url]);

  return (
    <>
      {(loading || (jupyter === undefined && url === undefined)) && (
        <LinearProgress className="w-full flex-none" />
      )}
      <NotebookContainer
        onLoad={() => setLoading(false)}
        title="notebook"
        key={iframeKey}
        className="w-full border-none"
        src={url || jupyter}
      />
    </>
  );
};

export default NotebookIframe;
