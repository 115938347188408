import type { WorkspaceTypes } from '@app/StudioPage/types';
import { updateWorkspaceTypeStateAction } from '@global/PrivateRouteProviders/UserSettingsProvider/actions';
import useUserSettingsContext from '@global/PrivateRouteProviders/UserSettingsProvider/hooks/useUserSettingsContext';

type UseUserWorkspaceTypeReturnType = {
  workspaceType: WorkspaceTypes;
  isFetching: boolean;
  updateWorkspaceType: (workspaceType: WorkspaceTypes) => Promise<void>;
};

const useUserWorkspaceType = (): UseUserWorkspaceTypeReturnType => {
  const {
    userSettings: { workspace_type: workspaceType } = {},
    isFetching,
    setUserSettingsState,
    updateUserSettings,
  } = useUserSettingsContext();

  const updateWorkspaceType = async (nextWorkspaceType: WorkspaceTypes) => {
    if (workspaceType === nextWorkspaceType) {
      return;
    }

    setUserSettingsState(updateWorkspaceTypeStateAction(nextWorkspaceType));

    await updateUserSettings({
      workspace_type: nextWorkspaceType,
    });
  };

  return {
    workspaceType: workspaceType as WorkspaceTypes,
    isFetching,
    updateWorkspaceType,
  };
};

export default useUserWorkspaceType;
