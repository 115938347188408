import type { ReactNode } from 'react';
import { useReducer } from 'react';

import JobProgressNotification from '@components/JobProgressNotification';

import { removeJobProgressBar } from './actions';
import JobProgressNotificationsContext, {
  jobProgressNotificationsInitialState,
} from './context';
import jobProgressNotificationsReducer from './reducer';

type JobProgressNotificationsProviderProps = {
  children: ReactNode;
};

/**
 * Provides a callback to launch a progress bar for an engine job.
 *
 * This provider handles all the work of displaying, updating, and closing
 * the progress bar notification; users of this provider can simply call
 * `launchProgressBar` and then forget about the job.
 */
const JobProgressNotificationsProvider = ({
  children,
}: JobProgressNotificationsProviderProps) => {
  const [jobsInProgress, dispatch] = useReducer(
    jobProgressNotificationsReducer,
    jobProgressNotificationsInitialState,
  );

  return (
    <JobProgressNotificationsContext.Provider
      value={{ jobsInProgress, dispatch }}
    >
      {children}
      {jobsInProgress.map(({ jobUid, onCompleteMessage, runningJob }) => (
        <JobProgressNotification
          uuid={jobUid}
          onComplete={() => {
            dispatch(removeJobProgressBar(jobUid));
          }}
          onCompleteMessage={onCompleteMessage}
          key={jobUid}
          job={runningJob}
        />
      ))}
    </JobProgressNotificationsContext.Provider>
  );
};

export default JobProgressNotificationsProvider;
