import React from 'react';

// eslint-disable-next-line import/no-cycle
import Modal from './index';
import DialogHeader from './DialogHeader';
import DialogContent from './DialogContent';
import DeprecatedText, { TextSize, Variant } from '../DeprecatedText';
import DialogFooter from './DialogFooter';
import Button, { ButtonThemes } from '../Button';

type ConfirmCloseModalProps = {
  onConfirm: VoidFunction;
  onClose: VoidFunction;
  text?: string;
};

const ConfirmCloseModal = (props: ConfirmCloseModalProps) => {
  const { onClose, onConfirm, text } = props;

  return (
    <div className="z-dialog ">
      <Modal onClose={onClose} width={300}>
        <DialogHeader onClose={onClose} id="form-dialog-title">
          Confirm close
        </DialogHeader>
        <DialogContent>
          <DeprecatedText size={TextSize.main} variant={Variant.body}>
            {text}
          </DeprecatedText>
        </DialogContent>
        <DialogFooter>
          <div className="flex gap-3">
            <Button onClick={onClose} filled={false}>
              Nevermind
            </Button>
            <Button
              data-cy="confirm-close"
              theme={ButtonThemes.primary}
              onClick={onConfirm}
            >
              Confirm and Close
            </Button>
          </div>
        </DialogFooter>
      </Modal>
    </div>
  );
};

export default ConfirmCloseModal;
