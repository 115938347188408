import type { ReactNode } from 'react';
import HStack, {
  HStackAlignment,
  HStackBottomPadding,
  HStackHeight,
  HStackLeftPadding,
  HStackPosition,
  HStackSpacing,
  HStackTopPadding,
  HStackWidth,
} from '../../layout/HStack';
import VStack, { VStackAlignment, VStackSpacing } from '../../layout/VStack';
import Textv2, { TextColor, TextSize } from '../Textv2';

export { VStackAlignment as NoDataMessageAlignment } from '../../layout/VStack';

export const CHECK_FILTERS_STRING =
  'Check if you have active filters or are iterating on a specific LF';

type NoDataMessageProps = Readonly<{
  header: string;
  subheader?: string;
  actionButton?: ReactNode;
  dataCySuffix?: string;
  alignment?: VStackAlignment;
  isCentered?: boolean;
}>;

/**
 * Renders a descriptive message with a header, subheader, and optional button.
 * This component should be rendered in empty data states, with a concise header and
 * subheader that clearly explains how or when data may be populated.
 * @param props
 * @returns <NoDataMessage/>
 */

const NoDataMessage = ({
  header,
  subheader = '',
  actionButton,
  dataCySuffix,
  alignment = VStackAlignment.START,
  isCentered,
}: NoDataMessageProps) => {
  return (
    <HStack
      data-cy={
        dataCySuffix ? `no-data-message-${dataCySuffix}` : 'no-data-message'
      }
      padding={{
        left: HStackLeftPadding.l,
        bottom: HStackBottomPadding.l,
        top: HStackTopPadding.l,
      }}
      spacing={HStackSpacing.l}
      alignment={HStackAlignment.CENTER}
      width={HStackWidth.FULL}
      height={isCentered ? HStackHeight.FULL : undefined}
      position={isCentered ? HStackPosition.CENTER : undefined}
    >
      <VStack alignment={alignment} spacing={VStackSpacing.s}>
        <Textv2 color={TextColor.GRAY} size={TextSize.medium}>
          {header}
        </Textv2>
        {subheader.length > 0 ? (
          <Textv2 color={TextColor.GRAY} size={TextSize.small}>
            {subheader}
          </Textv2>
        ) : null}
      </VStack>
      {actionButton ? (
        <div className="m-auto mt-2 mb-0 mr-6">{actionButton}</div>
      ) : null}
    </HStack>
  );
};

export default NoDataMessage;
