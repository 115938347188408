import { useAtomValue } from 'jotai';
import type { ReactNode } from 'react';
import { useEffect } from 'react';

import useAuthContext from '@hooks/useAuthContext';
import type { WebSocketChannel } from '@hooks/useWebSocketNotfications/types';
import useWebSocketNotifications from '@hooks/useWebSocketNotfications/useWebSocketNotifications';

import useGlobalPreferences from './PrivateRouteProviders/UserSettingsProvider/hooks/useGlobalPreferences';
import { userChannelWebsocketAtom } from './store/websockets';

type NotificationWebSocketHandlerProps = {
  children: ReactNode;
};

/**
 * Top level wrapper that initializes a websocket connection
 * to persist throughout the user's session
 *
 * @returns
 */
const NotificationWebSocketHandler = ({
  children,
}: NotificationWebSocketHandlerProps) => {
  const { initializeNotificationWebSocket } = useWebSocketNotifications();
  const userChannelWebsocket = useAtomValue(userChannelWebsocketAtom);
  const { enableNotifications } = useGlobalPreferences();

  const { user_uid: userUid } = useAuthContext();
  const channel: WebSocketChannel = `user:${userUid}`;

  /**
   * @effect Initialize the websocket connection if one for that channel
   * does not already exist
   * @deps None, runs on mount
   */
  useEffect(() => {
    if (!userChannelWebsocket && enableNotifications) {
      initializeNotificationWebSocket(channel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableNotifications]);

  return <>{children}</>;
};

export default NotificationWebSocketHandler;
