import { createContext } from 'react';

import type {
  UserSettings,
  UserSettingsReadState,
  UserSettingsWriteState,
} from './types';

export const userSettingsInitialState: UserSettings = {};

const Read = createContext<UserSettingsReadState>({
  userSettings: userSettingsInitialState,
  isFetching: true,
});

const Write = createContext<UserSettingsWriteState>({
  setUserSettingsState: () => {},
  updateUserSettings: async () => ({}),
});

const UserSettingsContext = {
  Read,
  Write,
};

export default UserSettingsContext;
