import { useCallback, useRef } from 'react';

import type { UseRequestOptions } from '@snorkel/useRequest/types';

import useNodeId from './useNodeId';
import useUntypedRequest from './useUntypedRequest';

/** @deprecated: Use typed request handlers */
const useNodeRequest = () => {
  const nodeId = useNodeId();

  const request = useUntypedRequest();
  const refRequest = useRef(request);
  refRequest.current = request;

  return useCallback(
    (path: string, options?: UseRequestOptions) =>
      refRequest.current(`nodes/${nodeId}/${path}`, options),
    [nodeId, refRequest],
  );
};

export type NodeRequest = ReturnType<typeof useNodeRequest>;

export default useNodeRequest;
