import type { InputProps } from '../types';
import { InputSizes } from '../../InputContainer/types';

const getInputStyles = ({
  size = InputSizes.medium,
  error,
  variant = 'default',
}: Pick<InputProps, 'size' | 'error' | 'variant'>) => {
  const fontSizeClasses = size === 'small' ? 'text-xs' : 'text-sm';
  const backgroundClasses = variant === 'filled' ? 'bg-gray-100' : 'bg-white';
  const defaultBorderClassName =
    variant === 'filled' ? 'border-gray-100' : 'border-gray-border';
  const borderClasses = error
    ? 'border border-red-700'
    : `border ${defaultBorderClassName} focus:border-primary`;

  return `${borderClasses} ${fontSizeClasses} ${backgroundClasses}`;
};

export default getInputStyles;
