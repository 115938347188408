import type { PropsWithChildren } from 'react';

import useSetObjectStorageUrlToNavMeta from '@global/providers/NavigationProvider/hooks/useSetObjectStorageUrlToNavMeta';
import useUserLastNodePageVisited from '@global/providers/NavigationProvider/hooks/useUserLastNodePageVisited';

type NavigationHooksProps = PropsWithChildren<{}>;

const NavigationHooks = ({ children }: NavigationHooksProps) => {
  useUserLastNodePageVisited();
  useSetObjectStorageUrlToNavMeta();

  return <>{children}</>;
};

export default NavigationHooks;
