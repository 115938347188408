import type { NextPageContext } from 'next';

import type { AccessTokenData } from '@core/types';
import AccessToken from '@utils/AccessToken';
import fetchFromApi from '@utils/api/fetchFromApi';
import getAccessToken from '@utils/getAccessToken';
import getErrorMessage from '@utils/getErrorMessage';
import getIsServerRendered from '@utils/getIsServerRendered';
import parseCookie from '@utils/parseCookie';

import isExternalJwtLoginEnabled from './isJwtLoginEnabled';
import parseJWTFromAccessToken from './parseJWTFromAccessToken';

const getAccessTokenSSR = (ctx: NextPageContext) => {
  if (isExternalJwtLoginEnabled()) {
    const accessToken = ctx.req?.headers.authorization?.split(' ')[1];

    if (!accessToken) {
      throw Error('Auth header not found or invalid in SSR');
    }

    const { exp } = parseJWTFromAccessToken(accessToken);

    return Promise.resolve({ accessToken, expiry: exp });
  }

  const cookies = parseCookie(ctx.req);

  const { refresh_token } = cookies;

  return getAccessToken(refresh_token);
};

const shouldRenewToken = (token: AccessTokenData) => {
  const hourMs = 1000 * 60 * 60;
  const timeDifferenceFromNow = token.expiry - new Date().getTime();

  const timeDifferenceInHourMs = timeDifferenceFromNow / hourMs;

  // is token going to expire in 1 hour or already expired
  const isTokenExpiredORToBeExpired = timeDifferenceInHourMs < 1;

  return isTokenExpiredORToBeExpired;
};

const getAccessTokenCSR = async () => {
  const accessToken = AccessToken.getInstance().getAccessToken();

  const isTokenValid = accessToken && !shouldRenewToken(accessToken);

  if (isTokenValid) {
    return accessToken;
  }

  try {
    const response = await fetchFromApi('access-token');
    const newAccessToken = await response.json();
    AccessToken.getInstance().setAccessToken(newAccessToken);

    return newAccessToken;
  } catch (e) {
    throw new Error(getErrorMessage(e));
  }
};

const makeGetAccessToken = (): ((ctx: any) => Promise<AccessTokenData>) => {
  if (getIsServerRendered()) {
    return getAccessTokenSSR;
  }

  return getAccessTokenCSR;
};

export default makeGetAccessToken;
