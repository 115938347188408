import { SplitType } from '@core/types';

const getInitialSplit = (splits: string[]) => {
  if (splits.includes(SplitType.TRAIN)) {
    return SplitType.DEV;
  }

  if (splits.includes(SplitType.VALID)) {
    return SplitType.VALID;
  }

  return SplitType.TEST;
};

export default getInitialSplit;
