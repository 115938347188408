import type { WorkspaceTypes } from '@app/StudioPage/types';

import type {
  GlobalPreferences,
  LFPreferences,
  ModelPreferences,
  OnboardingPreferences,
  StudioPreferences,
  UserSettings,
  ViewConfig,
} from './types';
import { UserSettingsActions } from './types';

export const updateAllStateAction = (userSettings: UserSettings) => ({
  type: UserSettingsActions.UPDATE_ALL,
  data: userSettings,
});

export const updateGlobalPreferencesStateAction = (
  globalPreferences: GlobalPreferences,
) => ({
  type: UserSettingsActions.UPDATE_GLOBAL_PREFERENCES,
  data: globalPreferences,
});

export const updateStudioPreferencesStateAction = (
  studioPreferences: StudioPreferences,
) => ({
  type: UserSettingsActions.UPDATE_STUDIO_PREFERENCES,
  data: studioPreferences,
});

export const updateViewConfigStateAction = (viewConfig: ViewConfig) => ({
  type: UserSettingsActions.UPDATE_VIEW_CONFIG,
  data: viewConfig,
});

export const updateDagManipulationEnabledStateAction = (
  dagManipulationEnabled: boolean,
) => ({
  type: UserSettingsActions.UPDATE_DAG_MANIPULATION_ENABLED,
  data: dagManipulationEnabled,
});

export const updateWorkspaceTypeStateAction = (
  workspaceType: WorkspaceTypes,
) => ({
  type: UserSettingsActions.UPDATE_WORKSPACE_TYPE,
  data: workspaceType,
});

export const updatePlotGroundTruthStateAction = (plotGroundTruth: string) => ({
  type: UserSettingsActions.UPDATE_PLOT_GROUND_TRUTH,
  data: plotGroundTruth,
});

export const updateLFPreferencesStateAction = (
  lfPreferences: LFPreferences,
) => ({
  type: UserSettingsActions.UPDATE_LF_PREFERENCES,
  data: lfPreferences,
});

export const updateIsStudioV2Action = (isStudioV2: boolean) => ({
  type: UserSettingsActions.UPDATE_IS_STUDIO_V2,
  data: isStudioV2,
});

export const updateModelPreferencesStateAction = (
  modelPreferences: ModelPreferences,
) => ({
  type: UserSettingsActions.UPDATE_MODEL_PREFERENCES,
  data: modelPreferences,
});

export const updateOnboardingPreferencesStateAction = (
  onboardingPreferences: OnboardingPreferences,
) => ({
  type: UserSettingsActions.UPDATE_ONBOARDING_PREFERENCES,
  data: onboardingPreferences,
});

export default null;
