import React, { forwardRef } from 'react';
import type { InputProps } from '../components/Input/types';
import Input from '../components/Input';
import Icon, { Icons } from '../components/Icon';

const SearchInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Input {...props} ref={ref} prefixEl={<Icon name={Icons.SEARCH} />} />
));

export default SearchInput;
