import { useEffect, useState } from 'react';

import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { useTaskStateContext } from '@hooks/useRootContext/useTaskStateContext';
import getUrlComponents from '@utils/getUrlComponents';

import useAuthContext from './useAuthContext';

const useNotebookState = () => {
  const { jupyter, userDir } = useTaskStateContext();
  const [open, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>(jupyter);
  const [currentUrl, setCurrentUrl] = useState<string>(jupyter);
  const [key, setKey] = useState<string>('undefined0');
  const { handleCopy } = useCopyToClipboard();
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const { user_uid, isLoggingOut } = useAuthContext();

  const handleFileBrowserClick = () => {
    const { file, path } = getUrlComponents(currentUrl);
    let workingDir = userDir;

    if (file && path && url) {
      // if user is editing a file and then click File Browser again,
      // the url still has edit in it as the "jupyter:pageChange"
      // is never called to update currentUrl
      workingDir = path.replace('edit', 'tree');
    }

    if (workingDir === url) {
      // Reload iframe if url is same
      setKey(`${user_uid}${key + 1}`);
    }

    setUrl(workingDir);
  };

  const onNotebookEvent = (e: any) => {
    if (e && e.data?.eventType === 'jupyter:notebookRename') {
      setCurrentUrl(e.source.location.href);
    }

    if (e.data?.eventType === 'jupyter:pageChange' && e.data?.eventData) {
      setCurrentUrl(e.data?.eventData);
    }
  };

  useEffect(() => {
    setCurrentUrl(jupyter);
    setUrl(jupyter);
  }, [jupyter]);

  useEffect(() => {
    window.addEventListener('message', onNotebookEvent);

    return () => window.removeEventListener('message', onNotebookEvent);
  }, []);

  const copyNotebookUrl = () => {
    handleCopy(currentUrl, 'Notebook URL');
  };

  return {
    open,
    url,
    key,
    isLoggingOut,
    handleOpen,
    handleClose,
    handleFileBrowserClick,
    copyNotebookUrl,
  };
};

export default useNotebookState;
