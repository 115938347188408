import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useCallback } from 'react';
import useUseRequestContext from './UseRequestProvider/hooks/useUseRequestContext';
import type { RequestError, UseRequestOptions } from './types';
import TIMEOUT_ERROR_MSG from './utils/constants';
import generateAPIUrl from './utils/generateAPIUrl';
import generateDefaultHeaders from './utils/generateDefaultHeaders';
import parseJWTFromAccessToken from './utils/parseJWTFromAccessToken';
import type { Unwrap } from './utils/unwrap';
import useMakeOnRequestFailed from './utils/useMakeOnRequestFailed';

const getErrorThreshold = () => {
  const errorThreshold =
    typeof window === 'undefined' ? 0 : (window as any).sf?.errorThreshold;

  return errorThreshold ?? 0;
};

const useRequest = showErrorAlert => {
  const {
    accessToken: { accessToken },
    setAccessToken,
    basePath,
  } = useUseRequestContext();
  const makeOnRequestFailed = useMakeOnRequestFailed(showErrorAlert);

  return useCallback(
    async <
      TArgs extends Array<any>,
      TPromise extends Promise<AxiosResponse<any>>,
    >(
      openApiPromise: (...args: TArgs) => TPromise,
      options: UseRequestOptions<Unwrap<TPromise>['data']> = {},
      ...args: TArgs
    ): Promise<Unwrap<TPromise>['data']> => {
      const onRequestFailed = makeOnRequestFailed(options);

      try {
        const that = {
          basePath: generateAPIUrl({ basePath }),
          axios,
          configuration: {
            baseOptions: {
              headers: generateDefaultHeaders(accessToken),
              validateStatus: () => true,
            },
          },
        };

        const result = await openApiPromise.call(that, ...args);

        if (Math.floor(Math.random() * 100) < getErrorThreshold()) {
          result.status = 542;
        }

        const { data, headers } = result;

        if (headers.authorization) {
          try {
            setAccessToken({
              accessToken: headers.authorization,
              expiry: parseJWTFromAccessToken(headers.authorization).exp,
            });
          } catch (e: any) {
            // do nothing
          }
        }

        if (result.status >= 400) {
          const error: RequestError = Object.assign(new Error(), {
            response: {
              ...result,
              config: {
                ...result.config,
                headers: {
                  ...result.config.headers,
                  authorization: '',
                },
              },
            },
            type: 'api-error',
            status: result.status,
            statusText: result.statusText,
            body: {
              user_friendly_message:
                result.status === 408
                  ? TIMEOUT_ERROR_MSG
                  : data?.user_friendly_message,
              detail: data?.detail,
              metadata: data?.metadata,
            },
          });

          return onRequestFailed(error);
        }

        if (options && options.onSuccess) {
          return options.onSuccess(data) ?? data;
        }

        return data;
      } catch (e: any) {
        return onRequestFailed(e);
      }
    },
    [accessToken],
  );
};

export default useRequest;
