import getHeightClass, { HeightSizes } from '../../Input/utils/getHeightClass';
import { ButtonSizes } from '../types';

const getSize = (size: ButtonSizes, rounded?: boolean) => {
  const roundedClass = rounded ? 'rounded' : '';
  const heightClass = getHeightClass(HeightSizes[size]);
  const commonClasses = [roundedClass, heightClass].join(' ');

  if (size === ButtonSizes.large) {
    return `px-3 text-sm ${commonClasses}`;
  }

  if (size === ButtonSizes.medium) {
    return `h-8 leading-small text-xs px-2 ${commonClasses}`;
  }

  if (size === ButtonSizes.small) {
    return `h-6 leading-small px-2 text-xs ${commonClasses}`;
  }

  if (size === ButtonSizes.slim) {
    return `px-1 text-xs ${commonClasses}`;
  }

  if (size === ButtonSizes.bare) {
    return `text-xs ${commonClasses}`;
  }

  return `px-3 py-2 text-sm min-h-input ${commonClasses}`;
};

export default getSize;
