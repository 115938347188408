import type { ReactNode } from 'react';

type DialogContentProps = {
  children: ReactNode;
  className?: string;
  dataCy?: string;
};

const DialogContent = ({
  children,
  className,
  dataCy = 'dialog-content',
}: DialogContentProps) => {
  return (
    <div
      data-cy={dataCy}
      className={`overflow-auto px-6 pb-6 ${className || ''}`}
    >
      {children}
    </div>
  );
};

export default DialogContent;
