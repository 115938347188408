import React, { useState } from 'react';

import useGlobalPreferences from '@global/PrivateRouteProviders/UserSettingsProvider/hooks/useGlobalPreferences';
import NavigationContext, {
  initialNavigation,
} from '@global/providers/NavigationProvider/context';
import NavigationHooks from '@global/providers/NavigationProvider/NavigationHooks';
import NavigationSidebar from '@global/providers/NavigationProvider/NavigationSidebar';
import type {
  NavigationMetaData,
  NavItem,
} from '@global/providers/NavigationProvider/types';
import useEffectAbortable from '@hooks/useEffectAbortable';
import useGetWorkspaces from '@hooks/useGetWorkspaces';
import useInitializeNotebook from '@hooks/useInitializeNotebook';

import NavigationContextInspector from './NavigationContextInspector';

type NavigationProviderProps = {
  children: React.ReactNode;
};

const NavigationProvider = ({ children }: NavigationProviderProps) => {
  const { refreshWorkspaces } = useGetWorkspaces();
  const { savedWorkspace } = useGlobalPreferences();
  const [navigation, setNavigation] = useState<NavItem[]>(initialNavigation);
  const [currentApplicationNavigation, setCurrentApplicationNavigation] =
    useState<NavItem[]>([]);

  const [meta, setNavigationMetaData] = useState<NavigationMetaData>({});

  useInitializeNotebook();

  useEffectAbortable(refreshWorkspaces, [savedWorkspace]);

  return (
    <NavigationContext.Provider
      value={{
        meta,
        navigation,
        currentApplicationNavigation,
        setNavigation,
        setCurrentApplicationNavigation,
        setNavigationMetaData,
      }}
    >
      <NavigationHooks>
        <NavigationSidebar />
        {children}
      </NavigationHooks>
      <NavigationContextInspector />
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
