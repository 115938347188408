import type { Dispatch } from 'react';
import { createContext } from 'react';

import type { BatchDetails } from '@app/Annotations/types';
import type { AnnotationGroup } from '@app/StudioPage/providers/AnnotationProvider/type';
import type { Action } from '@core/types';
import { SplitType } from '@core/types';

export type AnnotationReadState = {
  selectedAnnotatorUid?: number;
  currentBatch?: BatchDetails;
  annotations: AnnotationGroup;
  isLoadingAnnotations: boolean;
  annotationDataviewerSplit: SplitType;
  annotationDataviewerCurrAnnotationUid?: number | null;
  isSplitViewOpen: boolean;
};

export const defaultAnnotationReadState: AnnotationReadState = {
  annotations: {},
  isLoadingAnnotations: false,
  annotationDataviewerSplit: SplitType.DEV,
  isSplitViewOpen: false,
};

const Read = createContext<AnnotationReadState>({
  annotations: {},
  isLoadingAnnotations: false,
  annotationDataviewerSplit: SplitType.DEV,
  annotationDataviewerCurrAnnotationUid: null,
  isSplitViewOpen: false,
});

const Write = createContext<Dispatch<Action>>(() => {});

const AnnotationContext = {
  Read,
  Write,
};

export default AnnotationContext;
