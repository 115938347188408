export default {
  root: {
    boxShadow: 'none',
  },
  elevation4: {
    boxShadow: 'none',
  },
  elevation1: {
    boxShadow: 'none',
  },
};
