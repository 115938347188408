import moment from 'moment';

import { usersApi } from '@utils/api/serverRequests';

const getAccessToken = async (refreshToken?: string) => {
  if (!refreshToken) {
    throw new Error('No refresh token');
  }

  const {
    status,
    data: { access_token, expires_in },
  } = await usersApi.refreshTokenRefreshTokenPost({
    refresh_token: refreshToken,
  });

  if (!access_token || status >= 400) {
    throw new Error('Login failed');
  }

  return {
    accessToken: access_token,
    expiry: moment().add(expires_in, 'seconds').valueOf(),
  };
};

export default getAccessToken;
