import type { AxiosResponse } from 'axios';
import type { BareFetcher, Key, SWRConfiguration, SWRHook } from 'swr';

import createCacheKey from '@utils/requestCaching/createCacheKey';
import type { CacheKeyArgs } from '@utils/requestCaching/types';

// This middleware is used to create unique cache keys for both OpenAPI and string requests.
// It is modeled after the SWR docs https://swr.vercel.app/docs/middleware#api
const cacheKeyMiddleware =
  (useSWRNext: SWRHook) =>
  <
    TArgs extends any[] = any[],
    TPromise extends Promise<AxiosResponse<any>> = Promise<AxiosResponse<any>>,
  >(
    key: Key | CacheKeyArgs<TArgs, TPromise> | null,
    fetcher: BareFetcher | null,
    config: SWRConfiguration,
  ) => {
    if (!fetcher) {
      return useSWRNext(key, fetcher, config);
    }

    const [resource, requestOptions, args] = Array.isArray(key) ? key : [null];

    const serviceUrl = createCacheKey(
      resource,
      args as TArgs,
      requestOptions?.onSuccess,
    );

    return useSWRNext(
      serviceUrl,
      () => fetcher(resource, requestOptions, ...(args as TArgs)),
      config,
    );
  };

export default cacheKeyMiddleware;
