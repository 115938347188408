import { useEffect, useState } from 'react';

import useUntypedRequest from '@hooks/useUntypedRequest';
import type { AccountInfo } from '@snorkel/api/lib';

export interface AccountInformation {
  account_id: string;
  expiration_date: Date;
  validate_system: boolean;
  system_validation_key: string;
  dataset_limit: number;
  application_limit: number;
  seat_limit: number;
}

export interface CurrentCount {
  current_dataset_count: number;
  current_application_count: number;
  current_user_count: number;
}

export interface ExpirationBuffers {
  license_until_expiration_days: number;
  license_buffer_days: number;
}

const initialCount = {
  current_dataset_count: 0,
  current_application_count: 0,
  current_user_count: 0,
};

type UseGetLicenseArgs = {
  noInit?: boolean;
};

const useGetLicense = (args: UseGetLicenseArgs = {}) => {
  const [isLoading, setLoading] = useState(!args.noInit);
  const [hasLicense, setHasLicense] = useState(false);
  const [hasUsers, setHasUsers] = useState(false);
  const [accountInfo, setAccountInfo] = useState<AccountInfo>();
  const [currentCount, setCurrentCount] = useState<CurrentCount>(initialCount);
  const [expirationBuffers, setExpirationBuffers] =
    useState<ExpirationBuffers>();
  const request = useUntypedRequest();

  const getLicense = async () => {
    setLoading(true);
    const validateResponse = await request('validate');
    const { data: status } = validateResponse || {};

    if (status && status.valid) {
      const licenseResponse = await request('license');
      const {
        account_info,
        current_dataset_count,
        current_application_count,
        current_user_count,
        license_until_expiration_days,
        license_buffer_days,
      } = licenseResponse || {};

      if (current_user_count > 0) {
        setHasUsers(true);
      }

      setHasLicense(true);
      setAccountInfo(account_info);
      setCurrentCount({
        current_dataset_count,
        current_application_count,
        current_user_count,
      });
      setExpirationBuffers({
        license_buffer_days,
        license_until_expiration_days,
      });

      setLoading(false);

      return;
    }

    setHasLicense(false);
    setHasUsers(false);
    setLoading(false);
  };

  const refreshLicense = () => {
    getLicense();
  };

  useEffect(() => {
    if (!args.noInit) {
      getLicense();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    hasLicense,
    hasUsers,
    accountInfo,
    currentCount,
    refreshLicense,
    expirationBuffers,
  };
};

export default useGetLicense;
