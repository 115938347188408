import RobotoMonoRegular from '../../../public/static/fonts/roboto-mono-regular.woff';

const robotoMonoRegular = {
  fontFamily: 'robot-mono-regular',
  src: `url(${RobotoMonoRegular}) format('woff')`,
  fontStyle: 'normal',
  fontWeight: 'normal',
};

export default {
  '@global': {
    '@font-face': [robotoMonoRegular],
  },
};
