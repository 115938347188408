import React from 'react';

import { GenericSizes } from '@components/types';

type UserProfilePictureProps = {
  name?: string;
  size: GenericSizes;
};

const UserProfilePicture = ({ name = '', size }: UserProfilePictureProps) => {
  const className =
    size === GenericSizes.Large ? 'w-16 h-16 text-lg' : 'w-8 h-8 text-xs';

  return (
    <div
      className={`${className} bold flex items-center justify-center rounded-full bg-gray-200`}
    >
      {name.split('')[0].toUpperCase()}
    </div>
  );
};

export default UserProfilePicture;
