import type { PropsWithChildren } from 'react';

type PageContainerProps = PropsWithChildren<{}>;

const PageContainer = ({ children }: PageContainerProps) => (
  <div className="homepage-content flex h-full w-full flex-1">
    <div className="h-full w-full flex-1">{children}</div>
  </div>
);

export default PageContainer;
