import type { Context } from 'react';
import { createContext } from 'react';

import { initialState } from '@global/store/root/data/reducer';
import type { DataDispatch, DataState } from '@global/store/root/data/types';

const initialDispatch: DataDispatch = null as any;
const Dispatch: Context<DataDispatch> = createContext(initialDispatch);

const State: Context<DataState> = createContext(initialState);

const DataContext = { Dispatch, State };

export default DataContext;
