import type { PropsWithChildren } from 'react';
import { useReducer } from 'react';

import reducer, { initialState } from '@global/store/root/task/reducer';

import TaskContext from './TaskContext';

type TaskContextProviderProps = PropsWithChildren<{}>;

const TaskContextProvider = ({ children }: TaskContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TaskContext.Dispatch.Provider value={dispatch}>
      <TaskContext.State.Provider value={state}>
        {children}
      </TaskContext.State.Provider>
    </TaskContext.Dispatch.Provider>
  );
};

export default TaskContextProvider;
