import * as Sentry from '@sentry/nextjs';

import { SENTRY_DSN, SENTRY_ENVIRONMENT } from '@core/constants';
import { calcApiUrl } from '@utils/calcApiUrl';

const initSentry = () => {
  if (!SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [new RegExp(`^${calcApiUrl()}`)],
      }),
      new Sentry.Replay({
        maskAllInputs: true,
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
  });
};

export default initSentry;
