import type { RunningJob } from '@core/types';

import { JobProgressNotificationsActions } from './types';

export const queueJobProgressBar = (
  jobUid: string,
  runningJob: RunningJob,
  onCompleteMessage?: string,
) => ({
  type: JobProgressNotificationsActions.QUEUE_JOB_PROGRESS_BAR,
  data: {
    jobUid,
    onCompleteMessage,
    runningJob,
  },
});

export const removeJobProgressBar = (jobUid: string) => ({
  type: JobProgressNotificationsActions.REMOVE_JOB_PROGRESS_BAR,
  data: {
    jobUid,
  },
});
