import type { Context } from 'react';
import { createContext } from 'react';

import { initialState } from '@global/store/root/task/reducer';
import type { TaskDispatch } from '@global/store/root/task/types';

const initialDispatch: TaskDispatch = null as any;
const Dispatch: Context<TaskDispatch> = createContext(initialDispatch);

export type TaskState = Record<string, any>;
const State: Context<TaskState> = createContext(initialState as any);

const TaskContext = { Dispatch, State };

export default TaskContext;
