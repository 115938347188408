import type { Dispatch } from 'react';

import type { Action } from '@core/types';
import type {
  BuilderSettings,
  GlobalPreferences,
  LFPreferences,
  ModelPreferences,
  OnboardingPreferences,
  StudioPreferences,
  UserSettingsJson,
  ViewConfig,
} from '@snorkel/api/lib';
import type { LabelColor } from '@snorkel/coral/utils/styles/labelColors';

export enum UserSettingsActions {
  UPDATE_ALL = 'UPDATE_ALL',
  UPDATE_DAG_MANIPULATION_ENABLED = 'UPDATE_DAG_MANIPULATION_ENABLED',
  UPDATE_GLOBAL_PREFERENCES = 'UPDATE_GLOBAL_PREFERENCES',
  UPDATE_IS_STUDIO_V2 = 'UPDATE_IS_STUDIO_V2',
  UPDATE_LF_PREFERENCES = 'UPDATE_LF_PREFERENCES',
  UPDATE_MODEL_PREFERENCES = 'UPDATE_MODEL_PREFERENCES',
  UPDATE_PLOT_GROUND_TRUTH = 'UPDATE_PLOT_GROUND_TRUTH',
  UPDATE_STUDIO_PREFERENCES = 'UPDATE_STUDIO_PREFERENCES',
  UPDATE_VIEW_CONFIG = 'UPDATE_VIEW_CONFIG',
  UPDATE_WORKSPACE_TYPE = 'UPDATE_WORKSPACE_TYPE',
  UPDATE_ONBOARDING_PREFERENCES = 'UPDATE_ONBOARDING_PREFERENCES',
}

export type { BuilderSettings };
export type { GlobalPreferences };
export type { LFPreferences };
export type { ModelPreferences };
export type { StudioPreferences };
export type { ViewConfig };
export type { OnboardingPreferences };

export type SelectedTableFields = NonNullable<
  StudioPreferences['table_fields']
>;
export type SelectedExpandedFields = NonNullable<
  StudioPreferences['expanded_fields']
>;
export type SelectedContextFields = NonNullable<
  StudioPreferences['context_fields']
>;
export type SelectedSpanFields = NonNullable<StudioPreferences['span_fields']>;
export type SelectedLFMetrics = NonNullable<LFPreferences['selected_metrics']>;

export type UserSettings = Omit<UserSettingsJson, 'custom_colors'> & {
  custom_colors?: LabelColor[];
};

export type UserSettingsContext = Omit<
  UserSettingsJson,
  'custom_colors' | 'label_color_scheme'
>;

export type UserSettingsReadState = {
  userSettings: UserSettingsContext;
  isFetching: boolean;
};

export type UserSettingsWriteState = {
  setUserSettingsState: Dispatch<Action>;
  updateUserSettings: (settingsToUpdate: UserSettings) => Promise<UserSettings>;
};
