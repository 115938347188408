import type { BackendFeatureFlagNames } from '@snorkel/api/lib';
import type { Icons } from '@snorkel/coral/components/Icon';
import type { Flags } from '@utils/getFlag';

export enum Tool {
  DEFAULT = 'default',

  // Text tools
  SEARCH = 'search',
  CLUSTER = 'cluster',
  FM = 'foundation-model',

  // Code tools
  CODE = 'code',

  // Image tools
  IMAGE_TEXT = 'image-text',
  IMAGE_IMAGE = 'image-image',
  IMAGE_MODEL = 'image-model',
  PATTERN = 'pattern',
}

export type ToolInfoType = {
  tool: Tool;
  title: string;
  description: string;
  icon: Icons;
  disable?: boolean;
  flag?: BackendFeatureFlagNames | Flags;
};

export default null;
