import * as Progress from '@radix-ui/react-progress';
import OptionalTooltip from '@snorkel/coral/components/tooltips/OptionalTooltip';
import combineClasses from 'src/utils/combineClasses';

type ProgressBarProps = {
  value: number;
  tooltip?: string;
  className?: string;
  pausedProgress?: boolean;
};

const ProgressBar = ({
  value,
  tooltip,
  className,
  pausedProgress,
}: ProgressBarProps) => {
  return (
    <OptionalTooltip title={tooltip}>
      <Progress.Root
        className={combineClasses(
          'h-2 w-full overflow-hidden rounded-full bg-gray-200',
          className,
        )}
      >
        <Progress.Indicator
          className={`h-full w-full rounded-full ${
            pausedProgress ? 'bg-gray-500' : ''
          } transition-all duration-300 ease-in-out`}
          style={{
            transform: `translateX(-${100 - value}%)`,
            background: 'linear-gradient(90deg, #0043CE 0%, #82CFFF 100%)',
          }}
        />
      </Progress.Root>
    </OptionalTooltip>
  );
};

export default ProgressBar;
