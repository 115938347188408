export enum StudioViewModes {
  DOCUMENT_VIEW = 'document',
  DATA_VIEW = 'data',
  GALLERY_VIEW = 'gallery',
  IMAGE_VIEW = 'image',
  GRID_VIEW = 'grid',
  TABLE_VIEW = 'table',
  PLOT_VIEW = 'plot',
  RAW = 'raw',
  SEARCH_VIEW = 'search',
}

export enum WorkspaceTypes {
  DEVELOPMENT = 'default',
  ANNOTATION = 'annotator',
}

export type FeedbackResponse = {
  score: number;
  text: string;
};

export type SuggestionType = {
  msg: string;
  key?: string;
  lvl: number;
  lf_names?: string[];
  page?: string;
  page_options?: null;
};

export enum SpanTextsModelOptions {
  GT = 'gt',
  CURR_LF = 'curr_lf',
}

export enum FieldTypes {
  Table = 'Table',
  String = 'str',
  Integer = 'int',
}

export type SpanTextsModel = number | SpanTextsModelOptions;

export type Value = null | string | number | boolean | undefined;

export type ViewOption<T = Value> = {
  uid: string;
  title: string;
  choices: null | string[] | number[];
  onChange: (value?: T) => void;
  value?: T;
  'data-cy': string;
};

export type StudioPageProps = {
  mode?: string;
  page?: number;
  workspace: WorkspaceTypes;
};

export default null;
