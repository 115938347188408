/** @type {import('tailwindcss').Config} */
const tailwindConfig = {
  theme: {
    extend: {
      zIndex: {
        backdrop: 1200,
        dialog: 1202,
        notifications: 9999,
        tooltips: 10000,
        vis: 4000,
      },
      fontSize: {
        xxs: ['0.625rem', '1rem'],
        button: ['0.875rem', '1.5rem'],
        xxl: '2rem', // to be deprecated in v2
        canvas: '12px',
        xsmall: ['0.625rem', '12px'],
        small: ['0.75rem', '16px'],
        medium: ['0.875rem', '24px'],
        large: ['1rem', '24px'],
        xlarge: ['1.25rem', '32px'],
        xxlarge: ['1.5rem', { lineHeight: 32, letterSpacing: '-0.25%' }],
      },
      letterSpacing: {
        1: '0.01em', // to be deprecated
        2: '0.02em', // to be deprecated
        narrow1: '-0.03rem',
        narrow2: '-0.016rem',
        wide: '0.006rem',
        mono: '1%',
      },
      screens: {
        print: { raw: 'print' },
      },
      borderWidth: {
        '3/2': '1.5px',
      },
      opacity: {
        0: '0',
        25: '.25',
        50: '.5',
        75: '.75',
        10: '.1',
        20: '.2',
        30: '.3',
        40: '.4',
        60: '.6',
        70: '.7',
        80: '.8',
        90: '.9',
        100: '1',
      },
      spacing: {
        124: '31rem',
        92: '23rem',
        72: '18rem',
      },
      padding: {
        '1/2': '0.125rem',
        '5/2': '0.675rem',
        28: '7rem',
        30: '7.5rem',
        50: '12.5rem',
        highlight: '1.5px',
        large: '3rem',
      },
      width: {
        form: '400px',
        'slim-modal': '336px',
        'slim-modal-content': '304px',
        'dialog-content': '600px',
        aside: '360px',
        4.5: '1.125rem',
        25: '6.25rem',
        28: '7rem',
        30: '7.5rem',
        36: '9rem',
        44: '11rem',
        50: '12.5rem',
        56: '14rem',
        '11/24': '45.833%',
        '13/24': '54.1%',
        60: '15rem',
        68: '17rem',
        72: '18rem',
        80: '20rem',
        84: '21rem',
        87.5: '21.875rem',
        88: '22rem',
        96: '24rem',
        100: '25rem',
        112: '28rem',
        128: '32rem',
        144: '36rem',
        160: '40rem',
        176: '44rem',
        '24/25': '96%',
        '1px': '1px',
        smallButton: '27px',
        max: 'max-content',
        buttonIcon: '1rem',
        clusterMap: '280px',
        lfAdvancedSettingsMenu: '292px',
      },
      height: {
        toolbar: '52px',
        studioToolbar: '79px',
        3: '0.75rem',
        4.5: '1.125rem',
        7: '1.75rem',
        7.5: '1.875rem',
        11: '2.75rem',
        12.5: '3.125rem',
        18: '4.5rem',
        30: '7.5rem',
        48: '12rem',
        '24/25': '96%',
        '1px': '1px',
        '40px': '40px',
        smallButton: '27px',
        input: '2.5rem',
        buttonIcon: '1rem',
        barChart: '300px',
        clusterMap: '280px',
      },
      lineHeight: {
        // 4.5: '1.125rem',
        small: '0.925rem',
      },
      minWidth: {
        2: '0.5rem',
        3: '0.75rem',
        20: '5rem',
        24: '6rem',
        25: '6.25rem',
        27.5: '6.875rem',
        32: '8rem',
        40: '10rem',
        50: '12.5rem',
        52: '15rem',
        76: '19rem',
        87.5: '21.875rem',
        aside: '350px',
        '1/2': '50%',
        'custom-new-lf-button': '9.75rem',
        popup: '360px',
        fit: 'fit-content',
      },
      minHeight: {
        input: '40px',
        8: '2rem',
        10: '2.5rem',
        12: '3rem',
        18: '4.5rem',
        toolbar: '52px',
        studioToolbar: '79px',
        32: '8rem',
        36: '9rem',
        40: '10rem',
        80: '20rem',
        '75h': '75vh',
      },
      maxWidth: {
        'slim-modal': '336px',
        'prediction-item': '212px',
        50: '12.5rem',
        80: '18rem',
        DEFAULT: '1680px',
        p: '600px',
        utterance: '70%',
        conversation: '1000px',
        half: '50%',
      },
      maxHeight: {
        28: '7rem',
        40: '10rem',
        48: '12rem',
        52: '13rem',
        56: '14rem',
        76: '19rem',
        80: '20rem',
        '500px': '500px',
        '35h': '35vh',
        '45h': '45vh',
        '75h': '75vh',
      },
    },
    inset: {
      0: 0,
      4: '4px',
      16: '16px',
      24: '24px',
      32: '32px',
      96: '96px', // 6rem
      112: '112px',
      128: '128px',
      144: '144px',
      160: '160px',
      176: '176px',
      192: '192px', // 12rem
      208: '208px',
    },

    boxShadow: {
      sm: '0px 6px 6px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.16), inset 0px 8px 24px -24px rgba(0, 0, 0, 0.02)',
      md: '0px 16px 20px rgba(0, 0, 0, 0.04), 0px 14px 16px rgba(0, 0, 0, 0.06), 0px 8px 8px rgba(0, 0, 0, 0.08), inset 0px 24px 24px -24px rgba(0, 0, 0, 0.02)',
      lg: '0px 26px 32px rgba(0, 0, 0, 0.04), 0px 24px 28px rgba(0, 0, 0, 0.04), 0px 16px 16px rgba(0, 0, 0, 0.08), 0px 12px 12px rgba(0, 0, 0, 0.08), inset 0px 8px 24px -24px rgba(0, 0, 0, 0.02)',
      banner: '0px 7px 32px 0px rgba(0, 0, 0, 0.35)',
    },
    /**
     * https://www.figma.com/file/SqNJZTvByLQHLsb24s1lAk/Coral-2021?node-id=3385%3A7956
     */
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      white: '#FFF',
      black: '#000',
      gray: {
        text: '#747B83',
        'dark-text': '#8A929A',
        100: '#F4F4F4',
        200: '#E0E0E0',
        300: '#C6C6C6',
        400: '#A8A8A8',
        500: '#8D8D8D',
        600: '#6F6F6F',
        700: '#525252',
        800: '#393939',
        900: '#262626',
        1000: '#161616',
      },
      coolGray: {
        100: '#F2F4F8',
        200: '#DDE1E6',
        300: '#C1C7CD',
        400: '#A2A9B0',
        500: '#878D96',
        600: '#697077',
        700: '#343A3F',
        800: '#21272A',
        900: '#121619',
      },
      green: {
        100: '#DEFBE6',
        200: '#A7F0BA',
        300: '#6FDC8C',
        400: '#42BE65',
        500: '#24A148',
        600: '#198038',
        700: '#0E6027',
        800: '#044317',
        900: '#022D0D',
        1000: '#071908',
      },
      lightGreen: {
        100: '#F5F8EF',
        200: '#E0EBCE',
        300: '#CCDEAE',
        400: '#B8D18D',
        500: '#A3C46C',
        600: '#99BD5C',
        700: '#7A974A',
        800: '#5C7137',
        900: '#3D4C25',
        1000: '#1F2612',
      },
      teal: {
        100: '#D9FBFB',
        200: '#9EF0F0',
        300: '#3DDBD9',
        400: '#08BDBA',
        500: '#009D9A',
        600: '#007D79',
        700: '#005D5D',
        800: '#004144',
        900: '#022B30',
        1000: '#081A1C',
      },
      cyan: {
        100: '#E5F6FF',
        200: '#BAE6FF',
        300: '#82CFFF',
        400: '#33B1FF',
        500: '#1192E8',
        600: '#0072C3',
        700: '#00539A',
        800: '#003A6D',
        900: '#012749',
        1000: '#061727',
      },
      blue: {
        100: '#EDF5FF',
        200: '#D0E2FF',
        300: '#A6C8FF',
        400: '#78A9FF',
        500: '#4589FF',
        600: '#0F62FE',
        700: '#0043CE',
        800: '#002D9C',
        900: '#001D6C',
        1000: '#001141',
      },
      indigo: {
        100: '#ECEBF4',
        200: '#C7C2DD',
        300: '#A199C6',
        400: '#7B70AF',
        500: '#564798',
        600: '#43328D',
        700: '#362871',
        800: '#281E55',
        900: '#1B1438',
        1000: '#0D0A1C',
      },
      magenta: {
        100: '#FFF0F7',
        200: '#FFD6E8',
        300: '#FFAFD2',
        400: '#FF7EB6',
        500: '#EE5396',
        600: '#D12771',
        700: '#9F1853',
        800: '#740937',
        900: '#510224',
        1000: '#2A0A18',
      },
      red: {
        100: '#FFF1F1',
        200: '#FFD7D9',
        300: '#FFB3B8',
        400: '#FF8389',
        500: '#FA4D56',
        600: '#DA1E28',
        700: '#A2191F',
        800: '#750E13',
        900: '#520408',
        1000: '#2D0709',
      },
      orange: {
        100: '#FFF2E8',
        200: '#FFD9BE',
        300: '#FFB784',
        400: '#FF832B',
        500: '#EB6200',
        600: '#BA4E00',
        700: '#8A3800',
        800: '#5E2900',
        900: '#3E1A00',
        1000: '#231000',
      },
      purple: {
        100: '#F6F2FF',
        200: '#E8DAFF',
        300: '#D4BBFF',
        400: '#BE95FF',
        500: '#A56EFF',
        600: '#8A3FFC',
        700: '#6929C4',
        800: '#491D8B',
        900: '#31135E',
        1000: '#1C0F30',
      },
      chartreuse: {
        100: '#F9F8EC',
        200: '#EDEAC7',
        300: '#E1DDA2',
        400: '#D5CF7D',
        500: '#C9C158',
        600: '#C3BA45',
        700: '#9C9537',
        800: '#757029',
        900: '#4E4A1C',
        1000: '#27250E',
      },
      yellow: {
        100: '#FCF4D6',
        200: '#FDDC69',
        300: '#F1C21B',
        400: '#DDAA11',
        500: '#B28600',
        600: '#8E6A00',
        700: '#684E00',
        800: '#483700',
        900: '#302400',
        1000: '#1C1500',
      },
      blueGrey: {
        100: '#CFD8DC',
        200: '#B0BEC5',
        300: '#90A4AE',
        400: '#78909C',
        500: '#607D8B',
        600: '#546E7A',
        700: '#455A64',
      },
      brown: {
        100: '#D7CCC8',
        200: '#BCAAA4',
        300: '#A1887F',
        400: '#8D6E63',
        500: '#795548',
        600: '#6D4C41',
        700: '#5D4037',
      },
      navy: {
        100: '#E8EAF5',
        200: '#D2D6F2',
        300: '#A1A8D5',
        400: '#7B86C2',
        500: '#5E6BB2',
        600: '#4555A5',
        700: '#323F8E',
        800: '#242B86',
        900: '#14116C',
        1000: '#110842',
      },
      pink: {
        alert: '#FF00DB',
      },
      highlighter: {
        deprecated: '#B4D5FE',
        yellow: '#FDFF32',
        greenYellowLight: '#E9FF32',
        greenYellow: '#D4FF32',
        greenLight: '#ABFF32',
        greenMed: '#8CFF32',
        greenDark: '#10FF00',
        blueGreen: '#00FFD9',
        red: '#FF0000',
        fuchia: '#FF00DB',
        purple: '#BD00FF',
      },
    },
    fontFamily: {
      mono: "'Roboto Mono', Monaco, Courier, monospace",
      regular: "'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    },
  },
  plugins: [
    // eslint-disable-next-line global-require
    require('@tailwindcss/forms'),
  ],
  variants: {
    extend: {
      textColor: ['disabled'],
      backgroundColor: ['disabled'],
    },
  },
};

const attachColors = (color, nextConfigFn) => {
  tailwindConfig.theme.colors[color] = {
    ...tailwindConfig.theme.colors[color],
    ...nextConfigFn(tailwindConfig.theme.colors[color]),
  };
};

attachColors('green', nextColors => ({
  primary: nextColors['500'],
  success: nextColors['600'],
  20: nextColors['700'],
  30: nextColors['800'],
}));

['coolGray', 'teal', 'cyan', 'pink', 'red', 'purple'].forEach(color => {
  attachColors(color, nextColors => ({
    primary: nextColors['500'],
    10: nextColors['600'],
    20: nextColors['700'],
    30: nextColors['800'],
  }));
});

// Taken from snorkel color brand library
attachColors('blue', () => ({
  primary: '#0B59E6',
  10: '#113C98',
  20: '#0D317E',
  30: '#0D317E',
}));

// Taken from snorkel color brand library
attachColors('purple', () => ({
  primary: '#8735E8',
  10: '#7730CF',
  20: '#571CA0',
  30: '#491785',
}));

tailwindConfig.theme.colors.primary = tailwindConfig.theme.colors.blue['700'];

tailwindConfig.theme.colors.secondary =
  tailwindConfig.theme.colors.purple['700'];

tailwindConfig.theme.colors.tertiary = tailwindConfig.theme.colors.teal['700'];

tailwindConfig.theme.colors.gray.background =
  tailwindConfig.theme.colors.coolGray['100'];

tailwindConfig.theme.colors.gray.border =
  tailwindConfig.theme.colors.coolGray['300'];

module.exports = tailwindConfig;
