import { TextSize, Variant } from '../types';

const getTextSpacing = (variant: Variant, size: TextSize) => {
  if (variant === Variant.heading && size === TextSize.xsmall)
    return 'tracking-1';

  if (variant === Variant.body && size === TextSize.xsmall) return 'tracking-2';

  return 'tracking-normal';
};

export default getTextSpacing;
