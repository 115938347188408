import publicRuntimeConfig from '@utils/publicRuntimeConfig';

/**
 * Always use this function to read the env var value for the base path.
 * This is because the value is populated run time and if you try to read it
 * at build time, it will be undefined and cause issues in local dev.
 * see https://github.com/snorkel-ai/strap/pull/26907 for example usage
 *
 * @returns {string} the base path
 */
export const calcBasePath = (): string => {
  const basePath = publicRuntimeConfig.NEXT_PUBLIC_BASE_PATH;

  return basePath ?? '';
};

export default null;
