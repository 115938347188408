import type { Action } from '@core/types';

import type { UserSettings } from './types';
import { UserSettingsActions } from './types';

const userSettingsReducer = (state: UserSettings = {}, action: Action) => {
  if (!(action && action.type)) {
    return state;
  }

  switch (action.type) {
    case UserSettingsActions.UPDATE_ALL: {
      return action.data;
    }
    case UserSettingsActions.UPDATE_GLOBAL_PREFERENCES: {
      return {
        ...state,
        global_preferences: {
          ...state.global_preferences,
          ...action.data,
        },
      };
    }
    case UserSettingsActions.UPDATE_STUDIO_PREFERENCES: {
      return {
        ...state,
        studio_preferences: {
          ...state.studio_preferences,
          ...action.data, // TODO: preference sub types?
        },
      };
    }
    case UserSettingsActions.UPDATE_VIEW_CONFIG: {
      return {
        ...state,
        studio_preferences: {
          ...state.studio_preferences,
          view_config: {
            ...state.studio_preferences?.view_config,
            ...action.data,
          },
        },
      };
    }
    case UserSettingsActions.UPDATE_DAG_MANIPULATION_ENABLED: {
      return {
        ...state,
        dag_manipulation_enabled: action.data,
      };
    }
    case UserSettingsActions.UPDATE_WORKSPACE_TYPE: {
      return {
        ...state,
        workspace_type: action.data,
      };
    }
    case UserSettingsActions.UPDATE_PLOT_GROUND_TRUTH: {
      return {
        ...state,
        plot_ground_truth: action.data,
      };
    }
    case UserSettingsActions.UPDATE_LF_PREFERENCES: {
      return {
        ...state,
        lf_preferences: {
          ...state.lf_preferences,
          ...action.data,
        },
      };
    }
    case UserSettingsActions.UPDATE_IS_STUDIO_V2: {
      return {
        ...state,
        is_studio_v2: action.data,
        force_studio_v2: action.data,
      };
    }
    case UserSettingsActions.UPDATE_MODEL_PREFERENCES: {
      return {
        ...state,
        model_preferences: {
          ...state.model_preferences,
          ...action.data,
        },
      };
    }
    case UserSettingsActions.UPDATE_ONBOARDING_PREFERENCES: {
      return {
        ...state,
        onboarding_preferences: {
          ...state.onboarding_preferences,
          ...action.data,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default userSettingsReducer;
