import { capitalize } from 'lodash';

import { AnnotationRoutes, DevelopmentRoutes } from '@core/constants';
import useIsAnnotationMode from '@global/PrivateRouteProviders/WorkspaceProvider/hooks/useIsAnnotationMode';
import useNavigation from '@global/providers/NavigationProvider/hooks/useNavigationContext';
import useSnorkelRouter from '@hooks/useSnorkelRouter';

type Route = {
  title: string;
  href: string;
  notificationIndicator?: boolean;
};
const removeUrlParams = (name: string) =>
  name.indexOf('?') !== -1 ? name.split('?')[0] : name;

type UseBreadcrumbProps = {
  isOnboardingEnabled: boolean;
};

const useBreadcrumb = ({
  isOnboardingEnabled,
}: UseBreadcrumbProps): Route[] => {
  const { currentApplicationNavigation, navigation, meta } = useNavigation();
  const { asPath } = useSnorkelRouter();
  const isAnnotationMode = useIsAnnotationMode();

  const route = asPath.slice(1);

  if (route === '') {
    return [];
  }

  if (route.match(/applications\/[0-9]+$/)) {
    return [];
  }

  if (route.match(/(user\/settings|admin\/settings)(\?.*)?$/)) {
    return [];
  }

  if (route.match(/nodes\/[0-9]+$/) && isAnnotationMode) {
    return [];
  }

  const splitRoute = removeUrlParams(route).split('/');

  const routes = splitRoute.map((name, index) => {
    const href = splitRoute.slice(0, index + 1).join('/');
    const title = capitalize(name).replace(/([-_])/g, ' ');

    // we don't show urls that end with applications or overview
    if (href.match(/(applications|overview|annotate|annotation|develop)$/)) {
      return null;
    }

    if (href.match(/^applications\/new\/app$/)) {
      return {
        href: '',
        title: 'Applications',
      };
    }

    if (href.match(/^applications\/new\/app\/[0-9]+$/)) {
      return {
        href,
        title: currentApplicationNavigation[0]?.title,
      };
    }

    // we don't show urls that end with applications or overview
    if (href.match(/^applications\/new$/)) {
      if (route.match(/^applications\/new\/app/)) return null;

      return {
        href,
        title: 'New application',
      };
    }

    // example: annotate/231 should return batch name
    if (href.match(/annotate\/[0-9]+$/)) {
      return {
        href,
        title: meta.batchName || '',
      };
    }

    // example: deployments/231 should return deployment name
    if (href.match(/deployments\/[0-9]+$/)) {
      return {
        href,
        title: meta.deploymentName || '',
      };
    }

    // example: applications/231
    if (href.match(/applications\/[0-9]+$/)) {
      const applicationTitle = currentApplicationNavigation[0]?.title;

      if (isOnboardingEnabled)
        return {
          href: `applications/new/app/${name}`,
          title: applicationTitle,
        };

      return {
        href,
        title: applicationTitle,
      };
    }

    if (href.match(/nodes$/)) {
      return null;
    }

    if (href.match(/nodes\/[0-9]+$/)) {
      if (isAnnotationMode) {
        return {
          href: `${href}/${AnnotationRoutes.ANNOTATION}`,
          title: 'Annotate',
        };
      }

      return {
        href: `${href}/${DevelopmentRoutes.DEVELOP}`,
        title: 'Develop',
      };
    }

    if (title === 'Lf packages') {
      return {
        href,
        title: 'LF Packages',
      };
    }

    if (title === 'Train') {
      // Do a lazy check to see if flag was passed
      const hasUncommittedModel = JSON.stringify(navigation).includes(
        '"notificationIndicator":true',
      );

      return {
        href,
        title,
        notificationIndicator: hasUncommittedModel,
      };
    }

    return {
      href,
      title,
    };
  });

  return routes.filter(v => !!v) as Route[];
};

export default useBreadcrumb;
