import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type {
  NavigationMetaData,
  NavItem,
} from '@global/providers/NavigationProvider/types';
import { Icons } from '@snorkel/coral/components/Icon';

type NavigationContextState = {
  meta: NavigationMetaData;
  navigation: NavItem[];
  currentApplicationNavigation: NavItem[];
  setNavigation: Dispatch<SetStateAction<NavItem[]>>;
  setCurrentApplicationNavigation: Dispatch<SetStateAction<NavItem[]>>;
  setNavigationMetaData: Dispatch<SetStateAction<NavigationMetaData>>;
};

export const initialNavigation: NavItem[] = [
  {
    title: 'Applications',
    href: '/',
    hrefOverride: '/applications',
    leftIcon: Icons.OVERVIEW,
    children: {
      data: [
        {
          hiddenUnlessOn: true,
          title: 'New application',
          href: '/applications/new',
        },
        {
          hiddenUnlessOn: true,
          title: 'New preset application',
          href: '/applications/preset',
        },
      ],
    },
  },
  { title: 'Datasets', href: '/datasets', leftIcon: Icons.DATASET },
  ...[{ title: 'Deployments', href: '/deployments', leftIcon: Icons.DEPLOY }],

  // Notebook button defined inside NavigationSidebarNav
];

// Populate per-application navigation options
const currentApplicationNavigation: NavItem[] = [];

const NavigationContext = createContext<NavigationContextState>({
  meta: {},
  setNavigationMetaData: () => null,
  navigation: initialNavigation,
  setNavigation: () => null,
  currentApplicationNavigation,
  setCurrentApplicationNavigation: () => null,
});

export default NavigationContext;
