import { useCallback, useState } from 'react';

import type { RunningJob } from '@core/types';
import useInterval from '@hooks/useInterval';
import useUntypedRequest from '@hooks/useUntypedRequest';
import { JobState } from '@snorkel/api/lib';
import type { UseRequestOptions } from '@snorkel/useRequest/types';

export type UsePollerProps = {
  initialValue?: RunningJob;
  onStopPolling?: () => void;
  onChangeStatus?: (value: RunningJob) => void;
  requestOptions?: UseRequestOptions;
};

export const DEFAULT_JOB_STATUS: RunningJob = {
  state: JobState.Running,
  failed: false,
  message: '',
  percent: 0,
  uid: '',
};

export const MOCKABLE_SETTINGS = {
  POLL_INTERVAL_MILLIS: 2000,
};

interface RequestParamArgs {
  url: string;
  shouldRetry: boolean;
}

const usePoller = ({
  initialValue,
  onChangeStatus,
  onStopPolling,
  requestOptions,
}: UsePollerProps) => {
  const [activeRequestParams, setActiveRequestParams] =
    useState<RequestParamArgs | null>(null);
  const [status, setStatus] = useState<RunningJob>(
    initialValue ?? DEFAULT_JOB_STATUS,
  );
  const request = useUntypedRequest();
  // Using `let` instead of `useState` to ensure element doesn't re-render every every time it's set
  let fetching = false;

  const stopPolling = () => {
    setActiveRequestParams(null);
    if (onStopPolling) onStopPolling();
  };

  const fetchData = async (requestParams: RequestParamArgs) => {
    fetching = true;
    const labelStatus = await request(requestParams.url, requestOptions);
    fetching = false;

    // The fetch failed, maybe because the internet connection was lost
    if (!labelStatus) {
      if (!requestParams.shouldRetry && !requestOptions?.hide500Error) {
        stopPolling();
      }

      return;
    }

    setStatus(labelStatus);

    if (onChangeStatus) {
      onChangeStatus(labelStatus);
    }

    // TODO: Remove the labelStatus.failed && labelStatus.complete condition when the studio endpoint is updated
    if (
      ('failed' in labelStatus && labelStatus.failed) ||
      ('complete' in labelStatus && labelStatus.complete) ||
      ('state' in labelStatus &&
        labelStatus.state &&
        labelStatus.state !== JobState.Running)
    ) {
      stopPolling();
    }
  };

  const fetchDataCallback = useCallback(
    (requestParams?: RequestParamArgs) => {
      if (fetching) return;
      const finalRequestParams = requestParams ?? activeRequestParams;
      if (!finalRequestParams || !finalRequestParams.url) return;
      fetchData(finalRequestParams);
    },
    // Ensures that fetchData is called if and when activeRequestParams is set
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeRequestParams, fetching],
  );

  useInterval(
    fetchDataCallback,
    activeRequestParams && !fetching
      ? MOCKABLE_SETTINGS.POLL_INTERVAL_MILLIS
      : null,
  );

  const startPolling = (
    endpoint: string,
    { retry }: { retry: boolean } = { retry: false },
  ) => {
    const requestParams = { url: endpoint, shouldRetry: retry };
    setActiveRequestParams(requestParams);
    fetchDataCallback(requestParams);
  };

  return { status, setStatus, startPolling, stopPolling };
};

export default usePoller;
