import { createContext } from 'react';

import type {
  JobProgressNotificationsContextType,
  JobProgressNotificationsState,
} from './types';

export const jobProgressNotificationsInitialState: JobProgressNotificationsState =
  [];

const jobProgressNotificationsInitialContext: JobProgressNotificationsContextType =
  {
    jobsInProgress: jobProgressNotificationsInitialState,
    dispatch: async () => {},
  };

const JobProgressNotificationsContext =
  createContext<JobProgressNotificationsContextType>(
    jobProgressNotificationsInitialContext,
  );

export default JobProgressNotificationsContext;
