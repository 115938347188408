export enum HighlightVariant {
  ANNOTATED_SPAN = 'annotatedSpan',
  CANDIDATE_SPAN = 'candidateSpan',
  HIGHLIGHTED = 'highlighted',
  HYPERLINK = 'hyperlink',
  CLF_LF_VOTES = 'classificationLFVotes',
  SEQUENCE_LF_VOTES = 'sequenceLFVotes',
  LIVE = 'live',
  MODEL_VOTES = 'sequenceModelVotes',
  TRAINING_SET_VOTES = 'sequenceTrainingSetVotes',
  SUPER_TEXT = 'superText',
}

export enum HighlightStatus {
  BLUR = 'blur',
  DEFAULT = 'default',
  FOCUS = 'focus',
  HOVER = 'hover',
  SNIPPET_BLUR = 'snippetBlur',
  PDF = 'pdf',
}

type LabelStyleProps = {
  color?: string;
  backgroundColor?: string;
};

export type HighlightConfigDetails = {
  description?: string[];
  label?: string;
  // eslint-disable-next-line camelcase
  raw_label?: number;
  title?: string;
  variant?: HighlightVariant;
  confidence?: number;
  type?: string; // Maps to FilterTypes
  start?: number;
  end?: number;
  annotatorId?: number;
};

export type HighlightBounds = {
  borderLeft?: boolean;
  borderRight?: boolean;
};

export interface HighlightConfig
  extends LabelStyleProps,
    HighlightConfigDetails,
    HighlightBounds {
  status?: HighlightStatus;
}

export default null;
