import { createContext } from 'react';

import type { UserMap, Users } from '@app/AdminPage/AdminSettings/types';

type UsersContextState = {
  users: Users;
  workspaceUsers: Users;
  workspaceUserMap: UserMap;
  setUsers: (users: Users) => void;
  setWorkspaceUsers: (users: Users) => void;
};

const UsersContext = createContext<UsersContextState>({
  users: [],
  workspaceUsers: [],
  workspaceUserMap: {},
  setUsers: () => null,
  setWorkspaceUsers: () => null,
});

export default UsersContext;
