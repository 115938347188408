import type { CommentMap, CommentType } from '@core/types';

const addComment =
  (x_uid: string, newComment: CommentType) => (comment: CommentMap) => {
    if (x_uid === comment.x_uid) {
      return {
        x_uid,
        comments: [...comment.comments, newComment],
      };
    }

    return comment;
  };

export default addComment;
