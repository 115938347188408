export const validateUid = (uid?: number | string | string[]) => {
  if (typeof uid !== 'string' && typeof uid !== 'number') {
    return undefined;
  }

  const parseUid = Number(uid);

  if (parseUid < 1 || Number.isNaN(parseUid)) {
    return undefined;
  }

  return parseUid;
};

export default null;
