import { isNil } from 'lodash';

import { DEFAULT_WORKSPACE_UID } from '@core/constants';
import { updateGlobalPreferencesStateAction } from '@global/PrivateRouteProviders/UserSettingsProvider/actions';
import useUserSettingsContext from '@global/PrivateRouteProviders/UserSettingsProvider/hooks/useUserSettingsContext';

type UseGlobalPreferencesReturnType = {
  createdByMeFilter: boolean;
  updateCreatedByMeFilter: (createdByMeFilter?: boolean) => Promise<void>;
  savedWorkspace: number;
  updateSavedWorkspace: (workspace: number) => Promise<void>;
  enableNotifications: boolean;
  updateEnableNotifications: (enableNotifications: boolean) => Promise<void>;
};

const useGlobalPreferences = (): UseGlobalPreferencesReturnType => {
  const {
    userSettings: { global_preferences } = {},
    setUserSettingsState,
    updateUserSettings,
  } = useUserSettingsContext();

  const {
    created_by_me_filter: createdByMeFilter,
    saved_workspace: savedWorkspace,
    enable_notifications: enableNotifications,
  } = global_preferences || {};

  const updateCreatedByMeFilter = async (createdByMeArg?: boolean) => {
    const nextValue = isNil(createdByMeArg)
      ? !createdByMeFilter
      : createdByMeArg;

    setUserSettingsState(
      updateGlobalPreferencesStateAction({ created_by_me_filter: nextValue }),
    );

    await updateUserSettings({
      global_preferences: {
        created_by_me_filter: nextValue,
      },
    });
  };

  const updateSavedWorkspace = async (workspace: number) => {
    setUserSettingsState(
      updateGlobalPreferencesStateAction({
        saved_workspace: workspace,
      }),
    );

    await updateUserSettings({
      global_preferences: {
        saved_workspace: workspace,
      },
    });
  };

  const updateEnableNotifications = async (isNotificationEnabled: boolean) => {
    setUserSettingsState(
      updateGlobalPreferencesStateAction({
        enable_notifications: isNotificationEnabled,
      }),
    );

    await updateUserSettings({
      global_preferences: {
        enable_notifications: isNotificationEnabled,
      },
    });
  };

  return {
    createdByMeFilter: createdByMeFilter ?? false,
    updateCreatedByMeFilter,
    savedWorkspace: savedWorkspace ?? DEFAULT_WORKSPACE_UID,
    updateSavedWorkspace,
    enableNotifications: enableNotifications ?? false,
    updateEnableNotifications,
  };
};

export default useGlobalPreferences;
