import { useAtom, useSetAtom } from 'jotai/react';
import uniqueId from 'lodash/uniqueId';

import { pdfZoomAtom } from '@app/StudioPage/components/DocumentMode/View/HocrFieldContainer/HocrField/store/atom';
import { isSidebarNavVisible } from '@global/store/sidebarNav/sidebarVisibleAtom';
import { DEFAULT_ZOOM } from '@snorkel/coral/prebuilts/ZoomControl/constants';

import NavigationBreadcrumb from './NavigationBreadcrumb';
import NavigationLogo from './NavigationLogo';

import NavigationSidebarFooter from '../NavigationSidebarFooter';
import NavigationSidebarNav from '../NavigationSidebarNav';

// need to push the nav 1px down to align with logo

const NavigationSidebar = () => {
  const setPDFZoom = useSetAtom(pdfZoomAtom);
  const [isSidebarVisible, setIsSidebarVisible] = useAtom(isSidebarNavVisible);

  const toggleVisibility = () => {
    setIsSidebarVisible(prev => !prev);

    // TODO: ENG-15945 -> Add back tests for zoom
    setPDFZoom(DEFAULT_ZOOM);
  };

  return (
    <>
      <div className="py-app absolute top-0 left-0 z-50 mb-4 flex h-toolbar items-center">
        <NavigationLogo
          isSidebarOpen={isSidebarVisible}
          toggleVisibility={toggleVisibility}
          // required for react to render things properly
          // due to sync issues with localStorage & hydration
          key={uniqueId()}
        />
        <div className="pl-app mt-px">
          <NavigationBreadcrumb />
        </div>
      </div>
      {isSidebarVisible && (
        <aside
          className="flex h-full w-50 min-w-50 max-w-50 flex-col border-r bg-white pt-0 text-xs"
          data-cy="sidebar-navigation"
        >
          <div className="h-toolbar opacity-0 " />
          <div className="flex flex-1 flex-col px-2">
            <NavigationSidebarNav />
            <NavigationSidebarFooter />
          </div>
        </aside>
      )}
    </>
  );
};

export default NavigationSidebar;
