import { createContext } from 'react';

export type AccessToken = {
  accessToken: string;
  expiry: number;
};

export type UseRequestContextState = {
  accessToken: AccessToken;
  debugMode: boolean;
  basePath: string;
};

export type UseRequestContextType = {
  accessToken: AccessToken;
  debugMode: boolean;
  basePath: string;
  setAccessToken: (accessToken: AccessToken) => void;
};

const initialContext: UseRequestContextType = {
  setAccessToken: () => {},
  accessToken: {
    accessToken: '',
    expiry: 0,
  },
  debugMode: false,
  basePath: '',
};

const UseRequestContext = createContext(initialContext);

export default UseRequestContext;
