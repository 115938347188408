type UrlComponents = {
  path: string;
  file?: string;
};

const getUrlComponents = (url: string): UrlComponents => {
  if (url === '/') return { path: '/' };

  const standardizedUrl = url.endsWith('/') ? url.slice(0, -1) : url;
  const pathSegments = standardizedUrl.split('/');
  const file = pathSegments[pathSegments.length - 1];
  const path = pathSegments.slice(0, -1).join('/');

  return file.includes('.') ? { path, file } : { path };
};

export default getUrlComponents;
