export enum TooltipBaseColors {
  white = 'white',
  black = 'black',
}

export enum TooltipArrowSizes {
  small = 'small',
  large = 'large',
}

export default null;
