import { calcBasePath } from '@utils/api/calcBasePath';

export const calcApiUrl = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  const { protocol, host } = window.location;
  const basePath = calcBasePath();

  const apiUrl = `${protocol}//${host}${basePath}/api`;

  return apiUrl;
};

export default null;
