/* eslint-disable react-hooks/exhaustive-deps */

import produce from 'immer';
import { useCallback } from 'react';

import { usePageRegionAlerts } from '@components/PageRegion';
import { getGlobalAccessToken } from '@global/AccessTokenManager';
import useAuthContext from '@hooks/useAuthContext';
import makeDisplayErrorMessage from '@hooks/useMakeOnRequestFailed/utils/makeDisplayErrorMessage';
import makeMakeOnRequestFailed from '@hooks/useMakeOnRequestFailed/utils/makeMakeOnRequestFailed';
import type { UseRequestOptions } from '@snorkel/useRequest/types';
import TIMEOUT_ERROR_MSG from '@snorkel/useRequest/utils/constants';
import generateDefaultHeaders from '@snorkel/useRequest/utils/generateDefaultHeaders';
import type { RequestError } from '@utils/api';
import type { Options } from '@utils/api/fetchFromApi';
import fetchFromApi from '@utils/api/fetchFromApi';
import type { RequestOptions } from '@utils/makeService';
import parseJWT from '@utils/parseJWTFromAccessToken';

// --------------------------------------------------------------------
const makeHeaders = headers => ({ ...generateDefaultHeaders(), ...headers });

const makeOptionsWithAuth = (
  options: Options,
  body,
  method,
  token,
): Options => {
  return produce(options, draft => {
    draft.headers = draft.headers ?? {};
    draft.body = body;
    draft.method = method;
    draft.signal = options.signal;

    if (token) {
      draft.headers.Authorization =
        draft.headers?.Authorization || `Bearer ${token}`;
    }
  });
};

const makeError = (
  result: Response,
  data,
  hasJsonError: boolean,
): RequestError => {
  let detailMsg: string | null = null;
  const {
    user_friendly_message: simpleMsg = '',
    detail,
    metadata,
  } = data ?? {};

  if (!hasJsonError) {
    detailMsg =
      typeof detail === 'string'
        ? detail
        : detail[0].msg || JSON.stringify(detail) || '';
  }

  const error: RequestError = Object.assign(new Error(), {
    response: result,
    type: 'api-error',
    hasJsonError,
    status: result.status,
    statusText: result.statusText,
    body: {
      user_friendly_message:
        result.status === 408 ? TIMEOUT_ERROR_MSG : simpleMsg,
      detail: detailMsg,
      metadata,
    },
  });

  return error;
};

const makeRequest = (
  token: string,
  setAccessToken: (string) => void,
  makeOnRequestFailed,
) => {
  const request = async <TResponseData = any>(
    requestData: string | RequestOptions,
    options: UseRequestOptions = {},
  ): Promise<TResponseData> => {
    options.headers = makeHeaders(options.headers);

    if (options.fallback && options.surfaceError) {
      const error =
        'You cannot use surfaceError and fallback at the same time!';

      console.error(error); // eslint-disable-line no-console
      throw new Error(`DEVELOPERS: ${error}`);
    }

    const { url, body, method } =
      typeof requestData === 'string'
        ? { url: requestData, body: options?.body, method: options?.method }
        : (requestData as RequestOptions);
    const handleRequestFailed = makeOnRequestFailed(options);

    try {
      const optionsWithAuth = makeOptionsWithAuth(options, body, method, token);
      const result = await fetchFromApi(url, optionsWithAuth);
      const accessToken = result.headers.get('Authorization')?.split(' ')[1];

      if (accessToken) {
        try {
          setAccessToken({ accessToken, expiry: parseJWT(accessToken).exp });
        } catch (e) {
          // do nothing
        }
      }

      let data;
      let hasJsonError = false;

      try {
        data = result.status === 204 ? null : await result.json();
      } catch (e) {
        hasJsonError = true;
      }

      if (result.status >= 400) {
        return handleRequestFailed(makeError(result, data, hasJsonError));
      }

      return options.onSuccess ? options.onSuccess(data) ?? data : data;
    } catch (e) {
      return handleRequestFailed(e as any);
    }
  };

  return request;
};

// --------------------------------------------------------------------
const noop = () => {};
const makeNoop = () => noop;

export const makeRequestWithoutErrorHandling = (
  token: string,
  setAccessToken: (string) => void,
) => {
  return makeRequest(token, setAccessToken, makeNoop);
};

// --------------------------------------------------------------------
/** @deprecated: Use typed request handlers */
const useUntypedRequest = () => {
  const { showErrorAlert } = usePageRegionAlerts();
  const { setAccessToken } = useAuthContext();

  const token = getGlobalAccessToken()!;

  return useCallback(
    makeRequest(
      token,
      setAccessToken,
      makeMakeOnRequestFailed(
        makeDisplayErrorMessage({
          showErrorAlert,
          // TODO(ENG-20695): Re-enable feature flag to hide error snackbars performantly
          shouldHideErrorSnackbars: false,
        }),
      ),
    ),
    [showErrorAlert, token, setAccessToken],
  );
};

export default useUntypedRequest;
