const keyPrefix = 'snorkel-';

export const clearStorage = () => {
  const excludeFromClear = `${keyPrefix}version`;

  Object.keys(localStorage)
    .filter(key => key !== excludeFromClear)
    .forEach(storageKey => localStorage.removeItem(storageKey));
};

export const removeItem = (key: string) => {
  window.localStorage.removeItem(`${keyPrefix}${key}`);
};

export const setItem = <T>(key: string, data: T) => {
  window.localStorage.setItem(`${keyPrefix}${key}`, JSON.stringify(data));
};

export const getItem = <T = any>(key: string, defaultValue: T): T => {
  try {
    const data = window.localStorage.getItem(`${keyPrefix}${key}`);

    return data !== null ? JSON.parse(data) : defaultValue;
  } catch {
    return defaultValue;
  }
};
