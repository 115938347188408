import Notification from './Notification';
import ProgressBarNotification from './ProgressBarNotification';

import useNotificationsDispatchersContext from '../hooks/useNotificationsDispatchersContext';
import useNotificationsStateContext from '../hooks/useNotificationsStateContext';
import type { NotificationId } from '../types';
import { NotificationDuration, NotificationVariant } from '../types';

const NotificationsContainer = () => {
  const notifications = useNotificationsStateContext();
  const { removeNotification } = useNotificationsDispatchersContext();

  const handleClose = (uid: NotificationId) => removeNotification(uid);

  const calcAutoHideDuration = (variant: NotificationVariant) => {
    return variant === NotificationVariant.error
      ? NotificationDuration.TIMEOUT
      : NotificationDuration.DEFAULT;
  };

  return notifications.length > 0 ? (
    <div className="fixed right-32 bottom-32 z-notifications flex w-112 flex-col">
      {notifications.map(({ uid, ...notification }) => {
        return notification.variant === NotificationVariant.progress ? (
          <ProgressBarNotification
            key={uid}
            notification={{ uid, ...notification }}
            onClose={handleClose}
          />
        ) : (
          <Notification
            key={uid}
            notification={{ uid, ...notification }}
            onClose={handleClose}
            autoHideDuration={calcAutoHideDuration(notification.variant)}
          />
        );
      })}
    </div>
  ) : null;
};

export default NotificationsContainer;
