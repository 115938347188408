import { ChipVariant } from '../types';

const getVariantClasses = (variant: ChipVariant) => {
  switch (variant) {
    case ChipVariant.Dashed:
      return 'border-black border-dashed';
    case ChipVariant.Highlight:
      return 'border-blue-500 bg-blue-100 hover:bg-blue-200';
    default:
      return '';
  }
};

export default getVariantClasses;
