import { DataFilterActionTypes } from './dataFilterConstants';
import type {
  DataFiltersState,
  SortOptions,
  ToggleShowAnnotationsActionType,
  UpdateDataFilterStateActionType,
  UpdateLimitActionType,
  UpdateSortOptionsActionType,
} from './types';

export const updateDataFilterAction = (
  state: Partial<DataFiltersState>,
): UpdateDataFilterStateActionType => {
  return {
    type: DataFilterActionTypes.UPDATE_DATA_FILTERS_STATE,
    data: state,
  };
};

export const toggleShowAnnotationsAction =
  (): ToggleShowAnnotationsActionType => ({
    type: DataFilterActionTypes.TOGGLE_SHOW_ANNOTATIONS,
  });

export const updateSortOptionsAction = (
  state: Partial<SortOptions>,
): UpdateSortOptionsActionType => ({
  type: DataFilterActionTypes.UPDATE_SORT_OPTIONS,
  data: state,
});

export const updateLimitAction = (limit: number): UpdateLimitActionType => ({
  type: DataFilterActionTypes.UPDATE_LIMIT,
  data: limit,
});
