import React from 'react';

import type { User } from '@app/AdminPage/AdminSettings/types';
import { GenericSizes } from '@components/types';
import UserProfilePicture from '@components/User/UserProfilePicture';

type UserWithProfileHorizontalProps = {
  user: User;
};

const UserWithProfileHorizontal = ({
  user: { username },
}: UserWithProfileHorizontalProps) => (
  <div className="flex w-full items-center gap-3">
    <UserProfilePicture size={GenericSizes.Small} name={username} />
    {username}
  </div>
);

export default UserWithProfileHorizontal;
