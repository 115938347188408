import { useAtomValue } from 'jotai/react';
import { isEmpty } from 'lodash';
import { useState } from 'react';

import type { Workspaces } from '@app/AdminPage/AdminSettings/types';
import { workspacesAtom } from '@global/store/workspaces/workspacesAtomStore';
import useAuthContext from '@hooks/useAuthContext';
import useRequest from '@hooks/useRequestWithLogging';
import type { Workspace } from '@snorkel/api/lib';
import { UserRole } from '@snorkel/api/lib';
import alphabeticalSort from '@utils/alphabeticalSort';
import { workspacesApi } from '@utils/api/serverRequests';

import useSetWorkspaces from './useSetWorkspaces';

const useGetWorkspaces = () => {
  const request = useRequest();
  const [loading, setLoading] = useState<boolean>(false);
  const { supported_features } = useAuthContext();
  const workspaces = useAtomValue(workspacesAtom);
  const { updateWorkspaces } = useSetWorkspaces();

  const getWorkspaces = async (
    existingWorkspaces: Workspaces = [],
    isAdmin?: boolean,
    signal?: AbortSignal,
  ) => {
    setLoading(true);

    const role = (() => {
      if (supported_features.admin_settings) {
        return [UserRole.Superadmin];
      }

      if (supported_features.workspace_admin_settings) {
        return [UserRole.Admin];
      }

      return undefined;
    })();

    const data = await request(
      workspacesApi.listWorkspacesWorkspacesGet,
      {
        signal,
      },
      undefined,
      isAdmin ? role : undefined,
    );

    setLoading(false);

    if (!isEmpty(data)) {
      const sortedWorkspaces = data.workspaces.toSorted(
        (a: Workspace, b: Workspace) => alphabeticalSort(a.name, b.name),
      );

      return {
        ...data,
        workspaces: sortedWorkspaces,
      };
    }

    return { workspaces: existingWorkspaces };
  };

  const getAdminWorkspaces = async (signal?: AbortSignal) => {
    return (await getWorkspaces([], true, signal)).workspaces || [];
  };

  const refreshWorkspaces = async (signal?: AbortSignal) => {
    const [nextWorkspaces, nextAdminWorkspaces] = await Promise.all([
      getWorkspaces(workspaces, undefined, signal),
      getAdminWorkspaces(signal),
    ]);
    updateWorkspaces(nextWorkspaces.workspaces, nextAdminWorkspaces);
  };

  return {
    loading,
    getWorkspaces,
    getAdminWorkspaces,
    refreshWorkspaces,
  };
};

export default useGetWorkspaces;
