import { useEffect, useRef } from 'react';

import { useDataStateContext } from '@hooks/useRootContext/useDataStateContext';
import { useLFsStateContext } from '@hooks/useRootContext/useLFsStateContext';
import { useTaskStateContext } from '@hooks/useRootContext/useTaskStateContext';

const ContextInspector = () => {
  const data = useDataStateContext();
  const lfs = useLFsStateContext();
  const task = useTaskStateContext();

  const rootState = { data, lfs, task };
  const refRootState = useRef<typeof rootState>(rootState);
  refRootState.current = rootState;

  useEffect(() => {
    (window as any).sf = (window as any).sf ?? {};
    (window as any).sf.getRootCtx = () => refRootState.current;

    return () => {
      (window as any).sf.getRootCtx = undefined;
    };
  }, []);

  return null;
};

export default ContextInspector;
