import { PublicRoutes } from '@core/constants';
import { licenseCheckApi } from '@utils/api/serverRequests';
import getIsLicenseValid from '@utils/auth/getIsLicenseValid';

type GetUnauthenticatedHomeRouteTestOptions = {
  disableUsers?: boolean;
};

const getUnauthenticatedHomeRoute = async (
  __testOptions: GetUnauthenticatedHomeRouteTestOptions = {},
) => {
  try {
    const isLicenseValid = await getIsLicenseValid();

    if (!isLicenseValid) {
      return PublicRoutes.ADMIN;
    }

    let currentUserCount = await licenseCheckApi
      .getLicenseInfoLicenseGet()
      .then(({ data }) => data)
      .then(({ current_user_count }) => current_user_count);

    if (__testOptions.disableUsers) {
      currentUserCount = 0;
    }

    if (currentUserCount === 0) {
      return PublicRoutes.ADMIN_CREATE_USER;
    }
  } catch (e: any) {
    return PublicRoutes.ADMIN;
  }

  return PublicRoutes.ADMIN_LOGIN;
};

export default getUnauthenticatedHomeRoute;
