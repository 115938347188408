export default {
  formControl: {
    left: '8px',
    top: '7px',
    zIndex: 2,
  },
  shrink: {
    left: 0,
    top: '-6px',
    transform: 'translate(0, 1.5px) scale(0.9)',
  },
};
