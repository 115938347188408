import useCachedRequest from '@hooks/useCachedRequest';
import type { LicenseKey } from '@snorkel/api/lib';
import { TrialType } from '@snorkel/api/lib';
import { licenseCheckApi } from '@utils/api/serverRequests';

/**
 *
 * @returns undefined while trial status is still loading, else boolean. True if trial type number 4, else false.
 */
const useCachedIsTrial = () => {
  // get trial info
  const { data } = useCachedRequest<boolean>(
    licenseCheckApi.getLicenseInfoLicenseGet,
    {
      onSuccess: (resp: LicenseKey): boolean =>
        Boolean(resp?.account_info?.trial === TrialType.NUMBER_4),
    },
  );

  return data;
};

export default useCachedIsTrial;
