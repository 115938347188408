/* eslint-disable local-rules/no-layering-violations */
import generateDefaultHeaders from '@snorkel/useRequest/utils/generateDefaultHeaders';

import { calcBasePath } from './calcBasePath';
import generateApiUrl from './generateApiUrl';

export type Options = {
  body?: any;
  method?: string;
  protocol?: string;
  headers?: Record<string, string>;
  host?: string;
  signal?: AbortSignal;
};

const fetchFromApi = async (
  path: string,
  options: Options = {},
): Promise<Response> => {
  const urlOptions = {
    protocol: options.protocol,
    host: options.host,
    basePath: calcBasePath(),
  };
  const baseUrl = generateApiUrl(urlOptions);

  const url = [baseUrl, path].filter(segment => segment.length > 0).join('/');

  const inferred_method = options.body ? 'POST' : 'GET';
  const init: RequestInit = {
    method: options.method ?? inferred_method,
    credentials: 'same-origin',
    ...(options.body && { body: JSON.stringify(options.body) }),
    headers: {
      ...generateDefaultHeaders(),
      ...(options.body && { 'Content-Type': 'application/json' }),
      ...(options.headers && options.headers),
    },
    signal: options.signal,
  };

  return fetch(url, init);
};

export default fetchFromApi;
