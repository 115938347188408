import { usePageRegionAlerts } from '@components/PageRegion';
import useNavigationContext from '@global/providers/NavigationProvider/hooks/useNavigationContext';
import SidebarMenu from '@global/providers/NavigationProvider/NavigationSidebarFooter/common/SidebarMenu';
import * as taskActionTypes from '@global/store/root/task/actionTypes';
import { useTaskDispatchContext } from '@hooks/useRootContext/useTaskDispatchContext';
import { useTaskStateContext } from '@hooks/useRootContext/useTaskStateContext';
import Icon, { Icons } from '@snorkel/coral/components/Icon';
import getBundledLogs from '@utils/downloadBundledLogs';

import SidebarMenuItem from '../common/SidebarMenuItem';

const ResourcesMenu = () => {
  const {
    meta: { objectStorageUrl },
  } = useNavigationContext();

  const { shortcutsModalOpen } = useTaskStateContext();

  const dispatch = useTaskDispatchContext();

  const toggleModal = () => {
    dispatch({
      type: taskActionTypes.setShortcutsOpen,
      data: !shortcutsModalOpen,
    });
  };

  const { showInfoAlert } = usePageRegionAlerts();

  const onClickDownloadLogs = async () => {
    showInfoAlert({
      message: 'Your download should start in a moment',
      origin: 'ResourcesMenu',
    });
    await getBundledLogs();
  };

  return (
    <SidebarMenu>
      <SidebarMenuItem onClick={toggleModal}>Shortcuts</SidebarMenuItem>
      <SidebarMenuItem onClick={onClickDownloadLogs}>
        Download Encrypted Logs
      </SidebarMenuItem>
      <SidebarMenuItem
        href={objectStorageUrl}
        aria-label="takes you to MinIO's web app on a separate tab"
      >
        MinIO object storage <Icon name={Icons.LAUNCH} className="ml-1" />
      </SidebarMenuItem>
    </SidebarMenu>
  );
};

export default ResourcesMenu;
