import type { RunningJob } from '@core/types';

export const initialInitializerStatus: RunningJob = {
  complete: false,
  failed: false,
  message: '',
  percent: 0,
  uuid: '',
  uid: '',
};

export default null;
