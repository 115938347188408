import React, { useReducer } from 'react';

import type { CommentsContextType } from './reducer';
import reducer, { CommentsContext, commentsInitialState } from './reducer';

type CommentsProps = {
  children: React.ReactNode;
  initialValue?: Partial<CommentsContextType>;
};

const CommentsProvider = ({ children, initialValue }: CommentsProps) => {
  const [state, dispatch] = useReducer(reducer, commentsInitialState);

  return (
    <CommentsContext.Provider value={{ dispatch, state, ...initialValue }}>
      {children}
    </CommentsContext.Provider>
  );
};

CommentsProvider.defaultProps = {
  initialValue: {},
};

export default CommentsProvider;
