import type { TooltipProps } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

interface Props extends TooltipProps {
  disabled?: boolean;
}

const CustomTooltip = ({ disabled, children, ...props }: Props) => (
  <Tooltip
    disableHoverListener={disabled}
    disableFocusListener={disabled}
    disableTouchListener={disabled}
    {...props}
  >
    {children}
  </Tooltip>
);

export default CustomTooltip;
