/* eslint-disable no-unused-expressions */
type CopyResponse = {
  success: boolean;
  error?: string;
};

const copyToClipboard = (text: string): CopyResponse => {
  const copyArea = document.createElement('span');

  try {
    const selection = document.getSelection();
    const range = document.createRange();
    selection?.removeAllRanges();

    copyArea.style.position = 'fixed';
    copyArea.style.top = '-999999px';
    copyArea.style.left = '-999999px';
    copyArea.style.width = '1px';
    copyArea.style.height = '1px';
    copyArea.style.whiteSpace = 'pre';
    copyArea.textContent = text;
    copyArea.addEventListener('copy', e => {
      e.stopPropagation();
      e.clipboardData?.clearData();
      e.clipboardData?.setData('', text);
    });

    document.body.appendChild(copyArea);
    range.selectNodeContents(copyArea);
    selection?.addRange(range);

    document.execCommand('copy');
  } catch (error: any) {
    return { success: false, error: error.message };
  } finally {
    document.body.removeChild(copyArea);
  }

  return { success: true };
};

export default copyToClipboard;
