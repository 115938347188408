import type { KeyboardEvent, MouseEvent } from 'react';

type A11yOptions = {
  preventDefault?: boolean;
  stopPropagation?: boolean;
};

const makeA11YClickEvents = (
  voidClickOperation: (
    e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
  ) => void,
  options?: A11yOptions,
) => ({
  onClick: (e: MouseEvent<HTMLElement>) => {
    if (options?.preventDefault) {
      e.preventDefault();
    }

    if (options?.stopPropagation) {
      e.stopPropagation();
    }

    voidClickOperation(e);
  },
  onKeyPress: (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      if (options?.preventDefault) {
        e.preventDefault();
      }

      if (options?.stopPropagation) {
        e.stopPropagation();
      }

      voidClickOperation(e);
    }
  },
  tabIndex: 0,
  role: 'button',
});

export default makeA11YClickEvents;
