import useGlobalPreferences from '@global/PrivateRouteProviders/UserSettingsProvider/hooks/useGlobalPreferences';
import useAuthContext from '@hooks/useAuthContext';
import useCachedRequest from '@hooks/useCachedRequest';
import useNodeId from '@hooks/useNodeId';
import useUserRole from '@hooks/useUserRole';
import type { JobState } from '@snorkel/api/lib';
import { jobsApi } from '@utils/api/serverRequests';

type UsePollAllJobsProps = {
  jobState?: JobState;
  limit?: number;
  shouldPoll?: boolean;
};

const POLLING_INTERVAL = 3_000;

const usePollAllJobs = ({
  jobState,
  limit,
  shouldPoll = true,
}: UsePollAllJobsProps) => {
  const { savedWorkspace } = useGlobalPreferences();
  const { user_uid: userId } = useAuthContext();
  const { isAdmin, isSuperAdmin } = useUserRole();
  const nodeId = useNodeId();

  const shouldAllowAccess = isAdmin || isSuperAdmin;

  const { data, isLoading, isValidating } = useCachedRequest(
    jobsApi.listJobsJobsGet,
    {
      suspendFetch: !nodeId || !shouldPoll,
      useSWROptions: {
        refreshInterval: shouldPoll ? POLLING_INTERVAL : 0,
      },
    },
    undefined,
    undefined,
    nodeId,
    savedWorkspace,
    jobState,
    undefined,
    undefined,
    limit,
    undefined,
    // don't gate admin/super-admin from viewing jobs
    shouldAllowAccess ? undefined : userId,
  );

  return { jobs: data?.jobs, isLoading: isLoading || !!isValidating };
};

export default usePollAllJobs;
