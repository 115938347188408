import { LayoutSpacing } from '../../layout/types';

enum GapSpacing {
  gap0 = 'gap-0',
  gap1 = 'gap-1',
  gap2 = 'gap-2',
  gap3 = 'gap-3',
  gap4 = 'gap-4',
  gap6 = 'gap-6',
  gap8 = 'gap-8',
  gap16 = 'gap-16',
  gap32 = 'gap-32',
}

const mapGapFromSpacing: Record<LayoutSpacing, GapSpacing> = {
  [LayoutSpacing.collapse]: GapSpacing.gap0,
  [LayoutSpacing.xs]: GapSpacing.gap1,
  [LayoutSpacing.s]: GapSpacing.gap2,
  [LayoutSpacing.default]: GapSpacing.gap3,
  [LayoutSpacing.l]: GapSpacing.gap4,
  [LayoutSpacing.xl]: GapSpacing.gap6,
  [LayoutSpacing['2xl']]: GapSpacing.gap8,
  [LayoutSpacing['3xl']]: GapSpacing.gap16,
  [LayoutSpacing['4xl']]: GapSpacing.gap32,
};

const generateGapFromSpacing = (
  layoutSpacing: LayoutSpacing,
): GapSpacing | '' => {
  return mapGapFromSpacing[layoutSpacing] ?? '';
};

export default generateGapFromSpacing;
