import React from 'react';
import type { TextProps } from './types';
import { TextColor, TextSize, Variant } from './types';
import Element from './utils/getElement';
import getTextColor from './utils/getTextColor';
import getTextSize from './utils/getTextSize';
import getTextSpacing from './utils/getTextSpacing';
import getTextWeight from './utils/getTextWeight';
import getTextHeight from './utils/getTextHeight';

const combineClasses = (...classes: Array<string | undefined>) =>
  classes.filter((className: string | undefined) => className).join(' ');

/** @deprecated Please use Text instead */
const Textv2 = ({
  children,
  size = TextSize.medium,
  variant = Variant.body,
  color = TextColor.DEFAULT,
  wrap = true,
  href,
  caps,
  className,
  prefixEl,
  postfixEl,
  ...rest
}: TextProps) => {
  return (
    <span
      className={combineClasses(
        href ? 'text-primary underline' : 'no-underline',
        size === TextSize.medium && variant === Variant.subtitle && caps
          ? 'uppercase'
          : '',
        getTextColor(color),
        getTextSize(variant, size),
        getTextWeight(variant, size),
        getTextSpacing(variant, size),
        getTextHeight(variant, size),
        !wrap ? 'whitespace-nowrap' : '',
        variant === Variant.mono ? 'font-mono' : 'font-regular',
        'inline-flex items-center gap-2',
        className,
      )}
      {...rest}
    >
      {prefixEl}
      {Element(variant, size, href)(children)}
      {postfixEl}
    </span>
  );
};

Textv2.defaultProps = {
  size: TextSize.medium,
  variant: Variant.body,
};

export * from './types';
export default Textv2;
