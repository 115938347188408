import Cookies from 'js-cookie';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useMemo, useState } from 'react';

import useCurrentUser from '@global/AuthContextProvider/hooks/useCurrentUser';
import type {
  CurrentUserSupportedFeatures,
  GetCurrentUserResponse,
} from '@snorkel/api/lib';
import { UserRole, UserView } from '@snorkel/api/lib';
import UseRequestProvider from '@snorkel/useRequest/UseRequestProvider';
import fetchFromApi from '@utils/api/fetchFromApi';
import { normalizeUrl } from '@utils/normalizeUrl';
import setWindowLocationHref from '@utils/setWindowLocationHref';

import {
  getGlobalAccessToken,
  setGlobalAccessToken,
} from '../AccessTokenManager';

const initialUser: GetCurrentUserResponse = {
  username: '',
  supported_features: {
    admin_settings: false,
    workspace_admin_settings: false,
    annotation_workspace: true,
    view_all_annotations: true,
    development_workspace: true,
  },
  user_uid: -1,
  role: UserRole.Standard,
  user_roles: [],
  default_view: UserView.Standard,
  timezone: '',
};

type AccessTokenOptions = {
  accessToken: string;
  expiry: number;
};

export type AuthContextType = GetCurrentUserResponse & {
  role: UserRole;
  token: string;
  setAccessToken: Dispatch<SetStateAction<AccessTokenOptions>>;
  logout: () => void;
  setTimezone: (timezone: string) => void;
  setSupportedFeatures: (
    supportedFeatures: CurrentUserSupportedFeatures,
  ) => void;
  setRole: (role: UserRole) => void;
  setIsLoggingOut: (isLoggingOut: boolean) => void;
  loggedIn: boolean;
  showAdminSettings: boolean;
  isLoggingOut: boolean;
  setUser: Dispatch<SetStateAction<GetCurrentUserResponse | undefined>>;
};

export const initialAuthContext: AuthContextType = {
  ...initialUser,
  token: '',
  setAccessToken: () => null,
  setTimezone: () => null,
  logout: () => null,
  setSupportedFeatures: () => null,
  setRole: () => null,
  setIsLoggingOut: () => null,
  setUser: () => null,
  loggedIn: false,
  showAdminSettings: false,
  isLoggingOut: false,
};

export const AuthContext = createContext(initialAuthContext);

type AuthContextProviderProps = PropsWithChildren<{
  accessToken: string;
  accessTokenExpiry: number;
  existingUser?: GetCurrentUserResponse;
  basePath: string;
}>;

const AuthContextProvider = ({
  children,
  accessToken: initToken,
  accessTokenExpiry: initAccessTokenExpiry,
  existingUser,
  basePath,
}: AuthContextProviderProps) => {
  const [{ accessToken, expiry }, setAccessToken] =
    useState<AccessTokenOptions>({
      accessToken: initToken,
      expiry: initAccessTokenExpiry,
    });

  const { user, setUser } = useCurrentUser(accessToken, expiry, existingUser);

  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  const logout = async () => {
    if (isLoggingOut) return;

    if (!!user?.user_uid || !!user) {
      setIsLoggingOut(true);

      setGlobalAccessToken(null);

      await fetch(normalizeUrl(`/jupyterhub/user/${user.username}/logout`), {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        redirect: 'manual',
      });

      await fetch(normalizeUrl('/jupyterhub/hub/logout'), {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        redirect: 'manual',
      });

      Cookies.remove(`jupyterhub-user-${user.username}`, {
        path: `/jupyterhub/user/${user.username}/`,
      });
      Cookies.remove('jupyterhub-session-id', { path: '/' });
      Cookies.remove('jupyterhub-hub-login', { path: '/jupyterhub/hub/' });

      setWindowLocationHref(normalizeUrl('/logout'));
    } else {
      setGlobalAccessToken(null);

      setWindowLocationHref(normalizeUrl('/logout'));
    }
  };

  const setTimezone = async (timezone: string) => {
    if (!user?.user_uid || !user) {
      return;
    }

    const resp = await fetchFromApi(`users/${user.user_uid}/timezone`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: 'PUT',
      body: { timezone },
    });
    const userUpdateDetails = await resp.json();

    if (resp) {
      setUser({
        ...user,
        timezone: userUpdateDetails.timezone || undefined,
      });
    }
  };

  const setSupportedFeatures = (
    supportedFeatures: CurrentUserSupportedFeatures,
  ) => {
    if (!user?.user_uid || !user) {
      return;
    }

    setUser({
      ...user,
      supported_features: supportedFeatures,
    });
  };

  const setRole = (role: UserRole) => {
    if (!user?.user_uid || !user) {
      return;
    }

    setUser({
      ...user,
      role,
    });
  };

  const loggedIn = accessToken !== '';

  const value: AuthContextType = useMemo(
    () => ({
      ...(user || initialUser),
      token: accessToken,
      logout,
      setAccessToken,
      setTimezone,
      setSupportedFeatures,
      setRole,
      setIsLoggingOut,
      setUser,
      loggedIn,
      isLoggingOut,
      showAdminSettings:
        user?.supported_features?.admin_settings ||
        user?.supported_features?.workspace_admin_settings ||
        false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(user), accessToken, loggedIn, isLoggingOut],
  );

  const globalAccessToken = getGlobalAccessToken();

  if (accessToken === '') {
    setGlobalAccessToken(null);
  } else if (!globalAccessToken || globalAccessToken !== accessToken) {
    setGlobalAccessToken(accessToken);
  }

  return (
    <AuthContext.Provider value={value}>
      <UseRequestProvider
        accessToken={{ accessToken, expiry }}
        debugMode
        basePath={basePath}
      >
        {children}
      </UseRequestProvider>
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
