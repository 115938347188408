import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }: PropsWithChildren<{}>) => {
  const el = useMemo(() => {
    return typeof document !== 'undefined'
      ? document.createElement('div')
      : null;
  }, [typeof document !== 'undefined']);

  useEffect(() => {
    if (!el) {
      return () => {};
    }

    document.body.appendChild(el);

    return () => {
      document.body.removeChild(el);
    };
  }, [el]);

  if (!el) {
    return null;
  }

  return createPortal(children, el);
};

export default Portal;
