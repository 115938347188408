import { useState } from 'react';

import Spinner from '@components/Spinner';
import useRequest from '@hooks/useRequestWithLogging';
import type { JobInfo } from '@snorkel/api/lib';
import { JobState } from '@snorkel/api/lib';
import Button, { ButtonSizes } from '@snorkel/coral/components/Button';
import { Icons } from '@snorkel/coral/components/Icon';
import ProgressBar from '@snorkel/coral/components/ProgressBar';
import Text from '@snorkel/coral/components/Text';
import IconButton from '@snorkel/coral/prebuilts/IconButton';
import { jobsApi, logsApi } from '@utils/api/serverRequests';
import convertToTimeAgo from '@utils/convertToTimeAgo';
import formatLogs from '@utils/formatLogs';
import { normalizeUrl } from '@utils/normalizeUrl';
import saveAsFile from '@utils/saveAsFile';

type JobItemProps = { job: JobInfo };

const JobItem = ({ job }: JobItemProps) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const request = useRequest();

  const handleJobCancel = async () => {
    await request(jobsApi.cancelJobJobsJobUidCancelPost, {}, job.uid);
  };

  const downloadLogs = async () => {
    setIsDownloading(true);
    const data = await request(
      logsApi.getJobLogsLogsJobJobIdGet,
      {},
      job.uid.substring(3),
    );

    const bodyText = formatLogs(data || '');
    saveAsFile(bodyText, `Snorkel_Flow_Logs_${job.uid}.txt`);
    setIsDownloading(false);
  };

  const shouldShowCancelButton = job.state === JobState.Running;

  const renderJobType = () => (
    <div className="flex w-3/12 flex-col gap-2 truncate">
      <span className="text-xs text-gray-600">Job type</span>
      <Text className="text-xs" truncate>
        {job.job_type}
      </Text>
    </div>
  );

  const renderStartedAt = () => (
    <div className="flex w-3/12 flex-col gap-2">
      <span className="text-xs text-gray-600">Started</span>
      <span className="text-xs">
        {convertToTimeAgo({
          time: job.enqueued_time,
          fallback: 'Not started',
        })}
      </span>
    </div>
  );

  const renderJobPercent = () => (
    <div className="flex items-center gap-2">
      <ProgressBar value={job.percent || 0} />
      <Text className="text-xs">{`${job.percent || 0}%`}</Text>
    </div>
  );

  const renderJobProgress = () => (
    <Text className="flex flex-1 text-xs">
      {job.message || `Job ${job.state}`}
    </Text>
  );

  const renderDownloadButton = () =>
    isDownloading ? (
      <div className="px-2">
        <Spinner />
      </div>
    ) : (
      <IconButton
        tooltip="Download logs"
        onClick={downloadLogs}
        icon={Icons.DOWNLOAD}
        className="flex-none"
      />
    );

  const renderCancelButton = () =>
    shouldShowCancelButton ? (
      <Button
        onClick={handleJobCancel}
        data-cy="job-cancel-button"
        size={ButtonSizes.small}
        filled={false}
      >
        Cancel
      </Button>
    ) : null;

  return (
    <li className="w-full list-none border" data-cy="job-item">
      <div className="flex items-center gap-2 px-8">
        <div
          className="my-2 flex w-full items-center gap-3"
          data-cy="job-close-state"
        >
          {renderJobType()}
          {renderStartedAt()}
          <div className="flex w-6/12 flex-col gap-2">
            {renderJobPercent()}
            <div className="flex items-center justify-between">
              {renderJobProgress()}
              <div className="flex items-center gap-2">
                <IconButton
                  tooltip="View raw logs"
                  href={normalizeUrl(`/jobs/${job.uid}/logs`)}
                  icon={Icons.LAUNCH}
                  className="flex-none"
                />
                {renderDownloadButton()}
                {renderCancelButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default JobItem;
