import getAllFlags from '@utils/getFlag/utils/getAllFlags';
import getFlagInMemoryOverride from '@utils/getFlag/utils/getFlagInMemoryOverride';

import type FlagsType from './flags';

const allFlags = getAllFlags();

const getFlag = (flag: FlagsType) => {
  return getFlagInMemoryOverride(flag) ?? allFlags[flag];
};

export * from './flags';
export default getFlag;
