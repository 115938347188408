import type { ApplicationDetails, NodeDetails } from '@app/Applications/types';
import type { Action } from '@core/types';

export type NodeDetailsState = {
  application: ApplicationDetails;
  node: NodeDetails;
};

export const nodeDetailsInitialState = {
  application: {} as ApplicationDetails,
  node: { node_config: {} } as NodeDetails,
};

const nodeDetailsReducer = (state: NodeDetailsState, action: Action) => {
  switch (action.type) {
    default:
      return {
        ...state,
        ...action.data,
      };
  }
};

export default nodeDetailsReducer;
