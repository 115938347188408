import type { HTMLAttributes, PropsWithChildren } from 'react';
import React from 'react';
import type {
  LayoutPadding,
  LayoutBorderColor,
  LayoutColor,
  LayoutTopPadding,
  LayoutBottomPadding,
  LayoutRightPadding,
  LayoutLeftPadding,
  LayoutBottomBorderColor,
  LayoutTopBorderColor,
  LayoutRightBorderColor,
  LayoutLeftBorderColor,
} from '../../types';
import {
  LayoutAlignment,
  LayoutPosition,
  LayoutSpacing,
  LayoutWidth,
  LayoutHeight,
} from '../../types';
import type {
  BorderProperty,
  HeightProperty,
  PaddingProperty,
  RoundedProperty,
  SpacingProperty,
  BackgroundColorProperty,
  WidthProperty,
  WrapProperty,
} from '../../../__internal__/primitives/types';
import combineClasses from '../../../utils/combineClasses';
import generatePadding from '../../../__internal__/utils/generatePadding';
import generateBorder from '../../../__internal__/utils/generateBorder';
import generateBackgroundColor from '../../../__internal__/utils/generateBackgroundColor';
import generateGapFromSpacing from '../../../__internal__/utils/generateGapFromSpacing';

type IntrinsicDisplayProperties = SpacingProperty<LayoutSpacing> &
  HeightProperty<LayoutHeight> &
  WidthProperty<LayoutWidth> &
  RoundedProperty &
  WrapProperty &
  BorderProperty<
    | LayoutBorderColor
    | LayoutBottomBorderColor
    | LayoutTopBorderColor
    | LayoutRightBorderColor
    | LayoutLeftBorderColor
  > &
  PaddingProperty<
    | LayoutPadding
    | LayoutTopPadding
    | LayoutBottomPadding
    | LayoutRightPadding
    | LayoutLeftPadding
  > &
  BackgroundColorProperty<LayoutColor>;

type _BaseStackPrimitiveProps = Partial<IntrinsicDisplayProperties> &
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

interface _StackPrimitiveProps extends _BaseStackPrimitiveProps {
  alignment?: LayoutAlignment;
  position?: LayoutPosition;
}

export type StackPrimitiveProps = Omit<
  Partial<_StackPrimitiveProps>,
  'className'
>;

const StackPrimitive = ({
  alignment = LayoutAlignment.START,
  position = LayoutPosition.START,
  spacing = LayoutSpacing.default,
  border = false,
  height = LayoutHeight.DEFAULT,
  width = LayoutWidth.DEFAULT,
  padding = false,
  rounded = false,
  wrap = false,
  backgroundColor = false,
  children,
  className = '',
  ...props
}: _StackPrimitiveProps) => {
  return (
    <div
      className={combineClasses(
        'flex',
        wrap ? 'flex-wrap' : '',
        alignment,
        position,
        width,
        height,
        rounded ? 'rounded' : '',
        className,
        generateGapFromSpacing(spacing),
        generatePadding(padding),
        generateBorder(border),
        generateBackgroundColor(backgroundColor),
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default StackPrimitive;
