import type { ThemeOptions } from '@material-ui/core/styles/createTheme';
import createMuiTheme from '@material-ui/core/styles/createTheme';

import overrides from './overrides';
import palette from './palette';
import typography from './typography';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    custom: {
      confirm: string;
    };
  }
}

function createMyTheme(options: ThemeOptions) {
  return createMuiTheme({
    ...options,
  });
}

export default createMyTheme({
  typography,
  palette,
  overrides,
});
