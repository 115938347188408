import { createContext } from 'react';

import type { NotificationDispatchers } from './dispatchers';
import type { NotificationsState } from './reducer';

const State = createContext<NotificationsState>([]);
const Dispatchers = createContext<NotificationDispatchers>({} as any);
const NotificationsContext = { State, Dispatchers };

export default NotificationsContext;
