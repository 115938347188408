import React, { useEffect } from 'react';

import useLogger from '@hooks/useLogger';
import Accordion from '@snorkel/coral/components/Accordion';
import Alert, { AlertTheme } from '@snorkel/coral/components/Alert';
import DeprecatedText, {
  TextSize,
  Variant,
} from '@snorkel/coral/components/DeprecatedText';
import ToolbarPlaceholder from '@snorkel/coral/layout/ToolbarPlaceholder';
import { normalizeUrl } from '@utils/normalizeUrl';

const Error = ({ statusCode, err }: any) => {
  const logger = useLogger();

  useEffect(() => {
    logger.error({ type: 'Error', error: err, trace: err?.trace });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [err]);

  if (statusCode === 404) {
    return (
      <div className="p-app flex h-screen flex-col">
        <ToolbarPlaceholder />
        <div className="flex flex-1 items-center justify-center text-center">
          <div>
            <div>
              <DeprecatedText variant={Variant.heading} size={TextSize.large}>
                404
              </DeprecatedText>
            </div>
            <div>
              <DeprecatedText>Page not found</DeprecatedText>
            </div>
            <div>
              <DeprecatedText href={normalizeUrl('/')}>
                Click here to go home
              </DeprecatedText>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const errMessage =
    statusCode === 500
      ? 'Cannot connect to Snorkel Flow API'
      : JSON.stringify(err);

  return (
    <div className="p-app w-dialog-content">
      <ToolbarPlaceholder />
      <Alert theme={AlertTheme.error}>
        <DeprecatedText size={TextSize.large}>
          Snorkel Flow has run into an unexpected error.
        </DeprecatedText>
      </Alert>
      <div className="mt-4">
        <DeprecatedText>
          Please note the error below to share with the support team if needed,
          then try reloading the page.
        </DeprecatedText>
      </div>
      <div className="m-w-1/2 my-4 border">
        <Accordion summary="Error message">
          <code>
            {errMessage === '{}' || !errMessage
              ? 'Unknown error has occurred'
              : errMessage}
          </code>
        </Accordion>
      </div>
    </div>
  );
};

Error.getInitialProps = ({ res, err }: any) => {
  // eslint-disable-next-line no-nested-ternary
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  return { statusCode, err };
};

export default Error;
