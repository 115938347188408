import type { GetCommentMapReq } from '@snorkel/api/lib';

export enum CommentsActions {
  SET_DATA_COMMENTS = 'SET_DATA_COMMENTS',
  SET_DOC_COMMENTS = 'SET_DOC_COMMENTS',
  ADD_DATA_COMMENT = 'ADD_DATA_COMMENT',
  ADD_DOC_COMMENT = 'ADD_DOC_COMMENT',
  REMOVE_DATA_COMMENT = 'REMOVE_DATA_COMMENT',
  REMOVE_DOC_COMMENT = 'REMOVE_DOC_COMMENT',
  EDIT_DATA_COMMENT = 'EDIT_DATA_COMMENT',
  EDIT_DOC_COMMENT = 'EDIT_DOC_COMMENT',
}

export const setDataCommentsAction = (comments: GetCommentMapReq) => ({
  type: CommentsActions.SET_DATA_COMMENTS,
  data: comments,
});

export default null;
