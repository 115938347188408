import { WorkspaceTypes } from '@app/StudioPage/types';
import useWorkspaceContext from '@global/PrivateRouteProviders/WorkspaceProvider/hooks/useWorkspaceContext';

const useIsAnnotationMode = (mode?: WorkspaceTypes): boolean => {
  const { workspaceType } = useWorkspaceContext();

  return (mode || workspaceType) === WorkspaceTypes.ANNOTATION;
};

export default useIsAnnotationMode;
