import { FONT_MONO } from '@core/constants';

export default {
  root: {
    marginBottom: '12px',
  },
  input: {
    '&::placeholder': {
      fontFamily: FONT_MONO,
    },
  },
};
