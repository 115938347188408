import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import type { ErrorAlertData } from '@components/PageRegion/Alert/types';
import PageRegionContent from '@components/PageRegion/PageRegionContent';
import type { PageRegionTag } from '@components/PageRegion/types';

type PageRegionFallbackProps = PropsWithChildren<{
  resetErrorBoundary: () => void;
  tag: PageRegionTag;
  error: Error;
  isWrappingPopUp?: boolean;
}>;

const calcErrorAlertDataFromError = (
  error: Error,
  region: PageRegionTag,
): ErrorAlertData => {
  return {
    message: error.message,
    origin: `page-region-${region}`,
  };
};

const PageRegionFallback = ({
  children,
  resetErrorBoundary,
  tag,
  error,
  isWrappingPopUp,
}: PageRegionFallbackProps) => {
  return (
    <PageRegionContent
      tag={tag}
      errorAlertData={calcErrorAlertDataFromError(error, tag)}
      resetError={resetErrorBoundary}
      isUncaughtError={!isEmpty(error.stack)}
      isWrappingPopUp={isWrappingPopUp}
    >
      {children}
    </PageRegionContent>
  );
};

export default memo(PageRegionFallback, isEqual);
