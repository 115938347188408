import type { Nominal } from '@snorkel/coral/types/nominal';

export type LFsActionType = Nominal<string, 'LFsAction'>;

export const updateLFState = 'lfs/update-lf-state' as LFsActionType;
export const addLFs = 'lfs/add-lfs' as LFsActionType;
export const addLF = 'lfs/add-lf' as LFsActionType;
export const setLFs = 'lfs/set-lfs' as LFsActionType;
export const removeLFs = 'lfs/remove-lfs' as LFsActionType;
export const updateLF = 'lfs/update-lf' as LFsActionType;
export const updateLFs = 'lfs/update-lfs' as LFsActionType;
export const clearLFs = 'lfs/clear-lfs' as LFsActionType;
export const setLFsLoading = 'lfs/set-lfs-loading' as LFsActionType;
export const setAutosuggestLFsPopulating =
  'lfs/set-autosuggest-lfs-populating' as LFsActionType;
export const setAutosuggestLFsJobId =
  'lfs/set-autosuggest-lfs-job-id' as LFsActionType;
export const setLFsLoadingProgress =
  'lfs/set-lfs-loading-progress' as LFsActionType;
export const setLFVotes = 'lfs/set-lf-votes' as LFsActionType;
export const resetLFMetrics = 'lfs/reset-lf-metrics' as LFsActionType;
