import useStudioInitializer from '@app/StudioPage/hooks/useStudioInitializer';
import { WorkspaceTypes } from '@app/StudioPage/types';
import { SplitType } from '@core/types';
import { useTaskStateContext } from '@hooks/useRootContext/useTaskStateContext';
import useSnorkelRouter from '@hooks/useSnorkelRouter';

const usePushNextNav = () => {
  const { split } = useTaskStateContext();
  const { setSplit } = useStudioInitializer();
  const router = useSnorkelRouter();

  const pushNextNav = async (
    setModeTo: WorkspaceTypes | undefined,
    href: string | undefined,
  ): Promise<void> => {
    if (!href) {
      return;
    }
    /**
     * Edge case: We do this check to avoid attempting to
     * load a train split batch upon navigating from a train split batch
     * to development pages
     */

    const shouldChangeSplitToDev =
      setModeTo === WorkspaceTypes.DEVELOPMENT && split === SplitType.TRAIN;

    if (shouldChangeSplitToDev) {
      setSplit(SplitType.DEV);
    }

    const route = {
      pathname: href,
      ...(shouldChangeSplitToDev && {
        query: { split: SplitType.DEV },
      }),
    };
    router.push(route);
  };

  return { pushNextNav };
};

export default usePushNextNav;
