import type { FlagOverrides } from '@utils/getFlag/types';
import getIsServerRendered from '@utils/getIsServerRendered';

/**
 * Returns flag status based on an override passed in flags config
 * @param flagOverrides
 */
const getFlagHostOverrides = (flagOverrides: FlagOverrides = {}) => {
  const overrides = flagOverrides;

  if (getIsServerRendered() || !overrides) {
    return null;
  }

  const currentHost = window.location.host;
  let foundOverride: boolean | null = null;

  Object.entries(overrides).forEach(([hostRegex, enabled]) => {
    if (new RegExp(hostRegex).test(currentHost)) {
      foundOverride = enabled;
    }
  });

  return foundOverride;
};

export default getFlagHostOverrides;
