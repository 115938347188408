import type { LabelColorObject as LabelColorForDatabase } from '@snorkel/api/lib';
import type { LabelColor } from '@snorkel/coral/utils/styles/labelColors';

const getCustomColorsForDatabase = (customColorArray: LabelColor[] = []) =>
  customColorArray.reduce(
    (
      acc: LabelColorForDatabase[],
      {
        labelTextColor: label_text_color,
        labelBackgroundColor: label_background_color,
      }: LabelColor,
    ) => {
      if (!(label_text_color && label_background_color)) {
        return acc;
      }

      return [
        ...acc,
        {
          label_text_color,
          label_background_color,
        },
      ];
    },
    [],
  );

export default getCustomColorsForDatabase;
