import type { Context } from 'react';
import { createContext } from 'react';

import { initialState } from '@global/store/root/lfs/reducer';
import type { LFsDispatch, LFsState } from '@global/store/root/lfs/types';

const initialDispatch: LFsDispatch = null as any;
const Dispatch: Context<LFsDispatch> = createContext(initialDispatch);

const State: Context<LFsState> = createContext(initialState);

const LFsContext = { Dispatch, State };

export default LFsContext;
