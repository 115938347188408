import { useEffect, useRef } from 'react';

import getIsServerRendered from '@utils/getIsServerRendered';

const APP_CACHE_KEY = 'app-cache';

const useLocalStorageProvider = () => {
  const isServerRendered = getIsServerRendered();

  // Restore data from localStorage
  const map: Map<string, any> = new Map(
    isServerRendered
      ? undefined
      : JSON.parse(localStorage.getItem(APP_CACHE_KEY) || '[]'),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const writeCacheToLocalStorage = () => {
    const appCache = JSON.stringify(Array.from(map.entries()));

    if (isServerRendered) {
      return;
    }

    try {
      localStorage.setItem(APP_CACHE_KEY, appCache);
    } catch (err) {
      localStorage.setItem(APP_CACHE_KEY, '[]');
    }
  };

  const cb = useRef(writeCacheToLocalStorage);

  useEffect(() => {
    cb.current = writeCacheToLocalStorage;
  }, [writeCacheToLocalStorage]);

  useEffect(() => {
    if (isServerRendered) {
      return () => {};
    }

    const onUnload = () => cb.current?.();

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { localStorageProvider: () => map };
};

export default useLocalStorageProvider;
