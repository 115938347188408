import type { PaddingType } from '../primitives/types';
import {
  PaddingPrimitive,
  PaddingBottomPrimitive,
  PaddingLeftPrimitive,
  PaddingRightPrimitive,
  PaddingTopPrimitive,
} from '../primitives/types';

type Padding = PaddingType<
  | PaddingPrimitive
  | PaddingBottomPrimitive
  | PaddingLeftPrimitive
  | PaddingRightPrimitive
  | PaddingTopPrimitive
>;

type GeneratePadding = (padding: Padding) => string;

const getPaddingFromStringOrBool = (
  padding: boolean | string,
  defaultPadding: Padding,
): string => {
  if (!padding) {
    return 'p-0';
  }

  return `${typeof padding === 'boolean' ? defaultPadding : padding}`;
};

const generatePadding: GeneratePadding = (padding: Padding): string => {
  const isPaddingABoolean = typeof padding === 'boolean';
  const isPaddingAString = typeof padding === 'string';

  if (isPaddingABoolean || isPaddingAString) {
    return getPaddingFromStringOrBool(padding, PaddingPrimitive.default);
  }

  const constructPadding: string[] = [];

  if (padding.top) {
    constructPadding.push(
      getPaddingFromStringOrBool(padding.top, PaddingTopPrimitive.default),
    );
  }

  if (padding.left) {
    constructPadding.push(
      getPaddingFromStringOrBool(padding.left, PaddingLeftPrimitive.default),
    );
  }

  if (padding.right) {
    constructPadding.push(
      getPaddingFromStringOrBool(padding.right, PaddingRightPrimitive.default),
    );
  }

  if (padding.bottom) {
    constructPadding.push(
      getPaddingFromStringOrBool(
        padding.bottom,
        PaddingBottomPrimitive.default,
      ),
    );
  }

  return constructPadding.join(' ');
};

export default generatePadding;
