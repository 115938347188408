import useCandidateContext from '@app/StudioPage/hooks/useCandidateContext';
import useCurrentBatchUid from '@app/StudioPage/providers/AnnotationProvider/hooks/useCurrentBatchUid';
import useDataFiltersDispatchContext from '@app/StudioPage/providers/DataFiltersContext/hooks/useDataFiltersDispatchContext';
import { updateDataFilterAction } from '@app/StudioPage/providers/DataFiltersContext/utils/dataFilterActions';
import useDocFiltersDispatchContext from '@app/StudioPage/providers/DocFiltersContext/hooks/useDocFiltersDispatchContext';
import { updateDocFilterAction } from '@app/StudioPage/providers/DocFiltersContext/utils/docFilterActions';
import { initialInitializerStatus } from '@app/StudioPage/providers/StudioInitializationProvider/constants';
import useStudioInitializationContext from '@app/StudioPage/providers/StudioInitializationProvider/hooks/useStudioInitializationContext';
import { usePageRegionAlerts } from '@components/PageRegion';
import type { SplitType } from '@core/types';
import * as dataActionTypes from '@global/store/root/data/actionTypes';
import * as lfsActionTypes from '@global/store/root/lfs/actionTypes';
import * as taskActionTypes from '@global/store/root/task/actionTypes';
import useNodeRequest from '@hooks/useNodeRequest';
import usePoller from '@hooks/usePoller';
import { useDataDispatchContext } from '@hooks/useRootContext/useDataDispatchContext';
import { useLFsDispatchContext } from '@hooks/useRootContext/useLFsDispatchContext';
import { useTaskDispatchContext } from '@hooks/useRootContext/useTaskDispatchContext';
import { useTaskStateContext } from '@hooks/useRootContext/useTaskStateContext';
import { getSplitLocalStorageKey } from '@hooks/useUpdateInitialSplit';
import { setItem } from '@utils/localStorage';

const useStudioInitializer = () => {
  const dataDispatch = useDataDispatchContext();
  const lfsDispatch = useLFsDispatchContext();
  const { name } = useTaskStateContext();
  const nodeId: number = name;

  const taskDispatch = useTaskDispatchContext();

  const { showErrorAlert } = usePageRegionAlerts();

  const setStudioSplit = (newSplit: SplitType) => {
    taskDispatch({ type: taskActionTypes.changeSplit, data: newSplit });
    setItem(getSplitLocalStorageKey(nodeId), newSplit);
  };

  const { setInitialized, setStatus } = useStudioInitializationContext();
  const dataFiltersDispatch = useDataFiltersDispatchContext();
  const docFiltersDispatch = useDocFiltersDispatchContext();
  const { setSelected, setContextIndex } = useCandidateContext();

  const { startPolling, stopPolling } = usePoller({
    onChangeStatus: setStatus,
  });
  const nodeRequest = useNodeRequest();
  const batchUid = useCurrentBatchUid();

  const startInitializing = async (nextSplit: SplitType) => {
    const resp = await nodeRequest(`splits/${nextSplit}/load-dataset`, {
      method: 'POST',
      body: {
        batchUid,
      },
    });

    const { job_id: jobId } = resp ?? {};

    if (!jobId) {
      showErrorAlert({
        message: 'Failed to initialize data',
        origin: 'useStudioInitializer',
      });

      return;
    }

    startPolling(
      `nodes/${nodeId}/splits/${nextSplit}/studio-loading-jobs/${jobId}`,
    );
  };

  const resetDataviewerState = () => {
    dataFiltersDispatch(
      updateDataFilterAction({
        page: 0,
      }),
    );
    docFiltersDispatch(
      updateDocFilterAction({
        page: 0,
      }),
    );
    setSelected([]);
    setContextIndex(0);
    dataDispatch({ type: dataActionTypes.setSelectedIndex, data: 0 });
  };

  const setSplit = async (newSplit: SplitType) => {
    lfsDispatch({ type: lfsActionTypes.resetLFMetrics });
    taskDispatch({ type: taskActionTypes.resetSummary, data: null });
    setInitialized(false);
    resetDataviewerState();
    setStatus(initialInitializerStatus);
    startInitializing(newSplit);
    setStudioSplit(newSplit);
  };

  return { setSplit, stopPolling, startInitializing };
};

export default useStudioInitializer;
