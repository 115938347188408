import { useEffect } from 'react';

import useNavigationContext from '@global/providers/NavigationProvider/hooks/useNavigationContext';
import useGetObjectStorageUrl from '@global/providers/NavigationProvider/NavigationSidebarFooter/ResourcesMenu/hooks/useGetObjectStorageUrl';

const useSetObjectStorageUrlToNavMeta = () => {
  const { meta, setNavigationMetaData } = useNavigationContext();
  const { getObjectStorageUrl } = useGetObjectStorageUrl();

  const setObjectStorageUrl = async () => {
    const objectStorageUrl = await getObjectStorageUrl();
    if (meta.objectStorageUrl === objectStorageUrl) return;

    setNavigationMetaData(prevMeta => ({ ...prevMeta, objectStorageUrl }));
  };

  useEffect(() => {
    setObjectStorageUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.objectStorageUrl]);
};

export default useSetObjectStorageUrlToNavMeta;
