export enum DataFilterActionTypes {
  UPDATE_DATA_FILTERS_STATE = 'UPDATE_DATA_FILTERS_STATE',
  UPDATE_SORT_OPTIONS = 'UPDATE_SORT_OPTIONS',
  UPDATE_LIMIT = 'UPDATE_LIMIT',
  TOGGLE_SHOW_ANNOTATIONS = 'TOGGLE_SHOW_ANNOTATIONS',
}

export const { TOGGLE_SHOW_ANNOTATIONS } = DataFilterActionTypes;
export const { UPDATE_DATA_FILTERS_STATE } = DataFilterActionTypes;
export const { UPDATE_SORT_OPTIONS } = DataFilterActionTypes;
export const { UPDATE_LIMIT } = DataFilterActionTypes;
