/* eslint-disable local-rules/require-data-cy */
import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';

import type { ButtonProps } from '@components/Form/DeprecatedButton';
import Button from '@components/Form/DeprecatedButton';

interface DialogButtonProps extends ButtonProps {
  onClose?: Function;
  buttonText: string;
  isLoadingOverride?: boolean;
  buttonLoadingText?: string;
  buttonDataCy?: string;
  handleConfirm?: Function;
  syncHandleConfirm?: Function;
  disabled?: boolean;
}

export type DialogActionButtonProps = Omit<DialogButtonProps, 'children'>;

const DialogActionButtons = ({
  buttonText,
  buttonDataCy,
  onClose,
  isLoadingOverride,
  buttonLoadingText,
  disabled,
  syncHandleConfirm,
  handleConfirm,
  ...otherProps
}: DialogActionButtonProps): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);

  const onClickButton = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (e) e.stopPropagation();

    // only syncHandleConfirm or handleConfirm can be invoked
    if (syncHandleConfirm) {
      syncHandleConfirm();

      return;
    }

    if (!handleConfirm) {
      return;
    }

    setLoading(true);
    await handleConfirm();

    setLoading(false);

    if (e) {
      if (onClose) {
        onClose(e);
      }

      return;
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <Button
      type="submit"
      onClick={onClickButton}
      color="primary"
      variant="contained"
      disabled={disabled}
      autoFocus
      dataCy={buttonDataCy}
      {...otherProps}
    >
      {isLoadingOverride || loading ? (
        <>
          {buttonLoadingText || buttonText}
          <CircularProgress size="1rem" color="inherit" className="ml-3" />
        </>
      ) : (
        buttonText
      )}
    </Button>
  );
};

export default DialogActionButtons;
