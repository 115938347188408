import Router from 'next/router';

const getRouterQuery = () => {
  const { router } = Router;
  const { query = {} } = router ?? {};

  return query;
};

export default getRouterQuery;
