import { useState } from 'react';

const useDialogControl = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleDialogClose = (e?: any) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    setDialogOpen(false);
  };

  const handleDialogOpen = (e?: any) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    setDialogOpen(true);
  };

  return {
    dialogOpen,
    handleDialogClose,
    handleDialogOpen,
  };
};

export default useDialogControl;
