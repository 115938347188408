import type { HTMLAttributes, ReactText, ReactElement } from 'react';

export enum TextSize {
  xsmall = 'xsmall',
  small = 'small',
  main = 'main',
  large = 'large',
}

export enum Variant {
  body = 'body',
  heading = 'heading',
  mono = 'mono',
}

export interface TextProps extends HTMLAttributes<HTMLElement> {
  children?: ReactText;
  size: TextSize;
  variant: Variant;
  href?: string;
  'data-cy'?: string;
  postfixEl?: ReactElement;
  prefixEl?: ReactElement;
}

export default null;
