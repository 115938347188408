import { TextSize, Variant } from '../types';

const getTextWeight = (variant: Variant, size: TextSize) => {
  switch (variant) {
    case Variant.heading:
      switch (size) {
        case TextSize.xsmall:
          return 'font-semibold';
        case TextSize.small:
          return 'font-semibold';
        case TextSize.main:
          return 'font-normal';
        case TextSize.large:
          return 'font-light';
        default:
          return 'font-normal';
      }
    case Variant.body: // falls through
    case Variant.mono: // falls through

    default:
      switch (size) {
        case TextSize.xsmall:
          return 'font-medium';
        case TextSize.small:
          return 'font-normal';
        case TextSize.main:
          return 'font-normal';
        case TextSize.large:
          return 'font-normal';
        default:
          return 'font-normal';
      }
  }
};

export default getTextWeight;
