import type { BorderType } from '../primitives/types';
import {
  BorderColorPrimitive,
  BorderTopColorPrimitive,
  BorderLeftColorPrimitive,
  BorderRightColorPrimitive,
  BorderBottomColorPrimitive,
} from '../primitives/types';

type Border = BorderType<
  | BorderColorPrimitive
  | BorderBottomColorPrimitive
  | BorderTopColorPrimitive
  | BorderLeftColorPrimitive
  | BorderRightColorPrimitive
>;

type GenerateBorder = (padding: Border) => string;

const getBorderFromBooleanOrString = (
  border: boolean | string,
  defaultBorder: Border,
): string => {
  if (!border) {
    return 'border-0';
  }

  return `${typeof border === 'boolean' ? defaultBorder : border}`;
};

const generateBorder: GenerateBorder = (border: Border): string => {
  if (!border) {
    return '';
  }

  const isPaddingABoolean = typeof border === 'boolean';
  const isPaddingAString = typeof border === 'string';

  if (isPaddingABoolean || isPaddingAString) {
    return `border ${getBorderFromBooleanOrString(
      border,
      BorderColorPrimitive.DEFAULT,
    )}`;
  }

  const constructPadding: string[] = [];

  if (border.top) {
    constructPadding.push(
      `border-t ${getBorderFromBooleanOrString(
        border.top,
        BorderTopColorPrimitive.DEFAULT,
      )}`,
    );
  }

  if (border.left) {
    constructPadding.push(
      `border-l ${getBorderFromBooleanOrString(
        border.left,
        BorderLeftColorPrimitive.DEFAULT,
      )}`,
    );
  }

  if (border.right) {
    constructPadding.push(
      `border-r ${getBorderFromBooleanOrString(
        border.right,
        BorderRightColorPrimitive.DEFAULT,
      )}`,
    );
  }

  if (border.bottom) {
    constructPadding.push(
      `border-b ${getBorderFromBooleanOrString(
        border.bottom,
        BorderBottomColorPrimitive.DEFAULT,
      )}`,
    );
  }

  return constructPadding.join(' ');
};

export default generateBorder;
