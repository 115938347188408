export default {
  root: {
    height: '32px',
    borderRadius: '18px',
    padding: '0 16px',
    boxShadow: 'none',
    minWidth: 0,
    textTransform: 'inherit' as const,
    fontSize: 14,
  },
  contained: {
    boxShadow: 'none',
  },
  text: {
    padding: '0 16px',
  },
  outlined: {
    padding: '0, 16px',
  },
  iconSizeSmall: {
    marginRight: '4px',
  },
};
