import { DocFilterActionTypes } from './docFilterConstants';
import type { DocFiltersState, UpdateDocFilterStateActionType } from './types';

export type DocFiltersAction = UpdateDocFilterStateActionType;

export const updateDocFilterAction = (
  state: Partial<DocFiltersState>,
): UpdateDocFilterStateActionType => ({
  type: DocFilterActionTypes.UPDATE_DOC_FILTERS_STATE,
  data: state,
});
