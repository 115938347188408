/**
 * Directly using tailwind.css for this
 */
export enum LayoutPosition {
  START = 'justify-start',
  END = 'justify-end',
  CENTER = 'justify-center',
  BETWEEN = 'justify-between',
  NORMAL = 'normal',
}

export enum LayoutAlignment {
  START = 'items-start',
  END = 'items-end',
  CENTER = 'items-center',
  BETWEEN = 'items-between',
  NORMAL = 'normal',
  STRETCH = 'items-stretch',
}

export { PaddingPrimitive as LayoutPadding } from '../__internal__/primitives/types';
export { PaddingTopPrimitive as LayoutTopPadding } from '../__internal__/primitives/types';
export { PaddingLeftPrimitive as LayoutLeftPadding } from '../__internal__/primitives/types';
export { PaddingRightPrimitive as LayoutRightPadding } from '../__internal__/primitives/types';
export { PaddingBottomPrimitive as LayoutBottomPadding } from '../__internal__/primitives/types';
export { SpacingPrimitive as LayoutSpacing } from '../__internal__/primitives/types';
export { FilledPrimitive as LayoutColor } from '../__internal__/primitives/types';
export { HeightPrimitive as LayoutHeight } from '../__internal__/primitives/types';
export { WidthPrimitive as LayoutWidth } from '../__internal__/primitives/types';
export { BorderColorPrimitive as LayoutBorderColor } from '../__internal__/primitives/types';
export { BorderTopColorPrimitive as LayoutTopBorderColor } from '../__internal__/primitives/types';
export { BorderBottomColorPrimitive as LayoutBottomBorderColor } from '../__internal__/primitives/types';
export { BorderLeftColorPrimitive as LayoutLeftBorderColor } from '../__internal__/primitives/types';
export { BorderRightColorPrimitive as LayoutRightBorderColor } from '../__internal__/primitives/types';

export default null;
