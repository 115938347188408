import type { AxiosResponse } from 'axios';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import useRequest from '@hooks/useRequestWithLogging';
import cacheKeyMiddleware from '@utils/requestCaching/cacheKeyMiddleware';
import type {
  CachedData,
  MutateArgs,
  UseCachedRequestOptions,
  UseCachedRequestResult,
} from '@utils/requestCaching/types';
import { mutatorOptions } from '@utils/requestCaching/types';

type ExtendedSWROptions<TCustom> = SWRConfiguration<
  CachedData<TCustom, any>
> & { cacheKey?: string };

const useCachedRequest = <
  TCustom = undefined,
  TArgs extends any[] = any[],
  TResponse extends Promise<AxiosResponse<any>> = Promise<AxiosResponse<any>>,
>(
  openApiPromise: (...args: TArgs) => TResponse,
  options: UseCachedRequestOptions<CachedData<TCustom, TResponse>>,
  ...args: TArgs
): UseCachedRequestResult<TCustom, TResponse> => {
  const { useSWROptions, suspendFetch, onMutate, ...requestOptions } =
    options ?? {};

  const request = useRequest();

  const { data, error, isValidating, isLoading, mutate } = useSWR<
    CachedData<TCustom, TResponse>
  >(
    suspendFetch ? null : [openApiPromise, requestOptions, args],
    () => request(openApiPromise, requestOptions, ...args),
    {
      fallbackData: requestOptions?.fallback,
      use: [cacheKeyMiddleware],
      ...(useSWROptions ?? {}),
    } as ExtendedSWROptions<TCustom>,
  );

  const boundUpdateThenRevalidate = async (
    mutateArgs?: MutateArgs<CachedData<TCustom, TResponse>>,
  ) => {
    const { optimisticData, onMutateArgs } = mutateArgs ?? {};

    const mutateOptions = {
      ...mutatorOptions,
      optimisticData,
    };

    if (onMutate) {
      const result = onMutate(onMutateArgs);
      mutate(result, mutateOptions);

      return;
    }

    mutate(optimisticData, mutateOptions);
  };

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate: boundUpdateThenRevalidate,
  };
};

export default useCachedRequest;
