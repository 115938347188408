import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import type { PropsWithChildren } from 'react';
import React from 'react';
import { OverlayElevation } from '../../../layout/Overlay/types';

interface TooltipContentProps {
  side?: TooltipPrimitive.TooltipContentProps['side'];
}

export type TooltipProps = PropsWithChildren<{
  title: React.ReactNode;
  className?: string;
  open?: boolean;
  tooltipContentProps?: TooltipContentProps;
  asChild?: TooltipPrimitive.TooltipTriggerProps['asChild'];
}>;

const DEFAULT_DELAY_DURATION = 0;

// TODO: https://snorkelai.atlassian.net/browse/ENG-15666 is a cleanup ticket for this component
const Tooltip = ({
  children,
  title,
  className,
  open,
  tooltipContentProps,
  asChild = true,
}: TooltipProps) =>
  title ? (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root open={open} delayDuration={DEFAULT_DELAY_DURATION}>
        <TooltipPrimitive.Trigger asChild={asChild}>
          {children}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content
          sideOffset={4}
          className={`rounded bg-black p-3 shadow-sm ${
            OverlayElevation.TOOLTIP
          } ${className || ''}`}
          avoidCollisions
          {...tooltipContentProps}
          data-cy="tooltip-content"
        >
          <TooltipPrimitive.Arrow width={8} height={5} className="fill-black" />
          <span className="text-white">{title}</span>
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  ) : (
    <>{children}</>
  );

export default Tooltip;
