import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { getGlobalAccessToken } from '@global/AccessTokenManager';
import { notificationsAtom } from '@global/store/notifications/notificationsAtomStore';
import { userChannelWebsocketAtom } from '@global/store/websockets';
import generateAPIUrl from '@snorkel/useRequest/utils/generateAPIUrl';
import formatProtocolForWebSocket from '@utils/websocket/formatProtocolForWebSocket';

import type {
  WebSocketChannel,
  WebSocketMessage,
  WebSocketUserChannel,
} from './types';

const isWebSocketUserChannel = (
  channel: WebSocketChannel,
): channel is WebSocketUserChannel => {
  return channel.startsWith('user:');
};

const useWebSocketNotifications = () => {
  const [isError, setIsError] = useState<boolean>(false);
  const [channel, setChannel] = useState<WebSocketChannel | null>(null);

  const setNotifications = useSetAtom(notificationsAtom);
  const setUserChannelWebsocket = useSetAtom(userChannelWebsocketAtom);

  const initializeNotificationWebSocket = (
    channelToInitialize: WebSocketChannel,
  ) => {
    setChannel(channelToInitialize);
  };

  /**
   * Implements the basic WebSocket WebAPI interface
   *
   * @effect - create a new client websocket connection to the flow-ui node server
   * @deps channel - the channel to connect to
   */
  useEffect(() => {
    if (!channel) return undefined;

    // Construct a URL to the flow-ui node server
    const baseUrl = generateAPIUrl();
    const baseUrlForWebsocket = formatProtocolForWebSocket(baseUrl);
    const urlForWebsocket = `${baseUrlForWebsocket}/ws/${channel}?token=${getGlobalAccessToken()}`;
    const ws = new WebSocket(urlForWebsocket);

    if (isWebSocketUserChannel(channel)) {
      setUserChannelWebsocket(ws);
    }

    ws.onmessage = event => {
      const webSocketMessage: WebSocketMessage = JSON.parse(event.data);
      const notifFromWebSocket = webSocketMessage.message;
      setNotifications(prevNotifications => [
        ...prevNotifications,
        notifFromWebSocket,
      ]);
    };

    ws.onerror = () => {
      setIsError(true);
    };

    return () => {
      // Cleanup on unmount if ws wasn't closed already
      if (ws?.readyState !== WebSocket.CLOSED) {
        ws?.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return { initializeNotificationWebSocket, isError };
};

export default useWebSocketNotifications;
