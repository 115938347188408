import { useAtomValue } from 'jotai/react';
import { useMemo, useState } from 'react';

import MenuButton from '@components/Form/MenuButton';
import Dialog from '@components/Modals/Dialog';
import useGlobalPreferences from '@global/PrivateRouteProviders/UserSettingsProvider/hooks/useGlobalPreferences';
import useNavigationContext from '@global/providers/NavigationProvider/hooks/useNavigationContext';
import JobsDialog from '@global/providers/NavigationProvider/NavigationSidebarNav/JobsDialog';
import NavList from '@global/providers/NavigationProvider/NavigationSidebarNav/NavList';
import calcParentPaths from '@global/providers/NavigationProvider/NavigationSidebarNav/utils/calcParentPaths';
import { notificationsAtom } from '@global/store/notifications/notificationsAtomStore';
import {
  selectedWorkspaceAtom,
  workspacesAtom,
} from '@global/store/workspaces/workspacesAtomStore';
import useDialogControl from '@hooks/useDialogControl';
import useSetWorkspaces from '@hooks/useSetWorkspaces';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import type { Workspace } from '@snorkel/api/lib';
import Divider from '@snorkel/coral/components/Divider';
import Icon, { Icons } from '@snorkel/coral/components/Icon';

import CurrentApp from './CurrentApp';
import NotebookItem from './NotebookItem';
import NotificationsMenu from './NotificationMenu';

import NavigationButton from '../common/NavigationButton';
import { ROUTES_SUPPORTING_WORKSPACE_SWITCHING } from '../NavigationSidebarFooter';
import WorkspacesMenu from '../NavigationSidebarFooter/WorkspacesMenu';

const NavigationSidebarNav = () => {
  const { navigation, currentApplicationNavigation } = useNavigationContext();
  const { updateSelectedWorkspace } = useSetWorkspaces();
  const { enableNotifications } = useGlobalPreferences();

  const workspaces = useAtomValue(workspacesAtom);
  const selectedWorkspace = useAtomValue(selectedWorkspaceAtom);
  const router = useSnorkelRouter();
  const { handleDialogOpen, handleDialogClose, dialogOpen } =
    useDialogControl();

  const { asPath } = useSnorkelRouter();
  const path = asPath.split('?')[0];

  const [tempSelectedWorkspace, setTempSelectedWorkspace] =
    useState<Workspace | null>(null);

  const notifications = useAtomValue(notificationsAtom);

  const onWorkspaceChange = (nextWorkspace: Workspace) => {
    const route = router.asPath.split('/').join('');

    if (
      ROUTES_SUPPORTING_WORKSPACE_SWITCHING.some(
        allowedRoute => allowedRoute === route,
      )
    ) {
      setTempSelectedWorkspace(nextWorkspace);
      updateSelectedWorkspace(nextWorkspace);

      return;
    }

    setTempSelectedWorkspace(nextWorkspace);
    handleDialogOpen();
  };

  const goToHome = () => {
    const index = workspaces.findIndex(
      ({ workspace_uid }) =>
        tempSelectedWorkspace!.workspace_uid === workspace_uid,
    );

    if (index !== -1) {
      updateSelectedWorkspace(workspaces[index]);
      router.push('/');
    }
  };

  const openPaths = useMemo(
    () =>
      calcParentPaths({
        navItems: [...navigation, ...currentApplicationNavigation],
        targetHref: path,
      }),
    [navigation, currentApplicationNavigation, path],
  );

  return (
    <>
      <div className="my-2" />

      {enableNotifications ? (
        <MenuButton
          menuProps={{
            elevation: 0,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            transformOrigin: { vertical: 'top', horizontal: 'right' },
          }}
          menu={<NotificationsMenu />}
          button={
            <NavigationButton
              leftIcon={Icons.NOTIFICATION}
              selected={notifications?.length > 0}
              isNotification
            >
              <span
                data-cy="notifications-menu-button"
                className="flex-1 truncate"
              >
                Notifications
              </span>
              <Icon name={Icons.CHEVRON__DOWN} />
            </NavigationButton>
          }
        />
      ) : null}

      <Dialog
        title={`Switch workspace${
          tempSelectedWorkspace?.name
            ? ` to ${tempSelectedWorkspace?.name}`
            : ''
        }`}
        message="Switching workspaces will take you to the applications page for that workspace and exit the current application"
        open={dialogOpen}
        handleClose={handleDialogClose}
        actionButtonProps={{
          buttonText: 'Confirm',
          handleConfirm: goToHome,
          buttonDataCy: 'confirm-workspace-switch',
        }}
      />
      <div className="flex-1">
        <MenuButton
          menuProps={{
            elevation: 0,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            transformOrigin: { vertical: 'top', horizontal: 'right' },
          }}
          menu={
            <WorkspacesMenu
              workspaces={workspaces}
              onSelectWorkspace={onWorkspaceChange}
            />
          }
          button={
            <NavigationButton
              dataCy="workspaces-navigation-button"
              leftIcon={Icons.NOMINAL}
            >
              <span className="flex-1 truncate whitespace-pre">
                {selectedWorkspace?.name}
              </span>

              <Icon name={Icons.CHEVRON__DOWN} />
            </NavigationButton>
          }
        />
        <Divider className="my-4" />
        <NavList
          child={{ data: navigation, loading: false, tabbed: false }}
          openPaths={openPaths}
        />
        <NotebookItem />
        <CurrentApp data={currentApplicationNavigation} openPaths={openPaths} />
        <JobsDialog />
      </div>
    </>
  );
};

export default NavigationSidebarNav;
