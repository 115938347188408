import NotebookModalButton from '@components/NotebookModalButton';
import NavigationButton from '@global/providers/NavigationProvider/common/NavigationButton';
import useCachedIsTrial from '@hooks/useCachedIsTrial';
import { Icons } from '@snorkel/coral/components/Icon';

import ListItem from './NavList/ListItem';
import getListItemClass from './NavList/utils/getListItemClass';

const NotebookItem = () => {
  const isTrial = useCachedIsTrial();

  return (
    <ListItem role="listitem" className={getListItemClass({ selected: false })}>
      <NotebookModalButton
        button={({ onClick }: any) => (
          <NavigationButton
            onClick={onClick}
            disabled={isTrial}
            leftIcon={Icons.NOTEBOOK}
          >
            <span className="flex-1 truncate">Notebook </span>
          </NavigationButton>
        )}
      />
    </ListItem>
  );
};

export default NotebookItem;
