import type { HTMLAttributes, ReactText, ReactElement } from 'react';
import { TextColorPrimitive as TextColor } from '../../__internal__/primitives/types';

export enum TextSize {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum Variant {
  body = 'body',
  headline = 'headline',
  subtitle = 'subtitle',
  mono = 'mono',
  button = 'button',
}

export interface TextProps extends Omit<HTMLAttributes<HTMLElement>, 'color'> {
  children?: ReactText;
  size?: TextSize;
  color?: TextColor;
  variant?: Variant;
  href?: string;
  caps?: boolean;
  prefixEl?: ReactElement;
  postfixEl?: ReactElement;
  wrap?: boolean;
}

export { TextColor };

export default null;
