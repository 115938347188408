import getUID from '@utils/getUID';

import type {
  NotificationAction,
  NotificationId,
  NotificationItem,
  OptionalUid,
  ProgressNotificationProps,
} from '../types';
import { NotificationActionType } from '../types';

const calcDefaultNotificationId = (): NotificationId =>
  String(getUID()) as NotificationId;

export const queueNotification = ({
  uid,
  ...notification
}: OptionalUid<NotificationItem>): NotificationAction<NotificationItem> => ({
  type: NotificationActionType.queueNotification,
  payload: {
    ...notification,
    uid: uid ?? calcDefaultNotificationId(),
  } as NotificationItem,
});

export const removeNotification = (
  uid: NotificationId,
): NotificationAction<NotificationId> => ({
  type: NotificationActionType.removeNotification,
  payload: uid,
});

export const updateProgressNotification = (
  uid: NotificationId,
  progressNotification: Omit<ProgressNotificationProps, 'uid'>,
): NotificationAction<ProgressNotificationProps> => ({
  type: NotificationActionType.updateProgressNotification,
  payload: { ...progressNotification, uid },
});

export default null;
