import { usePageRegionAlerts } from '@components/PageRegion';
import type { RunningJob } from '@core/types';

import getJobFailureNotificationMetaData from './utils/getJobFailureNotificationMetadata';

const useJobFailureNotification = () => {
  const { showErrorAlert } = usePageRegionAlerts();

  const jobFailureNotification = ({ message = '', detail }: RunningJob) => {
    showErrorAlert({
      message,
      metaData: getJobFailureNotificationMetaData(detail),
      origin: 'useJobFailureNotification',
    });
  };

  return { jobFailureNotification };
};

export default useJobFailureNotification;
