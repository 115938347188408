import { TextSize, Variant } from '../types';

const getTextSize = (variant: Variant, size: TextSize) => {
  switch (variant) {
    case Variant.headline:
      switch (size) {
        case TextSize.large:
          return 'text-3xl'; // 1.875 rem
        case TextSize.medium:
          return 'text-2xl'; // 1.5rem
        case TextSize.small:
          return 'text-xl'; // 1.25rem
        default:
          throw Error('not valid size for headline');
      }
    case Variant.subtitle:
      switch (size) {
        case TextSize.large:
          return 'text-base'; // 1rem
        case TextSize.medium:
          return 'text-sm'; // 0.875rem
        case TextSize.small:
          return 'text-sm'; // 0.875rem
        default:
          throw Error('not valid size for subtitle');
      }
    case Variant.body:
      switch (size) {
        case TextSize.large:
          return 'text-base';
        case TextSize.medium:
          return 'text-sm';
        case TextSize.small:
          return 'text-xs';
        case TextSize.xsmall:
          return 'text-xxs';
        default:
          throw Error('not valid size for body');
      }
    case Variant.mono:
      switch (size) {
        case TextSize.large:
          return 'text-sm';
        case TextSize.medium:
          return 'text-xs';
        case TextSize.small:
          return 'text-xxs';
        default:
          throw Error('not valid size for mono');
      }
    case Variant.button:
      return 'text-button';

    default:
      switch (size) {
        case TextSize.large:
          return 'text-base';
        case TextSize.medium:
          return 'text-sm';
        case TextSize.small:
          return 'text-xs';
        case TextSize.xsmall:
          return 'text-xxs';
        default:
          return 'text-sm';
      }
  }
};

export default getTextSize;
