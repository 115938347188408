import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { RunningJob } from '@core/types';

import { initialInitializerStatus } from './constants';

export type StudioInitializationContextState = {
  initialized: boolean;
  setInitialized: Dispatch<SetStateAction<boolean>>;
  status: RunningJob;
  setStatus: Dispatch<SetStateAction<RunningJob>>;
  fastModelTrainOnReload: boolean;
  setFastModelTrainOnReload: Dispatch<SetStateAction<boolean>>;
};

export const initialContext = {
  initialized: false,
  setInitialized: () => null,
  status: initialInitializerStatus,
  setStatus: () => null,
  fastModelTrainOnReload: false,
  setFastModelTrainOnReload: () => null,
};

const StudioInitializationContext =
  createContext<StudioInitializationContextState>(initialContext);

export default StudioInitializationContext;
