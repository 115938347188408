import * as Sentry from '@sentry/nextjs';
import type { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import PageRegionFallback from '@components/PageRegion/PageRegionFallback';
import type { CreateFSEventFnType } from '@hooks/useFullstoryEvent';
import useFullstoryEvent from '@hooks/useFullstoryEvent';
import type { Logger } from '@hooks/useLogger';
import useLogger from '@hooks/useLogger';

import AlertProvider from './Alert/AlertProvider';
import PageRegionContainer from './PageRegionContainer';
import type { PageRegionTag } from './types';

type PageRegionProps = PropsWithChildren<{
  tag: PageRegionTag;
  wrapperClasses?: string;
  isWrappingPopUp?: boolean;
}>;

const level = 'error';

const logError = (
  logger: Logger,
  createFSEvent: CreateFSEventFnType,
  region: PageRegionTag,
  error: Error,
): void => {
  const { message } = error;
  Sentry.captureException({ ...error, level, region });
  const fsEventInfo = { message, type: 'uncaught-exception', level, region };
  createFSEvent(`uncaught-error`, fsEventInfo);
  logger.error({ message, level, region });
  // eslint-disable-next-line no-console
  console.warn('PageRegion Exception =>', { message, level, region });
};

const PageRegion = ({
  tag,
  children,
  wrapperClasses,
  isWrappingPopUp,
}: PageRegionProps) => {
  const logger = useLogger();
  const { createFSEvent } = useFullstoryEvent(); // Add Custom Fullstory Events logging snackbar notifications

  return (
    <AlertProvider tag={tag}>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <PageRegionFallback
            error={error}
            resetErrorBoundary={resetErrorBoundary}
            tag={tag}
            isWrappingPopUp={isWrappingPopUp}
          />
        )}
        onError={error => logError(logger, createFSEvent, tag, error)}
      >
        <PageRegionContainer tag={tag} wrapperClasses={wrapperClasses}>
          {children}
        </PageRegionContainer>
      </ErrorBoundary>
    </AlertProvider>
  );
};

export default PageRegion;
