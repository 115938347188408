import { SplitType } from '@core/types';

const shouldDisableSplit = (
  splitOption: string | string[] | undefined,
  taskSplits: string[],
) => {
  if (typeof splitOption !== 'string') return true;

  if (splitOption === SplitType.DEV && taskSplits.includes(SplitType.TRAIN)) {
    return false;
  }

  return !taskSplits.includes(splitOption);
};

export default shouldDisableSplit;
