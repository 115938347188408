import type {
  NotificationAction,
  NotificationId,
  NotificationItem,
} from '../types';
import { NotificationActionType } from '../types';

export type NotificationsState = NotificationItem[];
export const MAX_NOTIFICATIONS_TO_RENDER = 1;

export const initialState: NotificationsState = [];

const handleQueueNotification = (
  state: NotificationsState,
  { payload }: NotificationAction<NotificationItem>,
): NotificationsState => {
  const currentLength = state.length;

  return currentLength < MAX_NOTIFICATIONS_TO_RENDER
    ? [...state, payload]
    : [
        ...state.slice(currentLength - MAX_NOTIFICATIONS_TO_RENDER + 1),
        payload,
      ];
};

const handleRemoveNotification = (
  state: NotificationsState,
  { payload }: NotificationAction<NotificationId>,
): NotificationsState => {
  return state.filter(({ uid }: NotificationItem) => uid !== payload);
};

const handleUpdateProgressNotification = (
  state: NotificationsState,
  { payload }: NotificationAction<NotificationItem>,
): NotificationsState => {
  const { uid, ...notificationData } = payload;

  return state.map(notification => ({
    ...notification,
    ...(notification.uid === uid ? notificationData : {}),
  }));
};

const handlers = {
  [NotificationActionType.queueNotification]: handleQueueNotification,
  [NotificationActionType.removeNotification]: handleRemoveNotification,
  [NotificationActionType.updateProgressNotification]:
    handleUpdateProgressNotification,
};

const notificationsReducer = (
  state: NotificationsState = initialState,
  action: NotificationAction<any>,
) => {
  const handler = handlers[action?.type];

  return handler ? handler(state, action) : state;
};

export default notificationsReducer;
