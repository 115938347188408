import type { ReactNode } from 'react';
import React from 'react';

type DialogFooterProps = {
  children: ReactNode;
  hideBorder?: boolean;
};

const DialogFooter = ({ children, hideBorder }: DialogFooterProps) => {
  return (
    <div
      className={`mx-4 flex pt-4 pb-6 ${
        hideBorder ? '' : 'border-t'
      } justify-end`}
    >
      {children}
    </div>
  );
};

export default DialogFooter;
