import { AlertTheme } from '../types';

const getAlertColors = (theme: AlertTheme) => {
  switch (theme) {
    case AlertTheme.error:
      return 'bg-red-100 text-red-800 border-red-600';
    case AlertTheme.success:
      return 'bg-green-100 text-green-800 border-green-600';
    case AlertTheme.warning:
      return 'bg-orange-100 text-orange-800 border-orange-600';
    default:
      return 'bg-gray-100 border-gray-600';
  }
};

export default getAlertColors;
