import { useEffect } from 'react';

const useSetTimeout = (fn: Function, duration: number = 0) => {
  useEffect(() => {
    if (duration === 0) return undefined;

    const timer = setTimeout(fn, duration);

    return () => clearTimeout(timer);
  }, [fn, duration]);
};

export default useSetTimeout;
