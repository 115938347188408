/* eslint-disable local-rules/require-data-cy */
import type { ButtonHTMLAttributes, ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';

import SnorkelLink from '@components/SnorkelLink';

export type FooterItemContentProps = {
  onClick?: VoidFunction;
  href?: string;
  openInNewTab?: boolean;
  children: ReactNode;
  className?: string;
} & (
  | ButtonHTMLAttributes<HTMLButtonElement>
  | ButtonHTMLAttributes<HTMLAnchorElement>
);

const FooterItemContent = forwardRef(
  (
    {
      href,
      openInNewTab = true,
      children,
      onClick,
      ...props
    }: FooterItemContentProps,
    forwardedRef: ForwardedRef<HTMLLIElement>,
  ) => {
    const { className } = props;

    return (
      <li className="w-full list-none" ref={forwardedRef}>
        {href ? (
          <SnorkelLink href={href}>
            <a
              {...(props as ButtonHTMLAttributes<HTMLAnchorElement>)}
              target={openInNewTab ? '_blank' : ''}
              className={`block w-full ${className || ''}`}
            >
              {children}
            </a>
          </SnorkelLink>
        ) : (
          <button
            {...(props as ButtonHTMLAttributes<HTMLButtonElement>)}
            onClick={onClick}
            type="button"
            className={`text-left ${className || ''}`}
          >
            {children}
          </button>
        )}
      </li>
    );
  },
);

export default FooterItemContent;
