export enum HeightSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
  slim = 'slim',
}

const getHeightClass = (size: HeightSizes) => {
  switch (size) {
    case HeightSizes.small: {
      return 'h-6';
    }
    case HeightSizes.medium: {
      return 'h-8';
    }
    case HeightSizes.large: {
      return 'h-10';
    }

    default: {
      return '';
    }
  }
};

export default getHeightClass;
