import type { ContextTag, DataTagMap, TagType } from '@core/types';

export enum TagsActions {
  SET_AVAILABLE_TAGS = 'SET_AVAILABLE_TAGS',
  SET_DATA_TAGS = 'SET_DATA_TAGS',
  SET_DOC_DATA_TAGS = 'SET_DOC_DATA_TAGS',
  SET_CONTEXT_TAGS = 'SET_CONTEXT_TAGS',
  SET_DOC_DATA_AND_DATA_TAGS = 'SET_DOC_DATA_AND_DATA_TAGS',
  SET_OPEN_TAGS_MENU = 'SET_OPEN_TAGS_MENU',
  SET_OPEN_SUGGEST_LF_MENU = 'SET_OPEN_SUGGEST_LF_MENU',
}

export const setAvailableTagsAction = (tags: TagType[]) => ({
  type: TagsActions.SET_AVAILABLE_TAGS,
  data: tags,
});

export const setDataTagsAction = (tags: DataTagMap[]) => ({
  type: TagsActions.SET_DATA_TAGS,
  data: tags,
});

export const setDocDataTagsAction = (tags: DataTagMap[]) => ({
  type: TagsActions.SET_DOC_DATA_TAGS,
  data: tags,
});

export const setContextTagsAction = (tags: ContextTag[]) => ({
  type: TagsActions.SET_CONTEXT_TAGS,
  data: tags,
});

export const setOpenTagsMenuAction = (nextOpen: boolean) => ({
  type: TagsActions.SET_OPEN_TAGS_MENU,
  data: nextOpen,
});

export const setOpenSuggestLFAction = (nextOpen: boolean, tagId: number) => ({
  type: TagsActions.SET_OPEN_SUGGEST_LF_MENU,
  data: { nextOpen, tagId },
});

type SetDocDataAndTagActionArgs = {
  docDataTags: DataTagMap[];
  dataTags: DataTagMap[];
};

export const setDocDataAndDataTagsAction = (
  data: SetDocDataAndTagActionArgs,
) => ({ type: TagsActions.SET_DOC_DATA_AND_DATA_TAGS, data });

export default null;
