import React, { forwardRef } from 'react';
import InputContainer from '../InputContainer';
import { InputSizes } from '../InputContainer/types';
import type { InputProps } from './types';
import getInputClassName from './utils/getInputClassName';
import combineClasses from '../../utils/combineClasses';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      size = InputSizes.large,
      fullWidth,
      infoText,
      error,
      warning,
      prefixEl,
      suffixEl,
      className,
      tooltipTitle,
      variant = 'default',
      ...props
    }: InputProps,
    ref,
  ) => {
    const { id, disabled } = props;

    const getInputWrapperClassNames = () =>
      combineClasses(
        'flex',
        'gap-2',
        'items-center',
        'focus-within:border-primary focus-within:border-2',
        error
          ? 'border-2 focus-within:border-2 focus-within:border-red-700'
          : '',
        getInputClassName({
          className,
          size,
          error,
          variant,
        }),
      );

    return (
      <InputContainer
        size={size}
        fullWidth={fullWidth}
        label={label}
        infoText={infoText}
        id={id}
        disabled={disabled}
        error={error}
        warning={warning}
        tooltipTitle={tooltipTitle}
      >
        <div data-cy="input-wrapper" className={getInputWrapperClassNames()}>
          {prefixEl}
          <input
            {...props}
            // if we need to make the background transparent, set the color on
            // <InputWrapper /> not here
            className="w-full border-none bg-transparent px-0 text-sm focus:outline-none"
            ref={ref}
          />
          {suffixEl}
        </div>
      </InputContainer>
    );
  },
);

export default Input;
