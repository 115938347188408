import { uniqBy } from 'lodash';
import type { Dispatch } from 'react';
import { createContext } from 'react';

import type { Action, CommentMap } from '@core/types';
import addComment from '@utils/comments/addComment';
import editComment from '@utils/comments/editComment';
import removeComment from '@utils/comments/removeComment';

import { CommentsActions } from './actions';

export type CommentsReducerState = {
  dataComments: CommentMap[];
  docComments: CommentMap[];
};

export type CommentsContextType = {
  state: CommentsReducerState;
  dispatch: Dispatch<Action>;
};

export const commentsInitialState: CommentsReducerState = {
  dataComments: [],
  docComments: [],
};

const initialContext: CommentsContextType = {
  state: commentsInitialState,
  dispatch: () => null,
};

export const CommentsContext = createContext(initialContext);

const commentsReducer = (
  state: CommentsReducerState = commentsInitialState,
  action: Action,
) => {
  switch (action.type) {
    case CommentsActions.SET_DATA_COMMENTS: {
      const dataComments = uniqBy(
        [...action.data, ...state.dataComments],
        'x_uid',
      );

      return {
        ...state,
        dataComments: dataComments as CommentMap[],
      };
    }
    case CommentsActions.ADD_DATA_COMMENT: {
      const { x_uid, comment } = action.data;

      return {
        ...state,
        dataComments: state.dataComments.map(addComment(x_uid, comment)),
      };
    }
    case CommentsActions.REMOVE_DATA_COMMENT: {
      const { comment } = action.data;

      return {
        ...state,
        dataComments: state.dataComments.map(removeComment(comment)),
      };
    }
    case CommentsActions.EDIT_DATA_COMMENT: {
      const { comment } = action.data;

      return {
        ...state,
        dataComments: state.dataComments.map(editComment(comment)),
      };
    }
    case CommentsActions.SET_DOC_COMMENTS: {
      return {
        ...state,
        docComments: action.data,
      };
    }
    case CommentsActions.ADD_DOC_COMMENT: {
      const { x_uid, comment } = action.data;

      return {
        ...state,
        docComments: state.docComments.map(addComment(x_uid, comment)),
      };
    }
    case CommentsActions.REMOVE_DOC_COMMENT: {
      const { comment } = action.data;

      return {
        ...state,
        docComments: state.docComments.map(removeComment(comment)),
      };
    }
    case CommentsActions.EDIT_DOC_COMMENT: {
      const { comment } = action.data;

      return {
        ...state,
        docComments: state.docComments.map(editComment(comment)),
      };
    }

    default: {
      return state;
    }
  }
};

export default commentsReducer;
