import { ButtonThemes } from '../types';

const getBorderFromTheme = (theme: ButtonThemes): string => {
  switch (theme) {
    case ButtonThemes.primary:
      return 'border-primary';
    case ButtonThemes.secondary:
      return 'border-secondary';
    default:
      return 'border-gray-border';
  }
};

const getBorder = (
  border: boolean,
  theme: ButtonThemes,
  disabled?: boolean,
): string => {
  if (!border || disabled) {
    return 'border border-transparent';
  }

  return `border ${getBorderFromTheme(theme)}`;
};

export default getBorder;
