import type { CommentMap, CommentType } from '@core/types';

const removeComment =
  ({ x_uid, comment_uid }: CommentType) =>
  (comment: CommentMap) => {
    const { comments } = comment;
    if (x_uid === comment.x_uid)
      return {
        x_uid,
        comments: comments.filter(
          ({ comment_uid: cUid }: CommentType) => comment_uid !== cUid,
        ),
      };

    return comment;
  };

export default removeComment;
