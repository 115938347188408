/* eslint-disable local-rules/no-layering-violations */
import { DEFAULT_JOBS_LIMIT } from '@core/constants';
import type { JobInfo } from '@snorkel/api/lib';
import { JobState } from '@snorkel/api/lib';
import { jobsApi } from '@utils/api/serverRequests';
import { LOCK_TO_DATASOURCES_JOB } from '@utils/isPageGatedByRunningJob/constants';

const isPageGatedByRunningJob = async (path: string, accessToken: string) => {
  try {
    if (path.includes('/datasources')) {
      return false;
    }

    const match = path.match(/\/task\/([0-9]+)\//);

    if (!(match && match[1])) {
      return false;
    }

    const taskId = match[1];

    const jobList: JobInfo[] = await jobsApi
      .listJobsJobsGet(
        undefined,
        undefined,
        parseInt(taskId, 10),
        undefined,
        JobState.Running,
        undefined,
        undefined,
        DEFAULT_JOBS_LIMIT,
        undefined,
        undefined,
        {
          headers: accessToken
            ? { authorization: `Bearer ${accessToken}` }
            : {},
        },
      )
      // TEMP remove for ch4921
      .then(({ data }: any) => (data.data ? data.data.jobs : data.jobs));

    return jobList.some(({ job_type }) => !!LOCK_TO_DATASOURCES_JOB[job_type]);
  } catch (e: any) {
    // fail silently to not crash the app over unimportant operation

    return false;
  }
};

export default isPageGatedByRunningJob;
