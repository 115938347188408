import flags from '@utils/getFlag/flags.json';
import type { FlagObject } from '@utils/getFlag/types';
import getFlagHostOverrides from '@utils/getFlag/utils/getFlagHostOverrides';
import getFlagInMemoryOverride from '@utils/getFlag/utils/getFlagInMemoryOverride';

const flagList = flags as unknown as Record<string, FlagObject>;

const getAllFlags = () =>
  Object.entries(flagList).reduce(
    (acc: Record<string, boolean>, [flag, flagData]) => ({
      ...acc,
      [flag]:
        getFlagInMemoryOverride(flag) ??
        getFlagHostOverrides(flagData.overrides) ??
        flagData.enabled,
    }),
    {},
  );

export default getAllFlags;
