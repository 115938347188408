import React from 'react';
import type { TooltipProps } from './Tooltip';
import Tooltip from './Tooltip';

/** @deprecated Use TooltipV2 instead */
const OptionalTooltip = (props: Partial<TooltipProps>) => {
  const { children, open, title } = props;

  if (!title) {
    return <>{children}</>;
  }

  return (
    <Tooltip {...props} title={title} open={open}>
      {children}
    </Tooltip>
  );
};

export default OptionalTooltip;
