import { isNil } from 'lodash';

import type { Action, JobProgressNotificationsState } from './types';
import { JobProgressNotificationsActions } from './types';

const jobProgressNotificationsReducer = (
  state: JobProgressNotificationsState,
  action: Action,
): JobProgressNotificationsState => {
  if (!(action && action.type)) {
    return state;
  }

  switch (action.type) {
    case JobProgressNotificationsActions.QUEUE_JOB_PROGRESS_BAR: {
      const { jobUid, onCompleteMessage, runningJob } = action.data;

      if (!isNil(state.find(job => job.jobUid === jobUid))) {
        // We're already showing a progress bar for the job.
        return state;
      }

      return [...state, { jobUid, onCompleteMessage, runningJob }];
    }
    case JobProgressNotificationsActions.REMOVE_JOB_PROGRESS_BAR: {
      const { jobUid } = action.data;

      return state.filter(({ jobUid: jobUid_ }) => jobUid_ !== jobUid);
    }

    default: {
      return state;
    }
  }
};

export default jobProgressNotificationsReducer;
