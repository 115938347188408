import type { PropsWithChildren } from 'react';
import React from 'react';

type MenuProps = PropsWithChildren<{
  width?: number;
  className?: string;
}>;

const Menu = ({ children, className = '', width = 200 }: MenuProps) => {
  return (
    <ul style={{ width }} className={`${className} rounded border bg-white`}>
      {children}
    </ul>
  );
};

export default Menu;
