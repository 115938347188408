import type { ErrorAlertData } from './Alert/types';
import PageRegionServerErrorAlert from './PageRegionServerError';
import PageRegionUncaughtErrorAlert from './PageRegionUncaughtError';
import type { PageRegionTag } from './types';

type PageRegionErrorProps = Readonly<{
  tag: PageRegionTag;
  errorAlertData: ErrorAlertData;
  resetError: () => void;
  isUncaughtError?: boolean;
  isWrappingPopUp?: boolean;
}>;

const PageRegionError = ({
  tag,
  errorAlertData,
  isWrappingPopUp = false,
  isUncaughtError,
}: PageRegionErrorProps) => {
  const { message, metaData } = errorAlertData;

  return isUncaughtError ? (
    <PageRegionUncaughtErrorAlert
      tag={tag}
      message={message}
      isWrappingPopUp={isWrappingPopUp}
    />
  ) : (
    <PageRegionServerErrorAlert
      tag={tag}
      message={message}
      isWrappingPopUp={isWrappingPopUp}
      metaData={metaData}
    />
  );
};

export default PageRegionError;
