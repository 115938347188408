import { createContext } from 'react';

import { WorkspaceTypes } from '@app/StudioPage/types';

type WorkspaceProviderState = {
  workspaceType?: WorkspaceTypes;
  setWorkspaceType: (nextWorkspace: WorkspaceTypes) => Promise<void>;
};

const WorkspaceContext = createContext<WorkspaceProviderState>({
  workspaceType: WorkspaceTypes.DEVELOPMENT,
  setWorkspaceType: async () => {},
});

export default WorkspaceContext;
