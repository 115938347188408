import type { Context, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

export type CoralContextType = Readonly<{
  basePath: string;
}>;

const initialContext: CoralContextType = { basePath: '' };
const CoralContext: Context<CoralContextType> = createContext(initialContext);

export const useCoralContext = (): CoralContextType => useContext(CoralContext);

type CoralContextProviderProps = PropsWithChildren<CoralContextType>;

export const CoralContextProvider = ({
  children,
  ...value
}: CoralContextProviderProps) => (
  <CoralContext.Provider value={value}>{children}</CoralContext.Provider>
);

export default null;
