import { calcBasePath } from '@utils/api/calcBasePath';

export const normalizeUrl = (url: string): string => {
  const basePath = calcBasePath();

  const normalizedUrl =
    url === '/' && basePath.length > 0 ? basePath : `${basePath}${url}`;

  return normalizedUrl;
};

export default null;
