import type { AccessTokenData } from '@core/types';

class AccessToken {
  private static instance: AccessToken | null = null;

  private accessToken: AccessTokenData | null = null;

  static getInstance() {
    if (!this.instance) {
      this.instance = new AccessToken();
    }

    return this.instance;
  }

  getAccessToken() {
    return this.accessToken;
  }

  setAccessToken(token: AccessTokenData | null) {
    this.accessToken = token;
  }
}

export default AccessToken;
