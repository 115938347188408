import type { PropsWithChildren } from 'react';

import NavigationProvider from '@global/providers/NavigationProvider';

type NavigationWrapperProps = PropsWithChildren<{
  isPublicRoute?: boolean;
}>;

const NavigationWrapper = ({
  children,
  isPublicRoute = false,
}: NavigationWrapperProps) => {
  if (isPublicRoute) {
    return <>{children}</>;
  }

  return (
    <div className="flex h-full w-full bg-white">
      <NavigationProvider>
        <main id="main-content" className="flex-1 overflow-auto">
          {children}
        </main>
      </NavigationProvider>
    </div>
  );
};

export default NavigationWrapper;
