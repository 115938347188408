import type { PropsWithChildren } from 'react';
import { useReducer } from 'react';

import reducer, { initialState } from '@global/store/root/lfs/reducer';

import LFsContext from './LFsContext';

type LFsContextProviderProps = PropsWithChildren<{}>;

const LFsContextProvider = ({ children }: LFsContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LFsContext.Dispatch.Provider value={dispatch}>
      <LFsContext.State.Provider value={state}>
        {children}
      </LFsContext.State.Provider>
    </LFsContext.Dispatch.Provider>
  );
};

export default LFsContextProvider;
