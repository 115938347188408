import React from 'react';

import CommentsProvider from '@global/PrivateRouteProviders/CommentsProvider';
import TagsProvider from '@global/PrivateRouteProviders/TagsProvider';
import UserSettingsProvider from '@global/PrivateRouteProviders/UserSettingsProvider';
import UsersProvider from '@global/PrivateRouteProviders/UsersProvider';

import WorkspaceProvider from './WorkspaceProvider';

type AppProvidersProps = {
  children: React.ReactNode;
};
const PrivateRouteProviders = ({ children }: AppProvidersProps) => (
  <UserSettingsProvider>
    <UsersProvider>
      <WorkspaceProvider>
        <CommentsProvider>
          <TagsProvider>{children}</TagsProvider>
        </CommentsProvider>
      </WorkspaceProvider>
    </UsersProvider>
  </UserSettingsProvider>
);

export default PrivateRouteProviders;
