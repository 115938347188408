import { useMemo } from 'react';

import useUserSettingsDispatchContext from '@global/PrivateRouteProviders/UserSettingsProvider/hooks/useUserSettingsDispatchContext';
import useUserSettingsStateContext from '@global/PrivateRouteProviders/UserSettingsProvider/hooks/useUserSettingsStateContext';

const useUserSettingsContext = () => {
  const state = useUserSettingsStateContext();
  const dispatch = useUserSettingsDispatchContext();

  const context = useMemo(() => ({ ...dispatch, ...state }), [dispatch, state]);

  return context;
};

export default useUserSettingsContext;
