import { useAtom } from 'jotai/react';
import { useCallback, useEffect, useState } from 'react';

import { notificationsAtom } from '@global/store/notifications/notificationsAtomStore';
import useRequest from '@hooks/useRequestWithLogging';
import { notificationsApi } from '@utils/api/serverRequests';

const useNotifications = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useAtom(notificationsAtom);
  const request = useRequest();

  const getNotifications = useCallback(async () => {
    setLoading(true);

    const result = await request(
      notificationsApi.listNotificationsNotificationsGet,
      {},
    );

    setLoading(false);

    setNotifications(result?.notifications ?? []);
  }, [request, setNotifications]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const deleteNotification = async (notificationUid: number) => {
    await request(
      notificationsApi.deleteNotificationNotificationsNotificationUidDelete,
      {},
      notificationUid,
    );
  };

  const resolveNotification = (notificationUid: number | undefined) => {
    if (!notificationUid) {
      return;
    }

    deleteNotification(notificationUid);
    setNotifications(
      notifications.filter(
        notification => notification.notification_uid !== notificationUid,
      ),
    );
  };

  return {
    loading,
    resolveNotification,
    getNotifications,
    notifications,
  };
};

export default useNotifications;
