export const TITLE = 'Snorkel Flow';
export const DATA_VIEWER_TITLE = 'Data Explorer';
export const LF_LIST_TITLE = 'Saved LFs';

export const APP_ERRORS = {
  ALREADY_EXISTS: 'Application with this name already exists',
  EMPTY: 'Application name cannot be empty',
};

export default null;
