import type { DropdownOptionProps } from './types';

function DropdownOption<T>({
  children,
  isDisabled = false,
}: DropdownOptionProps<T>) {
  return <span aria-disabled={isDisabled}>{children}</span>;
}

DropdownOption.displayName = 'DropdownOption';

export default DropdownOption;
