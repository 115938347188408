import type { CircularProgressProps } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import type { PropsWithChildren } from 'react';
import React from 'react';

type PageSpinnerProps = {
  size?: 's' | 'm' | 'l' | 'xl' | '2xl';
  hAlign?: 'left' | 'center' | 'right';
  vAlign?: 'top' | 'middle' | 'bottom';
  height?: 'full' | 'fit';
  width?: 'full' | 'fit';
  color?: CircularProgressProps['color'];
};

const sizeMap: Record<NonNullable<PageSpinnerProps['size']>, string> = {
  s: '0.75rem',
  m: '1rem',
  l: '1.25rem',
  xl: '1.5rem',
  '2xl': '1.75rem',
};

const hAlignMap: Record<NonNullable<PageSpinnerProps['hAlign']>, string> = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
};

const vAlignMap: Record<NonNullable<PageSpinnerProps['vAlign']>, string> = {
  top: 'items-start',
  middle: 'items-center',
  bottom: 'items-end',
};

const heightMap: Record<NonNullable<PageSpinnerProps['height']>, string> = {
  full: 'h-full',
  fit: 'h-fit',
};

const widthMap: Record<NonNullable<PageSpinnerProps['width']>, string> = {
  full: 'w-full',
  fit: 'w-fit',
};

const Spinner = ({
  children,
  size = 'm',
  hAlign = 'center',
  vAlign = 'middle',
  height = 'full',
  width = 'full',
  color,
}: PropsWithChildren<PageSpinnerProps>) => {
  return (
    <div
      className={`flex ${heightMap[height]} ${widthMap[width]} ${hAlignMap[hAlign]} ${vAlignMap[vAlign]} gap-3`}
      data-cy="spinner"
    >
      <CircularProgress size={sizeMap[size]} color={color} />
      {children ? <div>{children}</div> : null}
    </div>
  );
};

export default Spinner;
